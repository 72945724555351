<script lang="ts" setup>
import {computed, ref} from "vue"
import LogoPrimary from '../../img/Logo_Primary.svg'
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"
import {useRouter} from "vue-router"
import useAuth from "@/composables/useAuth"
import {useAppStore} from "@/stores/app"

const appName = computed(() => import.meta.env.VITE_APP_NAME)

const {$auth} = useAuth()
const router = useRouter()

const userBasedProfile = computed(() => {
  switch ($auth.user?.type) {
    case 'admin':
      return route(ROUTES.ADMIN.PROFILE)
    case 'coach':
      return route(ROUTES.COACHES.PROFILE.PROFILE)
    case 'user':
      return route(ROUTES.USERS.PROFILE)
    default:
      return route(ROUTES.HOME)
  }
})

const logout = () => {
  $auth.logout()
  router.push(route(ROUTES.HOME))
}



const stopImpersonating = async () => {
  await $auth.stopImpersonating()
  const appStore = useAppStore()
  appStore.addSuccess('Stopped impersonating user')
  await router.push(route(ROUTES.HOME))
}

const drawer = ref(false)

</script>

<template>
  <v-navigation-drawer
    v-model="drawer"
    temporary
  >
    <router-link :to="route(ROUTES.HOME)">
      <img
        :alt="`${appName} Logo`"
        :src="LogoPrimary"
        size="128"
        class="ma-4"
      >
    </router-link>

    <v-list>
      <v-list-item
        :to="route(ROUTES.HOME)"
      >
        Home
      </v-list-item>

      <template
        v-if="!$auth.user"
      >
        <v-list-item
          :to="route(ROUTES.COACHES.CREATE)"
        >
          Join as a Coach
        </v-list-item>
        <v-list-item
          :to="route(ROUTES.USERS.CREATE)"
        >
          Sign Up
        </v-list-item>
        <v-list-item
          :to="route(ROUTES.LOGIN)"
        >
          Login
        </v-list-item>
        <v-divider />
      </template>
      <template
        v-if="$auth.isCoach || $auth.isUser"
      >
        <v-list-item
          to="/#focus-areas"
          :active="false"
        >
          Focus Areas
        </v-list-item>
        <v-list-item
          to="/#faq"
          :active="false"
        >
          FAQ
        </v-list-item>
        <v-list-item
          :to="route(ROUTES.SEARCH)"
        >
          Search
        </v-list-item>
      </template>
      <template
        v-if="$auth.isAdmin"
      >
        <v-list-item
          :to="route(ROUTES.ADMIN.COACHES.INDEX)"
        >
          Coaches
        </v-list-item>
        <v-list-item
          :to="route(ROUTES.ADMIN.USERS.INDEX)"
        >
          Users
        </v-list-item>
        <v-list-item
          :to="route(ROUTES.ADMIN.ADMINS.INDEX)"
        >
          Admins
        </v-list-item>
        <v-list-item
          :to="route(ROUTES.ADMIN.FOCUS_AREAS.INDEX)"
        >
          Focus Areas
        </v-list-item>
        <v-list-item
          :to="route(ROUTES.ADMIN.APPOINTMENTS.INDEX)"
        >
          Appointments
        </v-list-item>
        <v-divider />
      </template>
      <template
        v-if="$auth.user"
      >
        <v-list-item
          :to="userBasedProfile"
        >
          My Profile
        </v-list-item>

        <v-list-item
          @click="logout"
        >
          Logout
        </v-list-item>
      </template>
      <v-btn
        v-if="$auth.user?.is_impersonated"
        color="error"
        variant="flat"
        @click="stopImpersonating"
      >
        Stop Impersonating
      </v-btn>
    </v-list>
  </v-navigation-drawer>

  <v-app-bar
    flat
    height="110"
    class="ga-4"
  >
    <v-container class="d-flex justify-space-between align-center">
      <router-link :to="route(ROUTES.HOME)">
        <img
          :alt="`${appName} Logo`"
          :src="LogoPrimary"
          size="166"
        >
      </router-link>
      <div class="desktop-menu">
        <div
          class="d-flex ga-2"
          v-if="!$auth.user"
        >
          <v-btn
            color="primary"
            size="large"
            variant="outlined"
            :to="route(ROUTES.COACHES.CREATE)"
          >
            Join as a Coach
          </v-btn>
          <v-btn
            color="primary"
            size="large"
            :to="route(ROUTES.USERS.CREATE)"
          >
            Sign Up
          </v-btn>
          <v-btn
            color="primary"
            size="large"
            :to="route(ROUTES.LOGIN)"
          >
            Login
          </v-btn>
        </div>
        <div
          v-if="$auth.isAdmin"
          class="d-flex ga-2"
        >
          <v-btn
            size="large"
            class="btn-links"
            variant="plain"
            :to="route(ROUTES.ADMIN.COACHES.INDEX)"
          >
            Coaches
          </v-btn>
          <v-btn
            size="large"
            class="btn-links"
            variant="plain"
            :to="route(ROUTES.ADMIN.USERS.INDEX)"
          >
            Users
          </v-btn>
          <v-btn
            size="large"
            class="btn-links"
            variant="plain"
            :to="route(ROUTES.ADMIN.ADMINS.INDEX)"
          >
            Admins
          </v-btn>
          <v-btn
            size="large"
            class="btn-links"
            variant="plain"
            :to="route(ROUTES.ADMIN.FOCUS_AREAS.INDEX)"
          >
            Focus Areas
          </v-btn>
          <v-btn
            size="large"
            class="btn-links"
            variant="plain"
            :to="route(ROUTES.ADMIN.APPOINTMENTS.INDEX)"
          >
            Appointments
          </v-btn>
        </div>
        <div
          v-if="$auth.isCoach || $auth.isUser"
        >
          <v-btn
            size="large"
            class="btn-links"
            variant="plain"
            to="/#focus-areas"
          >
            Focus Areas
          </v-btn>
          <v-btn
            size="large"
            class="btn-links"
            variant="plain"
            to="/#faq"
          >
            FAQ
          </v-btn>
          <v-btn
            size="large"
            class="btn-links"
            variant="plain"
            :to="route(ROUTES.SEARCH)"
          >
            Coaches
          </v-btn>
        </div>
        <div
          v-if="$auth.user"
          class="d-flex ga-4"
        >
          <v-btn
            color="primary"
            size="large"
            class="btn-links"
            variant="outlined"
            :to="userBasedProfile"
          >
            My Profile
          </v-btn>

          <v-btn
            v-if="!$auth.user?.is_impersonated"
            color="primary"
            size="large"
            @click="logout"
          >
            Logout
          </v-btn>
          <v-btn
            v-else
            color="primary"
            size="large"
            @click="stopImpersonating"
          >
            Stop Impersonating
          </v-btn>
        </div>
      </div>
      <v-app-bar-nav-icon
        class="mobile-menu"
        variant="text"
        @click.stop="drawer = !drawer"
      />
    </v-container>
  </v-app-bar>
</template>

<style lang="scss" scoped>
.btn-links {
  border-color: rgb(var(--v-theme-primary)) !important;
  color: #4B4B4B !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0 14px;

  &.v-btn--active {
    color: #000000;
  }
}

.desktop-menu {
  display: contents;
}

.mobile-menu {
  display: none;
}

@media (max-width: 1200px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
}


</style>
