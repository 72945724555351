import { defineStore } from "pinia"
import axios from "axios"
import type { AxiosResponse } from "@/plugins/axios"
import * as Sentry from "@sentry/vue"

export interface LoginForm {
  email: string
  password: string
}

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: null as App.Models.User | null
    }
  },
  actions: {
    async logout() {
      await axios.delete("/auth/me").catch((e) => e)
      this.user = null
      Sentry.setUser(null)
      localStorage.removeItem('token')
    },
    async loadUser(force = false) {
      if (this.user && !force) {
        return
      }
      if (!localStorage.getItem('token')) {
        return
      }
      const {
        data: { user }
      }: AxiosResponse<{ user: App.Models.User }> = await axios.get("/auth/me").catch((e) => e)
      this.user = user
      Sentry.setUser(user)
    },
    async impersonate(userId: number) {
      const response: AxiosResponse<{ access_token: string }> = await axios
        .post("/auth/impersonate", {
          user_id: userId
        })
        .catch((e) => e)

      if (response.data.access_token) {
        localStorage.setItem("token", response.data.access_token)
        await this.loadUser(true)
      }

      return response
    },
    async stopImpersonating() {
      const response: AxiosResponse<{ access_token: string }> = await axios
        .delete("/auth/stop-impersonating")
        .catch((e) => e)

      if (response.data.access_token) {
        localStorage.setItem("token", response.data.access_token)
        await this.loadUser(true)
      }

      return response
    }
  }
})
