<script lang="ts" setup>
import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import {reactive} from "vue"
import ThePasswordRules from "@/components/ThePasswordRules.vue"
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"
import AMainSection from "@/components/AMainSection.vue"
import {useRouter} from "vue-router"

const router = useRouter()

const form = reactive({
  first_name: null,
  middle_name: null,
  last_name: null,
  email: null,
  password: '',
  password_confirmation: '',
})

const handleFormSuccess = () => {
  router.push(route(ROUTES.ADMIN.ADMINS.INDEX))
}

</script>

<template>
  <a-main-section
    heading="Create Admin"
    return-text="Manage Admins"
    :return-to="route(ROUTES.ADMIN.ADMINS.INDEX)"
  >
    <app-server-validation-form
      :data="form"
      endpoint="/admin/admins"
      @success="handleFormSuccess"
      v-slot="{ submit, loading, getErrors }"
    >
      <v-text-field
        v-model="form.first_name"
        label="First Name"
        :error-messages="getErrors('first_name')"
      />

      <v-text-field
        v-model="form.middle_name"
        label="Middle Name (optional)"
        :error-messages="getErrors('middle_name')"
      />

      <v-text-field
        v-model="form.last_name"
        label="Last Name"
        :error-messages="getErrors('last_name')"
      />

      <v-text-field
        v-model="form.email"
        type="email"
        label="Email"
        :error-messages="getErrors('email')"
      />

      <v-text-field
        v-model="form.password"
        type="password"
        label="Password"
        :error-messages="getErrors('password')"
      />

      <v-text-field
        v-model="form.password_confirmation"
        type="password"
        label="Password Confirmation"
        :error-messages="getErrors('password_confirmation')"
      />

      <the-password-rules :value="form.password" />

      <v-btn
        color="primary"
        size="large"
        :loading="loading"
        @click="submit"
      >
        Save Admin
      </v-btn>
    </app-server-validation-form>
  </a-main-section>
</template>
