<script setup lang="ts">
import {ref} from 'vue'
import CoachProfileForm from "@/components/CoachProfileForm.vue"
import useAuth from "@/composables/useAuth"
import ALoader from "@/components/ALoader.vue"
import useAxios from "@/composables/useAxios"
import {useHead} from "@unhead/vue"


useHead({ title: 'My Profile' })

const {$auth} = useAuth()
const {axios} = useAxios()
const coach = ref<(App.Models.User & {password_confirmation: null | string}) | null>()
const load = async () => {
  const {data} = await axios.get(`/coach/coaches/${$auth.user!.id}`)
  coach.value = {...data, password_confirmation: null}
}


const handleSuccess = () => {
  $auth.loadUser()
  load()
}

</script>

<template>
  <a-loader :action="load">
    <coach-profile-form
      v-if="coach"
      @success="handleSuccess"
      endpoint="/coach/coaches"
      method="post"
      v-model="coach"
    />
  </a-loader>
</template>
