<script setup lang="ts">
import {ref, useTemplateRef, watch} from "vue"
import useValidators from "@/composables/useValidators"
import AppNumberInput from "@/components/AppNumberInput.vue"
import type {VForm} from "vuetify/components"

const props = defineProps<{
  service: App.Models.Service | null
}>()

const {rules} = useValidators()

const show = defineModel<boolean>({
  required: true,
})

const form = useTemplateRef<VForm>('form')

const emits = defineEmits<{
  success: [service: App.Models.Service]
  delete: []
}>()

const blankService: Partial<App.Models.Service> = {
  name: null,
  description: null,
  delivery_method: null,
  format: null,
  price: null,
}

const free = ref(false)

const internalService = ref<Partial<App.Models.Service>>({...props.service} || {...blankService})
watch(() => props.service, (service: App.Models.Service | null) => {
  internalService.value = {...service} || {...blankService}
  free.value = internalService.value.price === 0
})

watch(free, (free) => {
  if (free) {
    internalService.value.price = 0
  }
})

watch(show, () => {
  internalService.value = {...props.service} || {...blankService}
  free.value = internalService.value.price === 0
})

const submit = async () => {
  const result = await form.value!.validate()
  if (!result.valid) return
  emits('success', internalService.value as App.Models.Service)
}
</script>

<template>
  <v-dialog
    v-model="show"
    max-width="500"
    scrollable
  >
    <v-card>
      <v-card-title class="bg-primary d-flex align-center">
        {{ internalService.id ? 'Edit' : 'Add' }} Service {{ internalService.id ? internalService.name : '' }}
        <v-spacer />
        <v-icon
          size="small"
          @click="show = false"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="internalService.name"
            label="Name"
            required
          />
          <v-textarea
            v-model="internalService.description"
            label="Description"
            required
          />
          <v-radio-group
            v-model="internalService.delivery_method"
            label="Delivery Method"
            :rules="rules.required"
          >
            <v-radio
              label="In-person"
              value="In-person"
            />
            <v-radio
              label="Virtual"
              value="Virtual"
            />
          </v-radio-group>
          <v-radio-group
            v-model="internalService.format"
            label="Format"
            :rules="rules.required"
          >
            <v-radio
              label="Group"
              value="Group"
            />
            <v-radio
              label="One-on-one"
              value="One-on-one"
            />
          </v-radio-group>
          <v-checkbox
            label="Free"
            v-model="free"
            hide-details
          />
          <app-number-input
            v-if="!free"
            v-model="internalService.price"
            label="Price"
            :rules="rules.required"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          variant="outlined"
          v-if="service?.id"
          @click="emits('delete')"
        >
          Delete Service
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          variant="flat"
          @click="submit"
        >
          Add Service
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">

</style>
