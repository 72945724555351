<script setup lang="ts">

import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import AppFileUpload from "@/components/AppFileUpload.vue"

defineOptions({
  inheritAttrs: false,
})

const focusArea = defineModel<App.Models.FocusArea>({
  required: true,
})

defineProps<{
  endpoint: string
  method: string
}>()

</script>

<template>
  <app-server-validation-form
    :data="focusArea"
    :endpoint="endpoint"
    :method="method"
    v-bind="$attrs"
    v-slot="{ submit, loading, getErrors }"
  >
    <div class="d-flex flex-column gr-6">
      <v-number-input
        v-model="focusArea.position"
        label="Position"
      />
      <v-text-field
        v-model="focusArea.name"
        label="Name"
        :error-messages="getErrors('name')"
      />
      <v-textarea
        v-model="focusArea.description"
        label="Description"
        :error-messages="getErrors('description')"
      />
      <app-file-upload
        v-model="focusArea.file_id"
        label="Cover Image"
        :error-messages="getErrors('file_id')"
      />
      <v-btn
        @click="submit"
        :loading="loading"
        color="primary"
        text="Submit"
      />
    </div>
  </app-server-validation-form>
</template>

<style scoped lang="scss">

</style>
