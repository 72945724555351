<script lang="ts" setup>
import {ref} from "vue"
import AMainSection from "@/components/AMainSection.vue"
import {useRouter} from "vue-router"
import FocusAreaForm from "@/components/FocusAreaForm.vue"
import useRouteTo from "@/composables/useRouteTo"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"

const router = useRouter()
const {routeTo, ROUTES} = useRouteTo()
const { axios } = useAxios()

const props = defineProps<{
  focusAreaId: number;
}>()

const focusArea = ref({
  name: null,
  description: null,
  file_id: null
})

const handleFormSuccess = () => {
  router.push(routeTo(ROUTES.ADMIN.FOCUS_AREAS.INDEX))
}

const load = async () => {
  const {data} = await axios.get(`/admin/focus-areas/${props.focusAreaId}`)
  focusArea.value = data
}

</script>

<template>
  <a-main-section
    heading="Edit Focus Area"
    return-text="Manage Focus Areas"
    :return-to="routeTo(ROUTES.ADMIN.FOCUS_AREAS.INDEX)"
  >
    <a-loader :action="load">
      <focus-area-form
        v-model="focusArea"
        :endpoint="`/admin/focus-areas/${props.focusAreaId}`"
        method="put"
        @success="handleFormSuccess"
      />
    </a-loader>
  </a-main-section>
</template>
