<script setup lang="ts">
import {onMounted, ref} from "vue"
import AppPaginationTable from "@/components/AppPaginationTable.vue"
import route from "@/plugins/route"
import useAxios from "@/composables/useAxios"
import AMainSection from "@/components/AMainSection.vue"
import {ROUTES} from "@/router/paths"
import ConfirmationButton from "@/components/ConfirmationButton.vue"

const { axios } = useAxios()
const table = ref()

const q = ref('')

const headers = [
  {
    title: 'Name',
    key: 'full_name',
  },
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false
  }
]

onMounted(() => {
  table.value.reload()
})
</script>

<template>
  <a-main-section
    heading="Manage Admins"
  >
    <v-card
      class="mt-4"
      color="primary"
      variant="outlined"
    >
      <v-card-title>
        Admin Options
      </v-card-title>
      <v-card-text>
        <v-btn
          color="primary"
          text="Add Admin"
          :to="route(ROUTES.ADMIN.ADMINS.CREATE)"
        />
      </v-card-text>
    </v-card>

    <div class="my-4">
      <v-text-field
        v-model="q"
        placeholder="Search"
        variant="outlined"
      />
    </div>

    <app-pagination-table
      endpoint="/admin/admins"
      ref="table"
      :filters="{
        q: q,
      }"
      :headers="headers"
    >
      <template #item.actions="{ item }">
        <v-btn
          icon="mdi-pencil"
          variant="flat"
          :to="route(ROUTES.ADMIN.ADMINS.EDIT, { admin: item.id })"
        />
        <confirmation-button
          icon="mdi-delete"
          variant="flat"
          confirmation-heading="Are you sure?"
          :confirmation-text="`Are you sure you want to delete ${item.full_name}? This cannot be undone.`"
          confirmation-button="Delete"
          @confirm="axios.delete(`/admin/admins/${item.id}`).then(() => table.reload())"
        />
      </template>
    </app-pagination-table>
  </a-main-section>
</template>
