<script setup lang="ts">
import {computed, nextTick, reactive, ref} from 'vue'
import useAuth from "@/composables/useAuth"
import {useConfirm} from "vuetify-use-dialog"
import useTime from "@/composables/useTime"
import useAxios from "@/composables/useAxios"
import useErrorHandler from "@/composables/useErrorHandler"
import {useRouter} from "vue-router"
import useRouteTo from "@/composables/useRouteTo"
import ALoader from "@/components/ALoader.vue"
import AppPaginationTable from "@/components/AppPaginationTable.vue"
import {useHead} from "@unhead/vue"

useHead({ title: 'My Subscription' })

const {$auth} = useAuth()
const time = useTime()
const {axios} = useAxios()
const coach = ref<(App.Models.User & {password_confirmation: null | string}) | null>()
const headers = [
  {
    title: 'Date',
    key: 'status_transitions.paid_at',
    sortable: false
  },
  {
    title: 'Status',
    key: 'status',
    sortable: false
  },
  {
    title: 'Amount',
    key: 'amount_due',
    sortable: false
  },
]
const confirm = useConfirm()
const {errorHandler} = useErrorHandler()
const router = useRouter()
const {routeTo, ROUTES} = useRouteTo()

const price = computed(() => {
  return (coach.value?.current_subscription?.plan?.amount || 0 ) / 100
})
const interval = computed(() => {
  return coach.value?.current_subscription?.plan?.interval
})

const isSubscriptionActive = computed(() => {
  return coach.value?.current_subscription?.status === 'active'
})

const isSubscriptionOffline = computed(() => coach.value?.has_offline_subscription)

const isSubscriptionScheduledToCancel = computed(() => {
  return coach.value?.current_subscription?.cancel_at_period_end && isSubscriptionActive.value
})

const loading = reactive({
  cancelingSubscription: false,
  resumingSubscription: false,
  restartingSubscription: false,
})

const load = async () => {
  const {data} = await axios.get(`/coach/coaches/${$auth.user!.id}`)
  coach.value = {...data, password_confirmation: null}
}

const cancelSubscription = async () => {
  const subscriptionEnd = time.short_date_time(coach.value!.current_subscription.current_period_end)
  const response = await confirm({
    title: 'Are you sure?',
    content: 'Are you sure you want to cancel your subscription? Your subscription will remain active until ' + subscriptionEnd,
  })

  if (!response) {
    return
  }

  loading.cancelingSubscription = true
  const {data: {message, errors}, status} = await axios.delete('/coach/cancel-subscription', {}).catch(e => e)
  if (errorHandler(status, message, errors)) {
    loading.cancelingSubscription = false
    return
  }
  await Promise.all([$auth.loadUser(), load()])
  await nextTick(() => {
    loading.cancelingSubscription = false
  })
}

const resumeSubscription = async () => {
  const response = await confirm({
    title: 'Are you sure?',
    content: 'Are you sure you want to resume your subscription?',
  })

  if (!response) {
    return
  }

  loading.resumingSubscription = true
  const {data: {message, errors}, status} = await axios.post('/coach/resume-subscription', {}).catch(e => e)
  loading.resumingSubscription = false
  if (errorHandler(status, message, errors)) {
    loading.resumingSubscription = false
    return
  }
  await Promise.all([$auth.loadUser(), load()])
  await nextTick(() => {
    loading.resumingSubscription = false
  })
}

const restartSubscription = async () => {
  const response = await confirm({
    title: 'Are you sure?',
    content: `Are you sure you want to restart a ${coach.value!.subscription_level} subscription?`,
  })

  if (!response) {
    return
  }

  loading.restartingSubscription = true
  const {data: {message, errors}, status} = await axios.post('/coach/restart-subscription', {
    subscription_level: coach.value!.subscription_level
  }).catch(e => e)
  loading.restartingSubscription = false
  if (errorHandler(status, message, errors)) return
  await router.push(routeTo(ROUTES.COACHES.CHECKOUT))
}
</script>

<template>
  <a-loader :action="load">
    <div v-if="isSubscriptionOffline">
      <h3>Your subscription is managed offline.</h3>

      <p>Please contact Prime Health admins to manage your subscription.</p>
    </div>
    <div
      v-else
      class="d-flex flex-column ga-8 align-start"
    >
      <h3>
        Subscription
      </h3>
      <div>
        <p>Prime Health RN member dues</p>
        <p v-if="price">
          ${{ price }} / {{ interval }}
        </p>
        <p v-if="isSubscriptionScheduledToCancel">
          Access will end on {{ time.short_date_time(coach?.current_subscription.cancel_at) }}
        </p>
      </div>
      <v-btn
        v-if="isSubscriptionScheduledToCancel"
        size="large"
        color="primary"
        class="d-flex"
        :loading="loading.resumingSubscription"
        @click="resumeSubscription"
      >
        Resume Subscription
      </v-btn>
      <v-btn
        v-else-if="isSubscriptionActive"
        size="large"
        color="primary"
        class="d-flex"
        :loading="loading.cancelingSubscription"
        @click="cancelSubscription"
      >
        Cancel Subscription
      </v-btn>
      <template v-else>
        <v-select
          v-model="coach!.subscription_level"
          label="Subscription Level"
          class="mt-2"
          :items="['Monthly', 'Yearly']"
        />
        <v-btn
          size="large"
          color="primary"
          class="d-flex"
          :loading="loading.restartingSubscription"
          @click="restartSubscription"
        >
          Restart Subscription
        </v-btn>
      </template>
      <h3 class="mb-3">
        Subscription
      </h3>
      <app-pagination-table
        endpoint="/coach/payments"
        hide-default-footer
        :item-per-page="-1"
        :headers="headers"
      >
        <template #item.status_transitions.paid_at="{value}">
          <span>{{ time.short_date_time(value) }}</span>
        </template>
        <template #item.amount_due="{value}">
          <span>${{ (value / 100).toFixed(2) }}</span>
        </template>
        <template #item.status="{value}">
          <span class="text-uppercase">{{ value }}</span>
        </template>
      </app-pagination-table>
    </div>
  </a-loader>
</template>
