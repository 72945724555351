<script lang="ts" setup>
import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import {reactive, ref} from "vue"
import ThePasswordRules from "@/components/ThePasswordRules.vue"
import useAxios from "@/composables/useAxios"
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"
import ALoader from "@/components/ALoader.vue"
import AMainSection from "@/components/AMainSection.vue"
import AppPaginationTable from "@/components/AppPaginationTable.vue"
import {useHead} from "@unhead/vue"
import {$auth} from "@/plugins/auth"

useHead({ title: 'My Profile' })

const { axios } = useAxios()

const userData = ref<Record<any, any>>()

const form = reactive({
  first_name: null,
  middle_name: null,
  last_name: null,
  email: null,
  password: '',
  password_confirmation: '',
})

const tab = ref('profile')
const headers = [
  {
    title: 'Status',
    key: 'status',
    sortable: false
  },
  {
    title: 'Coach',
    key: 'coach.full_name',
    sortable: false
  },
  {
    title: 'Service',
    key: 'service',
    sortable: false
  },
  {
    title: '',
    key: 'actions',
    sortable: false
  },
]

const handleFormSuccess = async () => {
  form.password = form.password_confirmation = ''
  await load()
}

const load = async () => {
  await axios.get(`/auth/me`)
    .then(({ data }) => {

      userData.value = data.user

      form.first_name = data.user.first_name
      form.middle_name = data.user.middle_name
      form.last_name = data.user.last_name
      form.email = data.user.email
    })
}

</script>

<template>
  <v-container>
    <a-loader :action="() => load()">
      <v-tabs v-model="tab">
        <v-tab
          text="Profile"
          value="profile"
        />
        <v-tab
          v-if="!$auth.isAdmin"
          text="Appointments"
          value="appointments"
        />
      </v-tabs>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="profile">
          <a-main-section
            :heading="userData?.full_name"
          >
            <app-server-validation-form
              :data="form"
              method="patch"
              endpoint="/auth/me"
              @success="handleFormSuccess"
              v-slot="{ submit, loading, getErrors }"
            >
              <v-text-field
                v-model="form.first_name"
                placeholder="First Name"
                :error-messages="getErrors('first_name')"
              />
              <v-text-field
                v-model="form.middle_name"
                placeholder="Middle Name"
                :error-messages="getErrors('middle_name')"
              />
              <v-text-field
                v-model="form.last_name"
                placeholder="Last Name"
                :error-messages="getErrors('last_name')"
              />

              <v-text-field
                v-model="form.email"
                type="email"
                placeholder="Email"
                :error-messages="getErrors('email')"
              />

              <v-text-field
                v-model="form.password"
                type="password"
                placeholder="New Password (leave blank to keep original)"
                :error-messages="getErrors('password')"
              />

              <v-text-field
                v-model="form.password_confirmation"
                type="password"
                placeholder="Password Confirmation"
                :error-messages="getErrors('password_confirmation')"
              />

              <the-password-rules :value="form.password" />

              <v-btn
                color="primary"
                size="large"
                :loading="loading"
                @click="submit"
              >
                Save Profile
              </v-btn>
            </app-server-validation-form>
          </a-main-section>
        </v-tabs-window-item>
        <v-tabs-window-item value="appointments">
          <app-pagination-table
            endpoint="/appointments"
            striped
            :headers="headers"
          >
            <template #item.service="{value}">
              {{ value.name }} {{ value.delivery_method }} {{ value.format }}
            </template>
            <template #item.actions="{item}">
              <router-link
                :to="route(ROUTES.USERS.APPOINTMENT, { appointmentId: item.id })"
              >
                <v-icon color="primary">
                  mdi-open-in-new
                </v-icon>
              </router-link>
            </template>
          </app-pagination-table>
        </v-tabs-window-item>
      </v-tabs-window>
    </a-loader>
  </v-container>
</template>
