<script setup lang="ts">
import {computed, ref} from "vue"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"
import {useDisplay} from "vuetify"

const coachStates = defineModel<App.Models.State[]>({
  required: true,
})
const states = ref<App.Models.State[]>([])

const {axios} = useAxios()
const loadStates = async () => {
  await axios.get('/states').then(({data}) => {
    states.value = data
  })
}

const {smAndDown} = useDisplay()
const chunkSize = computed(() => {
  return states.value.length / (smAndDown.value ? 2 : 6)
})
const stateChunks = computed(() => {
  const chunks: App.Models.State[][] = []
  for (let i = 0; i < states.value.length; i += chunkSize.value) {
    chunks.push(states.value.slice(i, i + chunkSize.value))
  }
  return chunks
})

const areAllStatesSelected = computed(() => {
  return coachStates.value.length === states.value.length
})

const areSomeStatesSelected = computed(() => {
  return coachStates.value.length > 0 && coachStates.value.length < states.value.length
})

const areAllCompactStatesSelected = computed(() => {
  const selectedCompactStatesCount = coachStates.value.filter(s => s.compact).length
  const compactStatesCount = states.value.filter(s => s.compact).length
  return selectedCompactStatesCount === compactStatesCount
})

const areSomeCompactStatesSelected = computed(() => {
  const selectedCompactStatesCount = coachStates.value.filter(s => s.compact).length
  const compactStatesCount = states.value.filter(s => s.compact).length
  return selectedCompactStatesCount > 0 && selectedCompactStatesCount < compactStatesCount
})

const selectAllStates = (value: boolean | null) => {
  coachStates.value = value ? [...states.value] : []
}

const handleCompactLicenseChange = (value: boolean | null) => {
  if (!value) {
    coachStates.value = coachStates.value.filter(state => !state.compact)

    return
  }

  const selectStateIds = coachStates.value.map(state => state.id)
  coachStates.value = states.value.filter(state => state.compact || selectStateIds.includes(state.id))
}
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-label>Licensed to practice in</v-label>
    </v-col>
    <v-col cols="12">
      <a-loader :action="loadStates">
        <v-row
          no-gutters
          class="gc-4"
        >
          <v-checkbox
            :label="(areAllStatesSelected ? 'Deselect' : 'Select') + ' all'"
            :model-value="areAllStatesSelected"
            @update:model-value="selectAllStates"
            :indeterminate="areSomeStatesSelected"
            hide-details="auto"
            base-color="primary"
            density="compact"
            color="primary"
          />
          <v-checkbox
            :label="(areAllCompactStatesSelected ? 'Deselect' : 'Select') + ' All Nurse Licensure Compact States'"
            @update:model-value="handleCompactLicenseChange"
            :model-value="areAllCompactStatesSelected"
            :indeterminate="areSomeCompactStatesSelected"
            hide-details="auto"
            base-color="primary"
            density="compact"
            color="primary"
          />
        </v-row>
        <v-row class="mt-0">
          <v-col
            v-for="(chunk, i) in stateChunks"
            cols="6"
            md="4"
            lg="2"
            class="pt-0"
            :key="i"
          >
            <v-checkbox
              v-model="coachStates"
              :value="state"
              multiple
              :value-comparator="(a: any, b: any) => a.id === b.id"
              :label="state.code"
              base-color="primary"
              color="primary"
              density="compact"
              hide-details="auto"
              v-for="state in chunk"
              :key="state.id"
            />
          </v-col>
        </v-row>
      </a-loader>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">

</style>
