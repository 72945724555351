<script setup lang="ts">
import {onMounted, ref, watch} from "vue"
import AppPaginationTable from "@/components/AppPaginationTable.vue"
import useAxios from "@/composables/useAxios"
import AMainSection from "@/components/AMainSection.vue"
import useErrorHandler from "@/composables/useErrorHandler"
import useRouteTo from "@/composables/useRouteTo"
import AppDateInput from "@/components/AppDateInput.vue"
import {useDebounceFn} from "@vueuse/core"

const { axios } = useAxios()
const table = ref()
const {errorHandler} = useErrorHandler()
const {routeTo, ROUTES} = useRouteTo()

const filters = ref({
  search: null as string | null,
  date_from: undefined as string | undefined,
  date_to: undefined as string | undefined,
})

const stats = ref({
  requested: 0,
  scheduled: 0,
  completed: 0,
  canceled: 0,
  rejected: 0,
})

const loadingStats = ref(false)

const headers = [
  {
    title: 'Coach Name',
    key: 'coach_full_name',
  },
  {
    title: 'User Name',
    key: 'user_full_name',
  },
  {
    title: 'Payment Status',
    key: 'payment_complete',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false
  }
]

const loadStats = async () => {
  loadingStats.value = true
  const {data: {appointmentStats, message, errors}, status} = await axios.get('/admin/appointments-stats', {
    params: {
      ...filters.value,
    }
  })
  loadingStats.value = false
  if (errorHandler(status, message, errors)) {
    return
  }
  stats.value = appointmentStats
}

const debounceStats = useDebounceFn(loadStats, 1000)

watch(filters, () => {
  debounceStats()
}, {deep: true})


onMounted(() => {
  loadStats()
})
</script>

<template>
  <a-main-section
    heading="Appointments"
  >
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="filters.search"
          placeholder="Search"
          variant="outlined"
        />
      </v-col>
      <v-col cols="3">
        <app-date-input
          v-model="filters.date_from"
          variant="outlined"
          label="Date From"
          clearable
        />
      </v-col>
      <v-col cols="3">
        <app-date-input
          v-model="filters.date_to"
          variant="outlined"
          label="Date To"
          clearable
        />
      </v-col>
    </v-row>

    <v-card
      class="mb-4"
      color="primary"
      variant="outlined"
      :loading="loadingStats"
    >
      <v-card-title class="text-h6 text-center">
        Appointment Stats
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col
            cols="4"
            md="3"
            class="text-center"
          >
            <v-row>
              <v-col
                cols="12"
                class="py-0 text-center"
              >
                <strong>Requested</strong>
              </v-col>
              <v-col
                cols="12"
                class="py-0 text-center"
              >
                <v-chip
                  color="blue-lighten-1"
                  class="ma-2"
                >
                  {{ stats?.requested }}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="4"
            md="2"
            class="text-center"
          >
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <strong>Scheduled</strong>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-chip
                  color="green-lighten-1"
                  class="ma-2"
                >
                  {{ stats?.scheduled }}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="4"
            md="2"
            class="text-center"
          >
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <strong>Completed</strong>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-chip
                  color="green-lighten-1"
                  class="ma-2"
                >
                  {{ stats?.completed }}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="6"
            md="2"
            class="text-center"
          >
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <strong>Canceled</strong>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-chip
                  color="red-lighten-1"
                  class="ma-2"
                >
                  {{ stats?.canceled }}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="6"
            md="3"
            class="text-center"
          >
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <strong>Rejected</strong>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-chip
                  color="error"
                  text-color="white"
                  class="ma-2"
                >
                  {{ stats?.rejected }}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <app-pagination-table
      endpoint="/admin/appointments"
      ref="table"
      :filters="filters"
      :debounce-fields="['search', 'date_from', 'date_to']"
      :headers="headers"
    >
      <template #item.payment_complete="{ value }">
        <v-chip
          variant="flat"
          :color="{
            true: 'green-lighten-3',
            false: 'red-lighten-3',
          }[value]"
        >
          {{ value ? 'Paid' : 'Not Paid' }}
        </v-chip>
      </template>
      <template #item.status="{ value }">
        <v-chip
          variant="flat"
          :color="{
            'Requested': 'blue-lighten-3',
            'Scheduled': 'green-lighten-3',
            'Completed': 'green-lighten-1',
            'Canceled': 'red-lighten-3',
            'Rejected': 'red-lighten-1',
          }[value]"
        >
          {{ value }}
        </v-chip>
      </template>
      <template #item.actions="{ item }">
        <div class="d-flex flex-nowrap">
          <v-btn
            icon="mdi-pencil"
            variant="flat"
            :to="routeTo(ROUTES.ADMIN.COACHES.APPOINTMENT, { appointmentId: item.id, coachId: item.coach_id }, { appointmentsIndex: 1 })"
          />
        </div>
      </template>
    </app-pagination-table>
  </a-main-section>
</template>
