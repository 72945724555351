<script setup lang="ts">

const props = withDefaults(defineProps<{
  value?: string;
}>(), {
  value: '',
})

const rules = [
  {
    name: '8+ characters',
    test: () => props.value.length >= 8,
  },
  {
    name: 'at least 1 capital letter',
    test: () => /[A-Z]/g.test(props.value),
  },
  {
    name: 'at least 1 lowercase letter',
    test: () => /[a-z]/g.test(props.value),
  },
  {
    name: 'at least 1 number',
    test: () => /[0-9]/g.test(props.value),
  },
  {
    name: 'a special character such as #,!,@,$,%,^,&,*,(,),-,+,=',
    test: () => /[#!@$%^&*()-+=]/g.test(props.value),
  },
] as const

</script>

<template>
  <div class="password-rules mb-4">
    <div
      v-for="groupedRules of [ rules.slice(0, 3), rules.slice(3) ]"
      :key="'group-'+groupedRules[0].name"
    >
      <div
        v-for="rule of groupedRules"
        :key="rule.name"
      >
        <div class="d-flex">
          <div class="pr-1">
            <div
              class="rules-check"
              :class="{ checked: rule.test() }"
              :aria-label="rule.test() ? 'Requirement met' : 'Requirement not met'"
            />
          </div>
          <div>
            {{ rule.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.password-rules {
  display: flex;
}

.password-rules > div {
  flex: 1;
}

.password-rules .rules-check {
  border-radius: 100%;
  border: 2px solid rgb(var(--v-theme-primary));
  width: 16px;
  height: 16px;
  margin-top: 4px;
}

.password-rules .rules-check.checked {
  border-radius: 100%;
  background: rgb(var(--v-theme-primary));
}

</style>
