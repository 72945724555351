<script lang="ts" setup>
import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import {reactive} from "vue"
import {useRouter} from "vue-router"
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"
import AMainSection from "@/components/AMainSection.vue"

const router = useRouter()

const form = reactive({
  email: '',
})

const handleFormSuccess = () => {
  router.push(route(ROUTES.ADMIN.COACHES.INDEX))
}

</script>

<template>
  <a-main-section
    heading="Invite Coach"
    :return-to="route(ROUTES.ADMIN.COACHES.INDEX)"
    return-text="Manage Coaches"
  >
    <app-server-validation-form
      endpoint="/admin/coach-invites"
      @success="handleFormSuccess"
      :data="form"
      v-slot="{ submit, loading, getErrors }"
    >
      <v-text-field
        v-model="form.email"
        variant="outlined"
        type="email"
        placeholder="Email"
        :error-messages="getErrors('email')"
      />

      <v-btn
        color="primary"
        size="large"
        :loading="loading"
        @click="submit"
      >
        Invite Coach
      </v-btn>
    </app-server-validation-form>
  </a-main-section>
</template>
