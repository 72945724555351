<script setup lang="ts">
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"
import AppTable from "@/components/AppTable.vue"
import {useHead} from "@unhead/vue"
import router from "@/router"

useHead({ title: 'My Appointments' })

const headers = [
  {
    title: 'Status',
    key: 'status',
    sortable: false
  },
  {
    title: 'User',
    key: 'user.full_name',
    sortable: false
  },
  {
    title: 'Service',
    key: 'service',
    sortable: false
  },
  {
    title: '',
    key: 'actions',
    sortable: false
  },
]
</script>

<template>
  <app-table
    endpoint="/coach/appointments"
    striped
    :headers="headers"
  >
    <template #mobile-item="{item}">
      <v-list-item @click="router.push(route(ROUTES.COACHES.APPOINTMENT, { appointmentId: item.id }))">
        <v-list-item-title>
          Appointment With: <span class="font-weight-bold">{{ item.user.full_name }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          Service: <span class="font-weight-bold">{{ item.service.name }}</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Format: <span class="font-weight-bold">{{ item.service.format }}</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Delivery Method: <span class="font-weight-bold">{{ item.service.delivery_method }}</span>
        </v-list-item-subtitle>
        <template #append>
          <v-list-item-action>
            <v-icon color="primary">
              mdi-open-in-new
            </v-icon>
          </v-list-item-action>
        </template>
      </v-list-item>
    </template>
    <template #item.service="{value}">
      {{ value.name }} {{ value.delivery_method }} {{ value.format }}
    </template>
    <template #item.actions="{item}">
      <router-link
        :to="route(ROUTES.COACHES.APPOINTMENT, { appointmentId: item.id })"
      >
        <v-icon color="primary">
          mdi-open-in-new
        </v-icon>
      </router-link>
    </template>
  </app-table>
</template>


<style lang="scss" scoped>
.v-tabs-window {
  padding-top: 24px;
}
</style>
