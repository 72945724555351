<script setup lang="ts">

import {ref} from "vue"
import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import ThePasswordRules from "@/components/ThePasswordRules.vue"
import useRouteTo from "@/composables/useRouteTo"
import useAuth from "@/composables/useAuth"

const {routeTo, ROUTES} = useRouteTo()
const {$auth} = useAuth()

const model = defineModel<boolean>({
  required: true,
})

const emits = defineEmits<{
  'success': [];
}>()

const isLogin = ref<boolean>(true)
const loginForm = ref({
  email: null,
  password: null,
})
const showPassword = ref<boolean>(false)

const registerForm = ref({
  first_name: null,
  middle_name: null,
  last_name: null,
  email: '',
  password: '',
  password_confirmation: '',
  consent: false,
})

const login = async (data: {access_token: string}) => {
  localStorage.setItem("token", data.access_token)
  await $auth.loadUser()
  emits('success')
}

</script>

<template>
  <v-dialog
    v-model="model"
    max-width="600"
    scrollable
  >
    <v-card>
      <v-card-title class="bg-primary d-flex">
        {{ isLogin ? 'Login To Proceed' : 'Signup To Proceed' }}
        <v-spacer />
        <v-icon
          @click="model = false"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-title class="text-center">
        Welcome {{ isLogin ? 'back' : '' }} to Prime Health RN
      </v-card-title>
      <v-card-text>
        <app-server-validation-form
          v-if="isLogin"
          full-width
          hide-success
          :data="loginForm"
          endpoint="/auth/login"
          @success="login"
          class="d-flex flex-column"
          v-slot="{ submit, loading, getErrors }"
        >
          <v-text-field
            v-model="loginForm.email"
            :error-messages="getErrors('email')"
            variant="outlined"
            type="email"
            prepend-inner-icon="mdi-account"
            placeholder="Email"
            :disabled="loading"
          />
          <v-text-field
            v-model="loginForm.password"
            name="password"
            variant="outlined"
            placeholder="Password"
            prepend-inner-icon="mdi-lock"
            :type="showPassword ? 'text' : 'password'"
            @keydown.enter="submit"
            :error-messages="getErrors('password')"
            :disabled="loading"
          >
            <template #append-inner>
              <v-btn
                variant="text"
                small
                style="margin-top: -2px;"
                tabindex="-1"
                @click="showPassword = !showPassword"
              >
                <v-icon
                  size="20"
                >
                  {{ !showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <v-btn
            color="primary"
            :loading="loading"
            size="x-large"
            class="align-self-center"
            @click="submit"
          >
            Login
          </v-btn>
          <p class="text-center mt-5">
            No Account?
            <span
              @click="isLogin = false"
              class="text-primary cursor-pointer"
            >
              Signup
            </span>
          </p>
        </app-server-validation-form>
        <app-server-validation-form
          v-else
          full-width
          hide-success
          :data="registerForm"
          endpoint="/register"
          @success="login"
          class="d-flex flex-column"
          v-slot="{ submit, loading, getErrors }"
        >
          <v-text-field
            v-model="registerForm.first_name"
            placeholder="First Name"
            :disabled="loading"
            :error-messages="getErrors('first_name')"
          />
          <v-text-field
            v-model="registerForm.middle_name"
            placeholder="Middle Name"
            :disabled="loading"
            :error-messages="getErrors('middle_name')"
          />
          <v-text-field
            v-model="registerForm.last_name"
            placeholder="Last Name"
            :disabled="loading"
            :error-messages="getErrors('last_name')"
          />

          <v-text-field
            v-model="registerForm.email"
            type="email"
            placeholder="Email"
            :disabled="loading"
            :error-messages="getErrors('email')"
          />

          <v-text-field
            v-model="registerForm.password"
            placeholder="Password"
            :type="showPassword ? 'text' : 'password'"
            @keydown.enter="submit"
            :disabled="loading"
            :error-messages="getErrors('password')"
          >
            <template #append>
              <v-btn
                variant="text"
                small
                style="margin-top: -2px;"
                tabindex="-1"
                @click="showPassword = !showPassword"
              >
                <v-icon
                  size="20"
                >
                  {{ !showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <v-text-field
            v-model="registerForm.password_confirmation"
            type="password"
            placeholder="Password Confirmation"
            :disabled="loading"
            :error-messages="getErrors('password_confirmation')"
          />

          <the-password-rules
            :value="registerForm.password"
          />

          <v-checkbox
            v-model="registerForm.consent"
          >
            <template #label>
              <div>
                I have read and understood the
                <router-link
                  target="_blank"
                  :to="routeTo(ROUTES.TERMS_AND_CONDITIONS)"
                >
                  Terms & Conditions
                </router-link> ,
                <router-link
                  target="_blank"
                  :to="routeTo(ROUTES.DISCLAIMER)"
                >
                  Website Disclaimer
                </router-link> , and
                <router-link
                  target="_blank"
                  :to="routeTo(ROUTES.PRIVACY_POLICY)"
                >
                  Privacy Policy
                </router-link>
              </div>
            </template>
          </v-checkbox>

          <v-btn
            color="primary"
            :loading="loading"
            size="x-large"
            class="align-self-center"
            @click="submit"
          >
            Signup
          </v-btn>

          <p class="align-self-center mt-4">
            Already have an account?
            <span
              @click="isLogin = true"
              class="text-primary cursor-pointer"
            >
              Login
            </span>
          </p>
        </app-server-validation-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">

</style>
