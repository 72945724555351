<script setup lang="ts">
import TextClamp from 'vue3-text-clamp'
import useFile from "@/composables/useFile"
import useRouteTo from "@/composables/useRouteTo"

defineProps<{
  focusArea: App.Models.FocusArea
}>()

const { ROUTES, routeTo } = useRouteTo()
const fileUrl = useFile()

</script>

<template>
  <v-card
    :to="routeTo(ROUTES.FOCUS_AREA, {focusAreaId: focusArea.id})"
  >
    <v-img
      height="200px"
      :src="fileUrl(focusArea.file_id, 'original')"
      cover
    />

    <v-card-title class="d-flex justify-space-between text-wrap">
      {{ focusArea.name }}
      <v-btn
        color="primary"
        variant="text"
        :to="routeTo(ROUTES.FOCUS_AREA, {focusAreaId: focusArea.id})"
      >
        <v-icon color="primary">
          mdi-open-in-new
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="clipped">
      <text-clamp
        :max-lines="3"
        :text="focusArea.description"
      />
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
</style>
