<script setup lang="ts">
import {ref} from "vue"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"
import useRouteTo from "@/composables/useRouteTo"
import CoachHero from "@/components/CoachHero.vue"
import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import {useRouter} from "vue-router"
import {useHead} from "@unhead/vue"

const {routeTo, ROUTES} = useRouteTo()
const router = useRouter()

useHead({ title: 'Review' })

const props = defineProps<{
  appointmentId: string;
}>()


const review = ref({
  text: null,
  rating: 1,
})

const {axios} = useAxios()
const appointment = ref<App.Models.Appointment | null>(null)
const load = async () => {
  const {data} = await axios.get(`/appointments/${props.appointmentId}`)
  appointment.value = data
}

</script>

<template>
  <a-loader :action="load">
    <coach-hero
      :coach="appointment!.coach!"
      return-text="Review Coach"
      :return-to="routeTo(ROUTES.COACH_PROFILE, {coachId: appointment!.coach_id})"
      hide-bio
    />
    <v-container>
      <app-server-validation-form
        :data="review"
        :endpoint="`/appointments/${props.appointmentId}/reviews`"
        method="post"
        @success="() => router.push(routeTo(ROUTES.USERS.APPOINTMENT, {appointmentId: props.appointmentId}))"
        v-slot="{ submit, loading, getErrors }"
      >
        <div class="d-flex flex-column gr-6">
          <h1 class="heading">
            Review
          </h1>
          <v-label class="label">
            Your Rating
          </v-label>
          <v-rating
            v-model="review.rating"
            :error-messages="getErrors('rating')"
            color="primary"
            dense
          />
          <v-label class="label">
            Write a review
          </v-label>
          <v-textarea
            v-model="review.text"
            rows="5"
            :error-messages="getErrors('text')"
          />
          <v-btn
            :loading="loading"
            @click="submit"
            size="x-large"
            style="width: fit-content"
            color="primary"
            text="Submit"
          />
        </div>
      </app-server-validation-form>
    </v-container>
  </a-loader>
</template>

<style scoped lang="scss">
.heading {
  color: rgb(var(--v-theme-primary));
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 26px;
  font-weight: 600;
}

.label {
  color: #000;
  opacity: 1;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
}
</style>
