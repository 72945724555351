<script setup lang="ts">

defineModel<number|string>({
  default: 0,
})

withDefaults(defineProps<{
  readonly?: boolean;
}>(), {
  readonly: false,
})

const emit = defineEmits(['update:modelValue'])

</script>

<template>
  <div
    v-if="modelValue !== undefined && !isNaN(modelValue as any)"
    class="d-flex flex-nowrap"
  >
    <v-icon
      v-for="x in modelValue"
      :key="x"
      @click="emit('update:modelValue', x)"
      icon="mdi-star"
      color="primary"
    />
    <v-icon
      v-for="x in Math.max(0, 5 - (modelValue as number))"
      @click="emit('update:modelValue', x + (modelValue as number))"
      :key="x"
      icon="mdi-star-outline"
      color="primary"
    />
  </div>
</template>
