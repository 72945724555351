<script setup lang="ts">
import {ref} from "vue"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"
import useRouteTo from "@/composables/useRouteTo"
import AMainSection from "@/components/AMainSection.vue"
import useFile from "@/composables/useFile"
import route from "@/plugins/route"
import {useHead} from "@unhead/vue"

const { ROUTES } = useRouteTo()
const fileUrl = useFile()

const props = defineProps<{
  focusAreaId: string;
}>()

const {axios} = useAxios()
const focusArea = ref<(App.Models.FocusArea) | null>()
const load = async () => {
  const {data} = await axios.get(`/focus-areas/${props.focusAreaId}`)
  focusArea.value = data
}

useHead(() => ({
  title: focusArea.value?.name || 'Focus Area',
}))

</script>

<template>
  <a-loader :action="load">
    <a-main-section
      :heading="focusArea!.name"
      :return-to="route(ROUTES.HOME)"
      return-text="Prime Health RN"
    >
      <v-row>
        <v-col
          order="2"
          order-md="1"
          cols="12"
          md="8"
        >
          <p class="text-pre-wrap">
            {{ focusArea!.description }}
          </p>
          <v-btn
            size="x-large"
            class="mt-6"
            color="primary"
            :to="route(ROUTES.SEARCH, undefined, { focus_area: focusAreaId })"
          >
            Find a coach
          </v-btn>
        </v-col>
        <v-col
          order="1"
          order-md="2"
          cols="12"
          md="4"
        >
          <v-img
            :src="fileUrl(focusArea!.file_id, 'original')"
            aspect-ratio="1"
            cover
          />
        </v-col>
      </v-row>
    </a-main-section>
  </a-loader>
</template>

<style scoped lang="scss">
</style>
