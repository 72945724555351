<script setup lang="ts">
import {ref} from "vue"
import AppPaginationTable from "@/components/AppPaginationTable.vue"
import useAxios from "@/composables/useAxios"
import AMainSection from "@/components/AMainSection.vue"
import useAuth from "@/composables/useAuth"
import {useAppStore} from "@/stores/app"
import {useConfirm} from "vuetify-use-dialog"
import useErrorHandler from "@/composables/useErrorHandler"
import useRouteTo from "@/composables/useRouteTo"
import {useRouter} from "vue-router"

const { axios } = useAxios()
const table = ref()
const { $auth } = useAuth()
const $confirm = useConfirm()
const {errorHandler} = useErrorHandler()
const {routeTo, ROUTES} = useRouteTo()
const router = useRouter()

const q = ref('')
const status = ref(null)

const headers = [
  {
    title: 'Name',
    key: 'full_name',
  },
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Phone',
    key: 'phone',
  },
  {
    title: 'Status',
    key: 'deactivated_at',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false
  }
]

const impersonate = async (user: App.Models.User) => {
  if (!await $confirm({
    title: `Impersonate`,
    content: `Are you sure you want to impersonate ${user.full_name}`,
    cardTitleProps: {class: 'bg-primary text-white'},
  })) return
  const {data: {message, errors}, status} = await $auth.impersonate(user.id)
  if (errorHandler(status, message, errors)) {
    return
  }

  const appStore = useAppStore()
  appStore.addSuccess('Impersonating user')

  await router.push(routeTo(ROUTES.HOME))
}

</script>

<template>
  <a-main-section
    heading="Manage Users"
  >
    <v-container>
      <v-row>
        <v-col cols="9">
          <v-text-field
            v-model="q"
            placeholder="Search"
            variant="outlined"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            label="Status"
            v-model="status"
            :items="[
              { title: 'Active', value: 'active' },
              { title: 'Inactive', value: 'inactive' },
            ]"
            clearable
          />
        </v-col>
      </v-row>
    </v-container>

    <app-pagination-table
      endpoint="/admin/users"
      ref="table"
      :filters="{
        q: q,
        status: status,
      }"
      :headers="headers"
    >
      <template #item.deactivated_at="{ value }">
        <v-chip
          variant="flat"
          :color="value === null ? 'green-lighten-3' : 'red-lighten-3'"
          :title="value === null ? '' : `Deactivated on ${value}`"
          class="text-capitalize"
        >
          {{ value === null ? 'Active' : 'Inactive' }}
        </v-chip>
      </template>
      <template #item.actions="{ item }">
        <v-btn
          color="primary"
          icon="mdi-account-switch"
          size="small"
          title="Impersonate"
          variant="text"
          @click="impersonate(item)"
        />
        <v-btn
          icon="mdi-pencil"
          variant="flat"
          :to="routeTo(ROUTES.ADMIN.USERS.EDIT, { user: item.id })"
        />
        <v-btn
          icon="mdi-delete"
          variant="flat"
          @click="axios.delete(`/admin/users/${item.id}`).then(() => table.reload())"
        />
      </template>
    </app-pagination-table>
  </a-main-section>
</template>
