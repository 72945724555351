<script setup lang="ts">
import {onMounted, ref} from "vue"
import AppPaginationTable from "@/components/AppPaginationTable.vue"
import useAxios from "@/composables/useAxios"
import AMainSection from "@/components/AMainSection.vue"
import {useAppStore} from "@/stores/app"
import {useConfirm} from "vuetify-use-dialog"
import useErrorHandler from "@/composables/useErrorHandler"
import useRouteTo from "@/composables/useRouteTo"
import {useRouter} from "vue-router"
import useAuth from "@/composables/useAuth"
import ConfirmationButton from "@/components/ConfirmationButton.vue"

const { axios } = useAxios()
const table = ref()
const $confirm = useConfirm()
const { $auth } = useAuth()
const {errorHandler} = useErrorHandler()
const {routeTo, ROUTES} = useRouteTo()
const router = useRouter()

const q = ref('')

const headers = [
  {
    title: 'Featured',
    key: 'is_featured',
    sortable: false
  },
  {
    title: 'Name',
    key: 'full_name',
  },
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Phone',
    key: 'phone',
  },
  {
    title: 'Rating',
    key: 'user_rating',
  },
  {
    title: 'Focus Areas',
    key: 'focus_areas',
    sortable: false
  },
  {
    title: 'Approval Status',
    key: 'approval_status',
  },
  {
    title: 'Subscription Status',
    key: 'subscription_status',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false
  }
]

const impersonate = async (user: App.Models.User) => {
  if (!await $confirm({
    title: `Impersonate`,
    content: `Are you sure you want to impersonate ${user.full_name}`,
    cardTitleProps: {class: 'bg-primary text-white'},
  })) return
  const {data: {message, errors}, status} = await $auth.impersonate(user.id)
  if (errorHandler(status, message, errors)) {
    return
  }

  const appStore = useAppStore()
  appStore.addSuccess('Impersonating user')

  await router.push(routeTo(ROUTES.HOME))
}

onMounted(() => {
  table.value.reload()
})
</script>

<template>
  <a-main-section
    heading="Manage Coaches"
  >
    <v-card
      class="mt-4"
      color="primary"
      variant="outlined"
    >
      <v-card-title>
        Coach Options
      </v-card-title>
      <v-card-text>
        <v-btn
          color="primary"
          variant="outlined"
          text="Invite Coach by Email"
          class="mr-2"
          :to="routeTo(ROUTES.ADMIN.COACHES.INVITE)"
        />
        <v-btn
          color="primary"
          text="Add Coach"
          :to="routeTo(ROUTES.ADMIN.COACHES.CREATE)"
        />
      </v-card-text>
    </v-card>

    <div class="my-4">
      <v-text-field
        v-model="q"
        placeholder="Search"
        variant="outlined"
      />
    </div>

    <app-pagination-table
      endpoint="/admin/coaches"
      ref="table"
      :filters="{
        q: q,
      }"
      :headers="headers"
    >
      <template #item.is_featured="{ value }">
        <v-icon :color="value ? 'primary' : undefined">
          {{ value ? 'mdi-star' : 'mdi-star-outline' }}
        </v-icon>
      </template>
      <template #item.full_name="{ value, item }">
        {{ value + (item.title ? `, ${item.title}` : '') }}
      </template>
      <template #item.user_rating="{ value }">
        <v-rating
          :model-value="value"
          color="primary"
          :size="20"
          readonly
        />
      </template>
      <template #item.focus_areas="{ value }">
        {{ value.map(focusArea => focusArea.name).join(', ') || '-' }}
      </template>
      <template #item.approval_status="{ value }">
        <v-chip
          variant="flat"
          :color="{
            pending: 'blue-grey-lighten-5',
            approved: 'green-lighten-3',
            rejected: 'red-lighten-3',
          }[value]"
          class="text-capitalize"
        >
          {{ value }}
        </v-chip>
      </template>
      <template #item.subscription_status="{ item }">
        <v-chip
          variant="flat"
          :color="item.has_offline_subscription ? 'green-lighten-5' : (item.current_subscription?.status === 'active' ? 'green-lighten-3' : 'grey-lighten-5')"
          class="text-capitalize"
        >
          {{ item.has_offline_subscription ? 'Active, Offline' : (item.current_subscription?.status === 'active' ? 'Active' : 'Inactive') }}
        </v-chip>
      </template>
      <template #item.actions="{ item }">
        <div class="d-flex flex-nowrap">
          <v-btn
            color="primary"
            icon="mdi-account-switch"
            size="small"
            title="Impersonate"
            variant="text"
            @click="impersonate(item)"
          />
          <v-btn
            icon="mdi-pencil"
            variant="flat"
            :to="routeTo(ROUTES.ADMIN.COACHES.EDIT, { coachId: item.id })"
          />
          <confirmation-button
            icon="mdi-delete"
            variant="flat"
            confirmation-heading="Are you sure?"
            :confirmation-text="`Are you sure you want to delete ${item.full_name}? This cannot be undone.`"
            confirmation-button="Delete"
            @confirm="axios.delete(`/admin/coaches/${item.id}`).then(() => table.reload())"
          />
        </div>
      </template>
    </app-pagination-table>
  </a-main-section>
</template>
