import {type MaybeRef, type EmitFn, ref, toValue, nextTick, type Ref, onMounted} from "vue"
import type {VForm} from "vuetify/components"
import {get} from "lodash"
import useAxios from "@/composables/useAxios"
import {useAppStore} from "@/stores/app"
import {useLayout} from "vuetify"

export type FormProps<T> = {
  endpoint: MaybeRef<string>,
  method?: MaybeRef<string>,
  data: MaybeRef<T>,
  hideSuccess?: boolean,
  submitOnLoad: MaybeRef<boolean>,
}

export function useForm<T>(props: FormProps<T>, form: MaybeRef<VForm>, emits: EmitFn<['success', 'error']>) {
  const errorBag: Ref<{ [key: string]: string }> = ref({})
  const loading = ref(false)
  const {axios} = useAxios()
  const layout = useLayout()


  const getErrors = (field: string) => {
    return get(errorBag.value, field, [])
  }

  const submit: () => Promise<void> = async () => {
    if (!((await toValue(form).validate()).valid)) {
      throw new Error('Form is invalid')
    }

    const endpoint = toValue(props.endpoint)
    const method = toValue(props.method) ?? 'post'
    let config: any = toValue(props.data)

    if (method === "GET") {
      config = {
        params: config
      }
    }

    loading.value = true
    const {status, data} = await axios[method.toLowerCase()](toValue(endpoint), config).catch((e: any) => e)
    loading.value = false

    if (status > 204) {
      useAppStore().addError(data?.message ?? 'Unknown Error')
      errorBag.value = data?.errors ?? {}
      emits('error', errorBag)
      if (Object.keys(errorBag.value).length !== 0) {
        await nextTick(() => {
          window.scrollTo({
            top: document.getElementsByClassName('v-input--error')[0]?.getBoundingClientRect().top + document.documentElement.scrollTop - layout.mainRect.value.top,
            behavior: 'smooth',
          })
        })
      }

      return
    }

    if (!props.hideSuccess) {
      useAppStore().addSuccess('Success')
    }
    emits('success', data)
  }

  if (toValue(props?.submitOnLoad)) {
    onMounted(() => submit())
  }

  return {
    errorBag,
    loading,
    submit,
    getErrors
  }
}
