<script setup lang="ts">
import {useRoute, useRouter} from "vue-router"
import {onMounted, ref} from "vue"
import axios from "axios"
import {useUserStore} from "@/stores/user"
import {ROUTES} from "@/router/paths"
import route from "@/plugins/route"

const currentRoute = useRoute()
const router = useRouter()
const userStore = useUserStore()

const error = ref(false)

const loadUser = async () => {
  try {
    const token = currentRoute.query.token

    if (! token) {
      throw new Error('No token specified')
    }

    const { data } = await axios.post('/auth/email-login', { token })

    localStorage.setItem("token", data.access_token)

    await userStore.loadUser()

    await router.push(route(ROUTES.COACHES.PROFILE.PROFILE))

  } catch (e) {
    error.value = true
  }
}

onMounted(() => {
  loadUser()
})

</script>

<template>
  <v-container v-if="!error">
    <v-row class="my-12">
      <v-spacer />
      <v-col cols="auto">
        <v-progress-circular indeterminate />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="my-12">
      <v-spacer />
      <v-col
        cols="auto"
        class="text-center"
      >
        <p>Please wait while you are logged in...</p>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
  <v-container v-if="error">
    <v-row>
      <v-spacer />
      <v-col cols="auto">
        <p class="text-red">
          There was an error while logging you in...
        </p>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>
