<script setup lang="ts">

import useAuth from "@/composables/useAuth"

const {$auth} = useAuth()

withDefaults(defineProps<{
  service: App.Models.Service,
  edit?: boolean
  booking?: boolean
}>(), {
  edit: false,
  booking: false,
})

const emit = defineEmits<{
  edit: [],
  book: [],
}>()

</script>

<template>
  <v-card>
    <v-card-title class="d-flex text-primary align-center">
      <v-icon
        size="x-small"
        class="mr-3"
        v-if="edit"
        @click="emit('edit')"
      >
        mdi-pencil-outline
      </v-icon>
      <span class="title">{{ service.name }}</span>
      <v-spacer />
      <template v-if="service.price === 0">
        Free
      </template>
      <template v-else>
        ${{ service.price }}
      </template>
    </v-card-title>
    <v-card-text class="d-flex flex-column gr-4 align-start">
      <div class="delivery-format">
        {{ service.delivery_method }}
        <v-icon>mdi-circle-small</v-icon>
        {{ service.format }}
      </div>
      <p>
        {{ service.description }}
      </p>
      <v-btn
        color="primary"
        size="large"
        @click="emit('book')"
        v-if="booking && !$auth.isCoach"
      >
        Book Now
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.title {
  color: var(--v-theme-primary);
  text-wrap: wrap;
  word-break: break-word;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size:  26px;
  font-weight: 600;
}
.delivery-format {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
}
</style>
