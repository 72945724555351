<script setup lang="ts">

import useFile from "@/composables/useFile"
import MapPin from '@/images/map_pin.svg'
import useRouteTo from "@/composables/useRouteTo"

const fileUrl = useFile()
const {routeTo, ROUTES} = useRouteTo()
defineProps<{coach: App.Models.User}>()

</script>

<template>
  <v-card min-width="300">
    <v-card-title class="d-flex align-center">
      <v-avatar
        size="60"
        :image="fileUrl(coach.file_id)"
      />
      <div class="ml-4">
        <h3>{{ coach.full_name }}</h3>
        <p>{{ coach.title }}</p>
      </div>
      <v-spacer />
      <router-link
        :to="routeTo(ROUTES.COACH_PROFILE, { coachId: coach.id })"
        custom
        v-slot="{navigate}"
      >
        <v-icon
          color="primary"
          @click="navigate"
        >
          mdi-open-in-new
        </v-icon>
      </router-link>
    </v-card-title>
    <v-card-text class="d-flex align-center">
      <v-img
        max-width="40"
        height="50"
        class="mr-2"
        :src="MapPin"
      />
      <p style="max-width: 150px">
        {{ coach.display_address }}
      </p>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">

</style>
