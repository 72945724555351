<script setup lang="ts">
import AMainSection from "@/components/AMainSection.vue"
import useRouteTo from "@/composables/useRouteTo"
import AppointmentDetails from "@/components/AppointmentDetails.vue"
import {useHead} from "@unhead/vue"

useHead(() => ({title: 'Appointment Details'}))

const {routeTo, ROUTES} = useRouteTo()

const props = defineProps<{
  appointmentId: string | number,
}>()

</script>

<template>
  <a-main-section
    heading="Appointment Request"
    :return-to="routeTo(ROUTES.COACHES.PROFILE.APPOINTMENTS)"
    return-text="Appointments"
  >
    <appointment-details :appointment-id="props.appointmentId" />
  </a-main-section>
</template>

<style scoped lang="scss">
</style>
