<script setup lang="ts">
import {computed, ref} from "vue"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"
import useRouteTo from "@/composables/useRouteTo"
import CoachHero from "@/components/CoachHero.vue"
import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import CoachServiceCard from "@/components/CoachServiceCard.vue"
import {useRouter} from "vue-router"
import {useHead} from "@unhead/vue"

useHead({ title: 'Booking' })

const {routeTo, ROUTES} = useRouteTo()
const router = useRouter()

const props = defineProps<{
  coachId: string | number;
  serviceId?: string | number;
}>()

const bookingRequest = ref({
  service_id: props.serviceId,
  coach_id: props.coachId,
  wellness_objectives: '',
  time_slots: '',
})

const {axios} = useAxios()
const coach = ref<(App.Models.User) | null>()
const load = async () => {
  const {data} = await axios.get(`/coaches/${props.coachId}`)
  coach.value = data
}

const selectedService = computed(() => {
  if (coach.value?.consultation_service?.id === props.serviceId) {
    return coach.value?.consultation_service
  }
  return coach.value?.services.find(service => service.id === props.serviceId)
})

</script>

<template>
  <a-loader :action="load">
    <coach-hero
      :coach="coach!"
      return-text="Review Coach"
      :return-to="routeTo(ROUTES.COACH_PROFILE, {coachId: props.coachId})"
      hide-bio
    />
    <v-container>
      <app-server-validation-form
        :full-width="true"
        :data="bookingRequest"
        endpoint="/appointments"
        method="post"
        @success="router.push(routeTo(ROUTES.USERS.APPOINTMENT, {appointmentId: $event.id}))"
        v-slot="{ submit, loading, getErrors }"
      >
        <v-row>
          <v-col
            order="2"
            order-md="1"
            cols="12"
            md="6"
            class="d-flex flex-column gr-6"
          >
            <h1 class="heading">
              Details
            </h1>
            <v-label class="label">
              What are your wellness objectives?
            </v-label>
            <v-textarea
              v-model="bookingRequest.wellness_objectives"
              hide-details="auto"
              :error-messages="getErrors('wellness_objectives')"
            />
            <v-label class="label">
              Provide two to three meeting times that work best for you
            </v-label>
            <v-label class="sub-label">
              You can provide a specific date and time, or a description of your general availability like “Tuesday afternoons”
            </v-label>
            <div class="d-flex gc-4">
              <v-textarea
                v-model="bookingRequest.time_slots"
                hide-details="auto"
                placeholder="Please enter one meeting time per line"
                :error-messages="getErrors('time_slots')"
              />
            </div>
            <v-btn
              :loading="loading"
              @click="submit"
              size="x-large"
              style="width: fit-content"
              color="primary"
              text="Proceed"
            />
          </v-col>
          <v-col
            order="1"
            order-md="2"
            cols="12"
            md="6"
            class="d-flex flex-column gr-6"
          >
            <h1 class="heading">
              Service
            </h1>
            <coach-service-card
              v-if="selectedService"
              :service="selectedService"
            />
          </v-col>
        </v-row>
      </app-server-validation-form>
    </v-container>
  </a-loader>
</template>

<style scoped lang="scss">
.heading {
  color: rgb(var(--v-theme-primary));
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 26px;
  font-weight: 600;
}

.label {
  color: #000;
  opacity: 1;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  white-space: break-spaces;
}

.sub-label {
  color: #000;
  opacity: 1;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Lato, sans-serif;
  font-size: 20px;
  letter-spacing: 0.6px;
  white-space: pre-line;
}
</style>
