<template>
  <v-text-field
    ref="inputRef"
    :model-value="formattedValue"
    v-bind="textFieldProps"
    :class="$attrs.class"
    @click:clear="clear"
    validate-on="blur"
    @update:focused="focused = $event"
    :maxlength="$attrs.maxlength || 16"
    :suffix="$attrs.suffix"
    :prefix="$attrs.prefix"
  />
</template>


<script setup lang="ts">
import {CurrencyDisplay, useCurrencyInput} from 'vue-currency-input'
import {type PropType, ref, useAttrs, watch} from 'vue'
import {VTextField} from "vuetify/components"

const model = defineModel({
  default: null,
  type: [String, Number] as PropType<string | number | null>,
})

const focused = ref(false)


defineOptions({inheritAttrs: false})
const props = defineProps({
  decimals: {
    type: [Number, String],
    default: 2
  },
})

const attrs = useAttrs()

const textFieldProps = VTextField.filterProps(attrs)

const { inputRef, formattedValue, setValue } = useCurrencyInput({
  currency: 'USD',
  currencyDisplay: CurrencyDisplay.hidden,
  precision: Number(props.decimals),
}, false)

watch(focused, (val: boolean) => {
  if (!val) {
    setTimeout(() => {
      model.value = formattedValue.value === '' ? null : formattedValue.value
    })
  }
})

const clear = () => {
  setValue(null)
  model.value = null
}

watch(model, (value: string | number | null) => {
  // @ts-ignore
  setValue(value)
})
</script>
