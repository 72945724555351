<script setup lang="ts">

</script>

<template>
  <v-container>
    <h1 class="text-center mb-4">
      Website Disclaimer
    </h1>
    <h1 style="padding-top: 4pt;text-indent: 0pt;text-align: center;">
      PRIME HEALTH RN LLC DISCLAIMER
    </h1>
    <p style="padding-top: 11pt;text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      <a
        href="http://www.primehealthrn.com/"
        class="s1"
        target="_blank"
      >Please read this Disclaimer
        carefully and in its entirety before using </a>www.primehealthrn.com (hereinafter referred to as the “Site”). The
      Site and its content are owned by Prime Health RN LLC.
    </h2>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p
      class="s2"
      style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
    >
      PURPOSE<span class="h2">: This Disclaimer is here to clearly explain, outline, and layout who we are, what we do, and what we don&#39;t do before you use, view, and/or browse the Site and/or purchase or download any course, program, service, or product offered by us. In short, the purpose of the Site is to provide information &amp; education on health and wellness, coaching, and healthcare service collaboration.</span>
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p
      class="s2"
      style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
    >
      IMPORTANT NOTE<a
        href="mailto:primehealthrnuser@gmail.com"
        class="s1"
        target="_blank"
      >: By using the Site in any capacity, you
        voluntarily agree to this Disclaimer. You agree that you have read (or had the opportunity to read and chose not
        to), understood, and consented to this Disclaimer. If you have any questions, please contact us at
        primehealthrnuser@gmail.com.</a>
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Finally, you must be at least 18 years old and be
      able to consent to this Disclaimer. If you are under the age of 18, or you do not agree with this Disclaimer as
      stated herein,
    </h2>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      please <u>STOP</u> now and do not use this Site or
      its content. By using the Site, you agree to the Disclaimer as stated herein, regardless of whether or not you
      have read it.
    </h2>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <ol id="l1">
      <li data-list-text="1.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Definitions
        </h2>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <ul id="l2">
          <li data-list-text="•">
            <h2 style="padding-left: 41pt;text-indent: -25pt;text-align: left;">
              “Company”, “We”,
              “I”, “Our”, or “Us” <a
                href="http://www.primehealthrn.com/"
                class="a"
                target="_blank"
              >means Prime Health RN
                LLC and www.primehealthrn.com</a><a
                href="http://www.primehealthrn.com/"
                class="s1"
                target="_blank"
              >.</a>
            </h2>
          </li>
          <li data-list-text="•">
            <h2 style="padding-left: 41pt;text-indent: -25pt;text-align: left;">
              “Content” <a
                href="http://www.primehealthrn.com/"
                class="a"
                target="_blank"
              >means any and all written, visual, video, or
                audio information contained on the Site, including, but not limited to, any and all emails received from or
                on behalf of Prime Health RN LLC, or www.primehealthrn.com, and any and all written or Downloadable material
                Purchased, viewed, or otherwise offered on </a><span class="p">www.primehealthrn.com, such as blog posts, graphics, designs, documents, information, templates, and materials.</span>
            </h2>
          </li>
          <li data-list-text="•">
            <h2 style="padding-left: 41pt;text-indent: -25pt;text-align: left;">
              “Purchase”,
              “Purchased”, “Purchasing”, “Downloading” or “Download” <span class="p">means any Content (as defined herein) or Courses, Services, and/or Products (as defined herein) you paid for and/or copied to your computer, hard drive, cloud system, or another process of downloading data, from this Site (as defined herein).</span>
            </h2>
          </li>
          <li data-list-text="•">
            <h2 style="padding-left: 41pt;text-indent: -25pt;text-align: left;">
              “Personal
              Information” <span class="p">means information that can be used on its own or in conjunction with other information to identify, contact, or locate a single person, or to identify an individual in context. For example, personal information includes your name, address, email address, telephone number, etc.</span>
            </h2>
          </li>
          <li data-list-text="•">
            <h2 style="padding-left: 41pt;text-indent: -25pt;text-align: left;">
              “Site, Courses,
              Services, and/or Products” <a
                href="http://www.primehealthrn.com/"
                class="a"
                target="_blank"
              >means </a><span
                class="p"
              >www.primehealthrn.com and its associated pages, Content, email list, social media posts, blog posts, courses, coaching services, group courses or programs, templates, contracts, forms, guides, eBooks, worksheets, workbooks, website materials, and/or templates available on the Site.</span>
            </h2>
          </li>
          <li data-list-text="•">
            <h2 style="padding-top: 4pt;padding-left: 40pt;text-indent: -24pt;text-align: left;">
              “Site” <a
                href="http://www.primehealthrn.com/"
                class="a"
                target="_blank"
              >means </a><span class="p">www.primehealthrn.com and any and all of its pages, tabs, or sub-pages</span>
            </h2>
            <p style="padding-left: 41pt;text-indent: 0pt;text-align: left;">
              and “Content”, as defined herein.
            </p>
          </li>
          <li data-list-text="•">
            <h2 style="padding-left: 40pt;text-indent: -24pt;text-align: left;">
              “You” or “Your”
              <span class="p">means the user, customer, or viewer of the Site.</span>
            </h2>
            <p style="text-indent: 0pt;text-align: left;">
              <br>
            </p>
          </li>
        </ul>
      </li>
      <li data-list-text="2.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Disclaimer
        </h2>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          The Site, Courses, Services, and/or Products are
          intended for <u><b>informational &amp; educational</b></u><b />purposes only and are not intended as
          professional medical advice by us. By accessing and using the Site, Courses, Services, and/or Products, such
          use shall constitute your agreement that the Site, Courses, Services, and/or Products is not professional
          medical advice and shall not be relied upon by you as such.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You should ALWAYS consult with your physician or
          another medical professional first before implementing any of our advice, information, or suggestions. You
          should always consult with a physician or medical professional (not us) before implementing any changes to
          your diet, medication, lifestyle, exercise regimen, supplement regimen, or health practices. Please only
          implement any and all changes after consulting with your physician and assessing your own risk.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="3.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Affiliate
          Links/Products
        </h2>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          From time to time, we link to products or
          services we love using affiliate links. This means that we may receive a small percentage or fee for referring
          you to any product you may purchase from one of those sites. These small fees help sustain our small business.
          We truly appreciate your support.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We participate in the Amazon Services LLC
          Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by
          linking to Amazon.com and affiliated sites.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="4.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Sponsored Posts
        </h2>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >We may feature
            sponsored blog posts. If we do, we will clearly state that the post is sponsored and by whom in the post.
            While we are committed to only featuring sponsored content from companies or products we have tried and loved,
            we make no warranties, guarantees, or representations as to the effectiveness or safety of said products or
            services. You should use them at your own risk and make all the appropriate investigations you need to on your
            own to feel comfortable using them. Please feel free to email us at </a>primehealthrnuser@gmail.com if you
          have any questions about our sponsored posts.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="5.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Information Is No
          Substitute for Professional Advice
        </h2>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p
          class="s4"
          style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          The Content contained on this Site is
          not a substitute for the advice of your attorney, physician, medical professional, mental health professional,
          financial advisor, accountant, or any other
        </p>
        <p
          class="s4"
          style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          professional you
          consult or should consult. This Site simply contains informational and educational material and
          information.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="6.">
        <p
          class="s5"
          style="padding-left: 17pt;text-indent: -12pt;text-align: left;"
        >
          No
          Warranties, Guarantees, or Representations
        </p>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p
          class="s4"
          style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          Although we do our best to maintain
          the Site and its Content, we do not warranty, guarantee, or represent that our Content or Site, Courses,
          Services, and/or Products are accurate, complete, reliable, or free of errors or that they pertain to your
          particular circumstances or health situation. Although we do our best to update the Site, we also cannot
          guarantee -- due to how rapidly things change -- that all of our Content is up to date or completely
          accurate.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="7.">
        <p
          class="s5"
          style="padding-left: 17pt;text-indent: -12pt;text-align: left;"
        >
          Disclaimer
          of Liability
        </p>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p
          class="s4"
          style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          To the extent permitted by law, we
          disclaim any and all liability pertaining to your use, purchase, or download of the Site, Courses, Services,
          and/or Products. Please use the Site, Courses, Services, and/or Products at your own risk, after making an
          independent assessment of risk.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="8.">
        <p
          class="s5"
          style="padding-left: 17pt;text-indent: -12pt;text-align: left;"
        >
          Testimonials/Examples
        </p>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p
          class="s4"
          style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          The Testimonials or examples on the
          Site are simply that: examples. While they are all accurate and authentic, we are not making any claims that
          YOU will experience the same or better results from using or purchasing the Site, Courses, Services, and/or
          Products.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="9.">
        <p
          class="s5"
          style="padding-left: 17pt;text-indent: -12pt;text-align: left;"
        >
          No
          Endorsements
        </p>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p
          class="s4"
          style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          Any links to, mentions of, or
          features of various companies, products, or services are not in any way an endorsement of said company,
          product, and/or service by us. It does not mean that we guarantee your success, satisfaction, or safety with
          respect to said company, product, or service. You should only use or do business with one of those companies,
          products, or services after you have independently investigated it, assessed the applicable risk, and
          determined you would like to use or try it.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="10.">
        <p
          class="s5"
          style="padding-left: 23pt;text-indent: -18pt;text-align: left;"
        >
          Earnings
          Disclaimer
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p
          class="s4"
          style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          We make every effort to accurately
          represent our products, programs and services. Any earnings stated on the Site are estimates or examples only
          of what’s possible. They are not guarantees of future results, guaranteed success or a promise that you will
          experience the same or even similar results. There is no guarantee that past results will be replicated in the
          future. The failure or success of use of our products, programs and services relies on your own due diligence
          and efforts. We are not liable for the success or failure of your business, health, financial situation,
          and/or any other effect from use of our products, programs and/or services.
        </p>
        <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="11.">
        <p
          class="s5"
          style="padding-left: 23pt;text-indent: -18pt;text-align: left;"
        >
          Privacy
          Policy + Terms &amp; Conditions
        </p>
        <p
          class="s4"
          style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          Please read our
          Privacy Policy and Terms &amp; Conditions before using the Site. Thank you.
        </p>
        <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="12.">
        <p
          class="s5"
          style="padding-left: 23pt;text-indent: -18pt;text-align: left;"
        >
          Contact
          Us
        </p>
      </li>
    </ol>
    <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      <a
        href="mailto:primehealthrnuser@gmail.com"
        class="a"
        target="_blank"
      >If you have any
        questions about the Disclaimer or its terms, or our Privacy Policy and Terms &amp; Conditions, please email us
        at </a><a
        href="mailto:primehealthrnuser@gmail.com"
        target="_blank"
      >primehealthrnuser@gmail.com.</a>
    </p>
    <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Updated On: September 10, 2024
    </p>
  </v-container>
</template>

<style scoped lang="scss">
h1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 14pt;
}

.h2, h2 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s2 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.p, p {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin: 0pt;
}

.a, a {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s4 {
  color: #333;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s5 {
  color: #333;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s6 {
  color: #0000ED;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 11pt;
}

li {
  display: block;
}

#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}

#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) ". ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

#l2 {
  padding-left: 0pt;
}

#l2 > li > *:first-child:before {
  content: "• ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}
</style>
