<script setup lang="ts">
//

defineProps<{
  confirmationHeading?: string;
  confirmationText?: string;
  confirmationButton?: string;
}>()

const emit = defineEmits(['confirm'])

</script>

<template>
  <v-dialog max-width="500">
    <template #activator="{ props: activatorProps }">
      <v-btn
        v-bind="{
          ...$attrs,
          onClick: undefined,
          ...activatorProps,
        }"
      />
    </template>

    <template #default="{ isActive }">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="text-h5 text-medium-emphasis ps-2">
            {{ confirmationHeading || 'Confirm' }}
          </div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click="isActive.value = false"
          />
        </v-card-title>

        <v-card-text>
          {{ confirmationText || 'Are you sure you would like to perform this action?' }}
        </v-card-text>

        <v-card-actions class="ms-4 mb-4">
          <v-btn
            text="Cancel"
            @click="isActive.value = false"
            variant="outlined"
            color="primary"
          />
          <VBtn
            :text="confirmationButton || 'Confirm'"
            @click="emit('confirm')"
            variant="flat"
            color="primary"
          />
          <v-spacer />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
