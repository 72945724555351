<script lang="ts" setup>

</script>

<template>
  <v-container>
    <h1>MASTER SERVICES AGREEMENT</h1>
    <p>
      This MASTER SERVICES AGREEMENT (the "<strong>Agreement</strong>") is a binding contract between you ("<strong>Client</strong>")
      and PRIME HEALTH RN LLC, a Georgia limited liability company with a principal place of business located at 5711
      Chanta Lane, Powder Springs, GA 30127 ("<strong>Company</strong>"). Company and Client may sometimes be referred
      to herein individually as a "Party", and collectively referred to as the "Parties." This Agreement governs your
      access to and use of the Company's Services defined below.
    </p>
    <p>
      This document contains very important information regarding your rights and obligations, as well as conditions,
      limitations, and exclusions that might apply to you. Please read it carefully.
    </p>
    <p>
      This agreement takes effect when you click the "I Accept" button below or by accessing or using the cloud
      Services (the "<strong>Effective Date</strong>").
    </p>
    <p>
      By clicking on the "I Accept" button below or by accessing or using the Services you (a) acknowledge that you
      have read and understand this Agreement; (b) represent and warrant that you have the right, power, and authority
      to enter into this Agreement and, if entering into this Agreement for an organization, that you have the legal
      authority to bind that organization; and (c) accept this Agreement and agree that you are legally bound by its
      terms and conditions herein (these "<strong>Terms</strong>").
    </p>
    <p>
      <a href="http://www.primehealthrn.com/">The Terms apply to the purchase and sale of Services through</a>
      www.primehealthrn.com (the "<strong>Site</strong>"). These Terms are subject to change by PRIME HEALTH RN LLC
      without prior written notice at any time, in Company's sole discretion. Any changes to these Terms will be in
      effect as of the "Last Updated Date" referenced on the Site. You should review these Terms prior to purchasing any
      Services that are available through this Site. Your continued use of this Site after the "Last Updated Date" will
      constitute your acceptance of and agreement to such changes.
    </p>
    <p>If you do not agree to these terms, please select the "I Decline" button below, or close this browser</p>
    <p>window. If you do not accept these terms, you may not access or use these Services.</p>
    <p>
      <strong>WHEREAS</strong>, the Company is engaged in the business of providing a marketplace and marketing
      Services;
    </p>
    <p>
      WHEREAS, Client desires to engage Company to perform such Services, on the terms and conditions set forth in
      this Agreement.
    </p>
    <p>
      NOW THEREFORE, in consideration of the mutual covenants and agreements contained and set forth herein, and other
      good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged by both Parties and
      the Parties each intending to be bound agree as follows:
    </p>
    <ol>
      <li>
        <p>SERVICES.</p>
        <ol>
          <li>
            <p>
              Services and Deliverables. Company shall provide to the Client the Services (as defined herein,
              "Services"). In performing the Services, Company may create a final product, site, platform, or service
              specified, which shall be ready for Client no later than THIRTY (30) days after Effective Date. Client
              shall notify Company of its approval of the Services. Provided however, use of the Services or failure to
              contest the Services within FIVE (5) business days, whichever is first, shall constitute Client's approval
              of the Services. By approving Services, Client acknowledges the Services are satisfactory and further
              waives any claim of errors or omissions associated with the Services to the fullest extent permitted by
              law. Provided however that if Client does not approve of the Services as described herein, Company shall
              make the reasonable changes requested by Client.
            </p>
          </li>
          <li>
            <p>
              Special Licensure. The Parties acknowledge and agree that the Services do not include, and Client shall
              not require Company to provide, any services or perform any actions which Company is not licensed to
              perform, including without limitation medical advice or expertise, legal services or advice of any kind,
              tax or accounting services or advice, financial, investing, or wealth management services
            </p>
            <p>
              of any kind. Company in no way shall be deemed to be acting as a medical expert, therapist, manager,
              marketing manager, business manager, financial analyst, or to be performing such other services or actions
              which may require a special license that Company does not possess.
            </p>
          </li>
          <li>
            <p>
              Force Majeure. The Parties acknowledge that Company may be delayed for a number of reasons or causes
              beyond Company's control, including without limitation inclement weather, site conditions, acts of terror,
              acts of God, pandemics, epidemics, strikes, embargoes, motor vehicle failure, the acts or interference by
              Client, the inactions of Client, the failure of Client to provide necessary information to Company, or
              internet or utility outages or failures. Accordingly, Company shall not be liable to Client for any loss,
              expense, damage, or delay resulting from or arising out of any delay or inability to perform under this
              Agreement relating to the events described in this Section or for any other reason outside the control of
              Company. Moreover, in no event shall Company be required to perform or render Services if in doing so it
              would create an increased risk of injury to Company or its agents. In the event there is a delay under
              this Agreement, Company's date of completion shall be reasonably extended.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>PAYMENT.</p>
        <ol>
          <li>
            <p>
              Prices. As consideration for the Services rendered by Company, Client shall pay Company the amount
              described on Client's invoice (the "Subscription Price"). Client acknowledges Subscription Price may
              eventually increase based on a variety of business factors. Company shall invoice Client as portions of
              the Subscription Price come due in accordance with this Agreement. Notwithstanding the foregoing or any
              other term contained herein or in any exhibit, in no event shall the Subscription Price be paid more than
              thirty (30) calendar days after the Date of Commencement, defined below.
            </p>
          </li>
          <li>
            <p>
              Fees. Client acknowledges Company shall deduct payment processing fees charged by third-party processors
              and point of sale terminals, when billing Client. Aside from expenses associated with Customer's payment
              of the fees, such as merchant services and terminal fees, Company will not deduct additional fees for
              services purchased by patients on the platform. For the sake of clarity, Company shall essentially pass
              along these fees to client.
            </p>
          </li>
          <li>
            <p>
              Expenses. Client shall be responsible for all reasonable out-of-pocket and pass-through expenses,
              including without limitation expenses in connection with third parties as approved by Client
              (collectively, "Expenses") incurred while rendering Services in connection with this Agreement. Company
              shall bill to Client, and the Client shall reimburse Company for all Expenses incurred. All Expenses shall
              be paid in full to Company within FIFTEEN (15) calendar days of Company's delivery of an invoice or
              receipt to Client.
            </p>
          </li>
          <li>
            <p>
              <strong>Non-Payment.</strong> Failure to pay any amounts of the Subscription Price or Expenses in full
              when due shall constitute a breach of this Agreement. In such an event, Company may immediately cease
              performing any additional Services without notice and without penalty or being in breach of this
              Agreement. Accordingly, amounts not paid within thirty (30) days shall accrue interest at a rate of five
              and five tenths percent (5.5%) per annum or the highest lawful charge, whichever is less; <i>provided,
                however</i>, Client shall not be required to pay any amount which could subject the Company to any
              liability for being in excess of the legally permissible rate. Upon breach for non-payment, Client shall
              then have the ability to cure within five (5) business days of its breach. In the event Client fails to
              cure its breach within that time, Company may immediately terminate this Agreement for cause and shall not
              be obligated to perform any Services due under this Agreement. Moreover, Company may seek any remedy
              available to it under state and federal law.
            </p>
          </li>
          <li>
            <p>
              Charges by Third Party Vendors. The Parties acknowledge and agree the Subscription Price is for
              Company's Services. Except as otherwise agreed to in writing by the Parties, the Subscription Price does
              not include charges or fees billed by any third-party vendor, individual, or entity. Notwithstanding
              anything to the contrary in this Agreement, or any separate written agreement, Client shall be solely
              responsible for any fees or expenses as billed by any third-party vendor, individual, or entity,
              regardless of when such expense was billed or incurred and shall hold Company harmless from the same.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>TERM. This Agreement will commence as of the Effective Date and shall continue in full force and effect</p>
        <p>
          (i) unless terminated by either Party upon delivering written Notice (as hereinafter defined) to the other
          Party in accordance with this Agreement; or (ii) unless and until both Parties execute an updated version of
          this Agreement. The default term of any agreement with Company shall be TWELVE (12) months. Unless otherwise
          specified, or unless terminated by either Party upon written Notice of no less than SEVEN (7) days prior to
          the end of any term, each Service shall automatically renew for successive terms of equal length pursuant to
          the Agreement.
        </p>
      </li>
      <li>
        <p>TERMINATION.</p>
        <ol>
          <li>
            <p>
              <strong>Client's Right to Cancel.</strong> Should Client decide to terminate this Agreement any time
              prior to the Date of Commencement, Client shall be entitled to immediately terminate this Agreement upon
              providing the Company written Notice (as hereinafter defined); <i>provided, however</i>, that any deposits
              and/or payment made by Client shall be <strong>non-refundable</strong> to the fullest extent permitted by
              law. Moreover, Client may terminate this Agreement any time after the Date of Commencement, and Client may
              do so by providing the Company written Notice at least thirty (30) business days in advance.
              Notwithstanding the immediately preceding sentence, Client shall remain obligated to pay the Subscription
              Price in accordance with this Agreement. For avoidance of confusion, any portion of Services not timely
              scheduled or used by Client prior to the date of completion shall be forfeited and shall constitute
              termination in accordance with this Section.
            </p>
          </li>
          <li>
            <p>
              Company's Right to Terminate or Cancel. Company reserves the right to immediately terminate this
              Agreement and/or Services in whole or in part without penalty or being in breach of this Agreement upon
              providing notice to the Client in the event Client: (i) becomes insolvent; (ii) indicates either verbally
              or in writing that it cannot pay amounts due under this Agreement; (iii) fails to make any payments under
              this Agreement when due and fails to cure said breach in accordance with the terms of this Agreement; (iv)
              makes an assignment for the benefit of creditors; (v) files for bankruptcy or seeks judicial termination
              or dissolution; (vi) dissolves or is terminated; (vii) is found to be engaging in an illegal activity;
              (viii) Client or Client's owners, employees, agents, or representatives is or are rude, uncooperative,
              belligerent, or threatening towards Company or any of Company's agents; (ix) becomes unresponsive for more
              than TEN (10) business days; or for any other reasonable concern Company may have, as determined in its
              sole and absolute discretion. In the event that Company terminates this Agreement or Services in
              accordance with this Section prior to the Date of Commencement, Client shall be entitled to a refund of
              any deposit and/or payments made.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          BUSINESS TO BUSINESS RELATIONSHIP. Both Parties acknowledge and agree that the relationship between Company
          and Client is a business to business, commercial relationship. Unless expressly stated otherwise in this
          Agreement, nothing within this Agreement nor any action of either Party shall be deemed to constitute either
          Party being the other Party's employee or agents nor shall this Agreement or any action of either Party be
          deemed to create any partnership, joint venture, association, or syndicate among or between any of the
          Parties, nor shall it be deemed to confer on any Party any express or implied right, power, privilege, or
          authority, to enter into any agreement or commitment, whether express or implied, or to incur any obligation
          or liability on behalf of the other Party.
        </p>
      </li>
      <li>
        <p>Client Duties.</p>
        <ol>
          <li>
            <p>
              Client shall maintain an active, unencumbered, valid nursing license, and shall provide such
              documentation to Company when requested.
            </p>
          </li>
          <li>
            <p>Client shall report any disciplinary action or lapse in licensing within SEVEN (7) days to Company.</p>
          </li>
          <li>
            <p>
              Client shall follow current standards set forth by the American Holistic Nurse's Association, and the
              American Nurse's Association for the practice of nursing.
            </p>
          </li>
          <li>
            <p>
              Client shall fully comply with HIPAA guidelines in all manners, including, but not limited to, proper
              handling of all patient information.
            </p>
          </li>
          <li>
            <p>Client shall maintain their own business licens(s).</p>
          </li>
          <li>
            <p>
              Client shall maintain their own contractual agreements with patients. Client acknowledges that no legal
              or contractual agreements will be provided from Company.
            </p>
          </li>
          <li>
            <p>
              Client shall provide Company with THREE (3) genuine patient or customer reviews before Company will begin
              Services.
            </p>
          </li>
          <li>
            <p>
              Client shall maintain current and active liability insurance, and shall provide such documentation to
              Company when requested.
            </p>
          </li>
          <li>
            <p>
              Client shall abide by all Federal Trade Commission (FTC) rules for customer reviews. Client acknowledges
              they shall not offer rewards or incentives in exchange for customer or patient reviews.
            </p>
          </li>
          <li>
            <p>
              Client acknowledges Company offers a marketing platform for business promotion, with no guaranteed
              outcomes.
            </p>
          </li>
          <li>
            <p>
              Client shall make itself reasonably available at such dates and at such times to which the Parties may
              agree from to time. Moreover, Client shall respond to Company within THREE (3) business days of any
              Company requests made in performing Services. Failure to do so shall entitle Company to act in accordance
              with the rights described in Section 4 above. In such event, Client shall be solely liable for any
              additional costs incurred or subsequently arising as a result of such force majeure events, and Company
              reserves the right to charge or withhold fees from Client for same. Company shall not issue any refunds or
              credits for costs associated with delayed or aborted Services.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>REPRESENTATIONS AND WARRANTIES.</p>
        <ol>
          <li>
            <p>
              Each Party represents and warrants that it has the full power and authority to enter into and execute
              this Agreement and has no prior obligations that conflict with or will result in any breach of any
              agreement to which it is a party.
            </p>
          </li>
          <li>
            <p>
              Client represents and warrants that any materials or such specifications furnished or supplied to Company
              by Client will not (a) infringe on the intellectual property rights of any third party or any rights of
              publicity or privacy or (b) violate any law, statute, ordinance or regulation.
            </p>
          </li>
          <li>
            <p>
              Client further represents and warrants that all materials or information Client provides Company
              regarding Client, Client's business, its competitors, and the industry altogether will be truthful and
              accurate and will not violate, defame, or infringe any rights of any third party. Moreover, Client will
              not disparage any of its competitors or their products or services.
            </p>
          </li>
          <li>
            <p>
              Client acknowledges and understands that the nature of Company's Services is such that Company cannot
              guarantee specific results and that Client's experiences and results may vary significantly from those of
              other clients. Client further acknowledges and agrees that Company is not required to procure employment,
              business, or sales for the Client and is in no way warranting an increase of the same.
            </p>
          </li>
          <li>
            <p>
              Company may make certain marketing recommendations (<i>e.g.</i>, names, photos, logos) for Client to
              use. Client acknowledges and agrees that Company is merely acting as a consultant to Client and is not
              licensed to provide medical advice, legal services such as doing trademark searches to determine the
              availability of the trademark and is not in any way acting as such, nor is Company able to determine
              whether any recommended trademarks infringe on the intellectual property rights of any third party.
              Company specifically suggests that Client seek out the opinion of an attorney to determine the
              availability of any trademarks contemplated in this Section. COMPANY HEREBY EXPRESSLY DISCLAIMS ANY SUCH
              WARRANTY OF NON-INFRINGEMENT AS IT PERTAINS TO ANY TRADEMARKS CONTEMPLATED IN THIS SECTION TO THE FULLEST
              EXTENT PERMITTED BY LAW.
            </p>
          </li>
          <li>
            <p>
              Company may from time to time introduce Client to third-party vendors, individuals, or entities that may
              be of additional service to the Client outside the scope of this Agreement. COMPANY HEREBY DISCLAIMS ANY
              AND ALL WARRANTIES REGARDING ANY THIRD-PARTY VENDOR, PERSON, OR ENTITY INTRODUCED BY COMPANY TO CLIENT OR
              ANY SERVICE OR PRODUCT THEY MAY PROVIDE, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY
              AND FITNESS FOR A PARTICULAR PURPOSE TO THE FULLEST EXTENT PERMITTED BY LAW.
            </p>
          </li>
          <li>
            <p>
              COMPANY HEREBY DISCLAIMS ANY AND ALL WARRANTIES PERTAINING TO ITS WORK WHETHER EXPRESS OR IMPLIED
              INCLUDING BUT NOT LIMITED TO THE
            </p>
            <p>
              IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE TO THE FULLEST EXTENT
              PERMITTED BY LAW.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>CONFIDENTIALITY.</p>
        <ol>
          <li>
            <p>
              Confidential Information. The Parties acknowledge that by reason of their relationship hereunder, each
              Party may disclose or provide access to the other certain Confidential Information. "Confidential
              Information" shall mean (a) information concerning a Party's products, business, and operations including,
              but not limited to, information relating to business plans, financial records, customers, suppliers,
              vendors, products, product samples, costs, sources, strategies, inventions, procedures, sales aids or
              literature, technical advice or knowledge, contractual agreements, pricing, product specifications, trade
              secrets, distribution methods, inventories, marketing strategies and interests, algorithms, data, designs,
              drawings, work sheets, blueprints, concepts, samples, inventions, manufacturing processes, computer
              programs and systems and know-how or other intellectual property, of a Party and its affiliates that may
              be at any time furnished, communicated or delivered to a Party, whether in oral, tangible, electronic or
              other form; and (b) all other non-public information provided by any Party hereunder. All Confidential
              Information shall remain the exclusive property of the disclosing Party.
            </p>
          </li>
          <li>
            <p>
              Use of Confidential Information; Standard of Care. The receiving Party shall maintain the disclosing
              Party's Confidential Information in strict confidence and disclose the Confidential Information only to
              its employees, subcontractors and representatives who (a) have a need to know such Confidential
              Information in order to fulfill the business affairs and transactions between the Parties contemplated by
              this Agreement; (b) have been informed of the confidential nature of the Confidential Information
              furnished by the disclosing Party and the receiving Party's obligations with respect thereto; and (c) are
              bound by confidentiality obligations no less restrictive as this Agreement. The receiving Party shall at
              all times remain responsible for breaches of this Agreement arising from the acts of its employees,
              subcontractors and representatives. Receiving Party shall use the same degree of care as it uses with
              respect to its own similar information, but no less than a reasonable degree of care, to protect the
              Confidential Information from any unauthorized use, disclosure, dissemination, or publication. Each Party
              shall only use the Confidential Information in furtherance of its performance of its obligations under
              this Agreement, and agrees not to use the other Party's Confidential Information for any other purpose or
              for the improper benefit of themselves or to the benefit of any third party.
            </p>
          </li>
          <li>
            <p>
              Exceptions; Required Disclosures. Confidential Information does not include information that: (a) was
              lawfully in the receiving Party's possession before receipt from the disclosing Party; (b) becomes
              generally available to the public other than through any act or omission of the receiving Party; (c) is
              received by the receiving Party from a third party free to make such disclosure without, to the best of
              the receiving Party's knowledge, breach of any legal or contractual obligation; (d) is independently
              developed by the receiving Party without use of the Confidential Information; or (e) is disclosed by
              receiving Party with the disclosing Party's prior written approval. If the receiving Party is confronted
              with legal action to disclose Confidential Information received under this Agreement, it shall, unless
              prohibited by applicable law, provide prompt written notice to the disclosing Party to allow the
              disclosing Party an opportunity to seek a protective order or other relief it deems appropriate, and the
              receiving Party shall cooperate and reasonably assist the disclosing Party in such efforts. If disclosure
              is nonetheless required, the receiving Party shall limit its disclosure to only that portion of the
              Confidential Information which it is advised by its legal counsel must be disclosed. To be clear, any
              other term or provision otherwise contained in this Agreement notwithstanding, nothing within this
              Agreement is meant to limit receiving Party's right to or prevent receiving Party from reporting any
              unlawful action of the disclosing Party to a governing authority or agency or from disclosing the same to
              their attorneys for the purposes of obtaining counsel regarding receiving Party's rights and obligations
              under the law or under any applicable whistleblowing statute or regulation.
            </p>
          </li>
          <li>
            <p>
              Unauthorized Use or Disclosure of Confidential Information. In the event the receiving Party discovers
              that any Confidential Information has been used, disseminated, or accessed in violation of this Agreement,
              it will immediately notify the disclosing Party, take all commercially reasonable actions available to
              minimize the impact of the use, dissemination, or publication, and take any and all necessary steps to
              prevent any further breach of this Agreement. Provided however that Client acknowledges that Company
              provides services to a large and diverse group of businesses and individuals. It shall not be a violation
              or evidence of a violation of the Company's confidentiality obligations merely because Company provides
              services to companies in the same industry as Client. Both Parties acknowledge, covenant, and agreement
              that Company is a professional in consulting services adept at compartmentalizing information while
              performing services for multiple clients, including competing enterprises, without disclosing or using
              Confidential Information.
            </p>
          </li>
          <li>
            <p>
              Return of Confidential Information. The receiving Party shall promptly return all copies of Confidential
              Information at any time upon request or within THIRTY (30) days following termination of this Agreement.
              Client acknowledges that Company may continue to retain the Confidential Information on electronic back-up
              files after the information has been otherwise deleted or destroyed in accordance with its own document
              retention policy.
            </p>
          </li>
          <li>
            <p>
              Survival. Notwithstanding the foregoing, the Confidential Information disclosed to the receiving Party
              shall be protected during the Parties' relationship with each other and shall continue after the date of
              termination of the Parties' relationship with each other and this Agreement for so long as the
              Confidential Information remains confidential. Moreover, any information deemed trade secrets as defined
              by Georgia law shall be protected during the Parties' relationship with each other and shall continue
              after the date of termination of the Parties' relationship with each other and this Agreement for so long
              as the information or material remains a trade secret under Georgia law. This Section as well as the
              indemnification obligations shall survive the execution and termination of this Agreement, the performance
              of the Parties' obligations under this Agreement, and the Parties' relationship with each other.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          NON-SOLICITATION. In consideration of the mutual covenants and agreements contained and set forth herein,
          and other good and valuable consideration the receipt, sufficiency, and adequacy of which is hereby
          acknowledged by both Parties, Client hereby agrees that upon entering into this Agreement with Company and for
          a period of TWO (2) years after the termination of this Agreement, Client and Client's signatory to this
          Agreement shall not directly or indirectly by assisting others solicit, induce, or attempt to solicit or
          induce any business from Company's customers, employees, or clients, including actively sought prospective
          customers or clients, or Company's Agents, with whom Client had material contact during Company's performance
          of Services for the purpose of attempting to sell, license, or provide the same or similar products or
          services for the benefit of Client without Company's knowledge and prior consent in writing, which may be
          withheld by Company in its sole and absolute discretion.
        </p>
      </li>
      <li>
        <p>
          NON-COMPETITION. During the term of this Agreement and for ONE (1) year following any termination, Client
          shall refrain from, without Company's prior written consent (which may be withheld for any reason or no reason
          at all), directly or indirectly, for themselves, together or on behalf of any other party, providing Services
          similar in any way to Services provided by Company within ONE HUNDRED (100) miles of 5711 Chanta Lane, Powder
          Springs, GA 30127.
        </p>
      </li>
      <li>
        <p>
          EQUITABLE RELIEF. Both Parties acknowledge, covenant, and agree that the restrictive covenants set forth in
          Sections 9 and 10 are reasonable and reasonably necessary for the protection of the Parties' legitimate
          business interests and shall not place an undue burden or restraint upon the Parties. If either Party breaches
          those provisions, the other Party shall be damaged and monetary damages alone shall not fully compensate the
          other Party for said breach. Accordingly, the aggrieved Party shall be entitled to immediate injunctive relief
          prohibiting any violation of this Agreement, and the other Party shall also be permitted to pursue any and all
          other remedies available to it in law or in equity, including but not limited to compensatory damages,
          incidental damages, consequential damages, punitive damages, expenses and costs, reasonable attorneys' fees,
          and any other remedies allowed by law.
        </p>
      </li>
      <li>
        <p>INTELLECTUAL PROPERTY.</p>
        <ol>
          <li>
            <p>
              Ownership. Client hereby covenants and agrees that all improvements, inventions, modifications, ideas,
              discoveries, computer programs, Services, works of authorship, and other work product of Company
              hereunder, whether patentable, copyrightable, or trademarkable or not, Company shall conceive or first
              actually reduce to practice or fix in a tangible medium, whether alone or in conjunction with others,
              while engaged in performing the Services or any work for Client (the "Intellectual Property"), shall be,
              as between Client and Company, the sole and exclusive property of Company. Client agrees that with respect
              to Intellectual Property and any work performed for Client hereunder, which results in Company's
              preparation of written reports, studies, analysis, research data, strategies, or other similar work
              product, as between Company and Client, Company shall retain exclusive right, title, and interest in and
              to such Intellectual Property and work product and all derivative works thereof unless specifically and
              clearly stated otherwise. As owner of the Intellectual Property, Company retains ownership of and the
              right to use any content or Services, in part or in full, in perpetuity and in any medium for Company's
              own use and for any lawful purpose, whether promotional or otherwise. To be clear, any materials used in
              performing Services including without limitation brochures, worksheets, and templates are Intellectual
              Property and the sole and exclusive property of the Company.
            </p>
          </li>
          <li>
            <p>
              Name and Likeness. Client hereby grants to Company the exclusive, irrevocable and unrestricted, right,
              license and authority (but not the obligation) to use and display Client's name, image, likeness, voice,
              face, photographs, other likeness and biography, or any other simulations thereof, in publications and
              channels and means of distribution as Company may determine at any time in connection with the Company
              services, and the distribution, advertising, and exploitation of the same. In addition, Client grants to
              Company the right to publicize Client's association with the Company and Company's services and to
              advertise and promote Client's position as customer for the Company, including endorsements by Client of
              Company and its business and the services. Upon Company's request, Client shall provide additional
              information to Company in furtherance of this Section in a timely manner.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          INDEMNIFICATION. Each Party shall indemnify, defend, and hold harmless the other party and all of its
          agents, officers, employees, owners, members, heirs, successors, and assigns from and against any and all
          third-party claims, losses, suits, damages, legal or otherwise, arising out of or in any way connected with:
          (1) its own breach of this Agreement or (2) its own actions or inactions or those of its agents and employees
          or work performed thereof.
        </p>
      </li>
      <li>
        <p>
          LIMITATION OF LIABILITY. Except in connection with third-party claims for which a Party seeks indemnification
          hereunder, a breach of the restrictive covenant or intellectual property provisions in Sections 9, 10, and 11,
          or gross negligence or willful misconduct, neither Party shall be liable to the other for incidental,
          consequential, special or punitive damages, or lost or imputed profits arising out of this Agreement or its
          termination or expiration, whether for breach (or alleged breach) of a representation or warranty or any
          obligation arising therefrom or otherwise, whether liability is asserted in contract or tort and irrespective
          of whether the Party has been advised of the possibility of such loss or damage. Either Party hereby waives
          any claim that these exclusions deprive such Party of adequate remedy. Moreover, if Company is liable to
          Client pursuant to this Section 14 for any reason, such liability shall be limited to the Subscription Price
          paid to Company by Client hereunder. For the sake of clarity, no liability of any kind may be imputed upon
          Company for claims against Client. Company exclusively provides a marketing and financial billing platform and
          offers no further services.
        </p>
      </li>
      <li>
        <p>MISCELLANEOUS.</p>
        <ol>
          <li>
            <p>
              Notices. All Notices or communications to Company required or permitted under this Agreement shall be in
              writing and shall be deemed effective when received and made in writing by either (i) hand
            </p>
            <p>
              delivery, (ii) registered mail, (iii) certified mail, return receipt requested, or (iv) overnight mail,
              addressed to the Party to be notified at the addresses listed in the Agreement. Either Party may change
              its address from time to time by providing notice to the other Party.
            </p>
          </li>
          <li>
            <p>
              Exhibits. All exhibits to this Agreement (collectively, "Exhibits") are hereby incorporated by reference
              as if set forth fully herein. Capitalized terms used within the Exhibits not otherwise defined therein
              shall have the meanings as those terms set forth in this Agreement. To the extent there is a conflict in
              the terms of this Agreement and the Exhibits, the terms of the Exhibits control as to those conflicting
              terms only.
            </p>
          </li>
          <li>
            <p>
              Survival. The representations, warranties, indemnification rights, and those sections that would
              logically survive the termination or cancellation of this Agreement shall survive the execution of this
              Agreement.
            </p>
          </li>
          <li>
            <p>
              Governing Law. This Agreement and the relationship between the Parties will be governed in accordance
              with the laws of the State of Georgia without giving effect to any other laws or rules that would conflict
              or cause the application of any other laws.
            </p>
          </li>
          <li>
            <p>
              Waiver. No waiver by any Party of any provision hereof will be effective unless explicitly set forth in
              writing and signed by the Party so waiving.
            </p>
          </li>
          <li>
            <p>
              Entire Agreement. This Agreement and all Exhibits hereto represent the final, entire, and integrated
              agreement between the Parties.
            </p>
          </li>
          <li>
            <p>
              Modification. This Agreement may only be modified if done so in a writing signed by both Company and
              Client.
            </p>
          </li>
          <li>
            <p>
              <strong>Severability.</strong> Each provision shall be considered severable, and if one or more of the
              provisions of this Agreement or the Exhibits hereto, or any portion thereof are held to be invalid,
              illegal, or unenforceable then it shall be severed and shall not affect the balance of the Agreement and
              Exhibits; <i>provided, however</i>, that if an adjudicator of competent jurisdiction finds that it is
              invalid, illegal, or unenforceable but that by limiting said word, phrase, clause, sentence, or other
              portion it shall become valid, legal, and enforceable then it shall be deemed so limited and construed to
              the maximum extent permitted by law.
            </p>
          </li>
          <li>
            <p>
              Counterparts & Signatures. This Agreement may be executed online via website, or in any number of
              counterparts, each of which shall be an original but all of which shall constitute one instrument. A
              Party's signature sent by email or facsimile shall be deemed an original and binding signature.
            </p>
          </li>
          <li>
            <p>
              Construction. No presumption or rule requiring that the terms of this Agreement must be construed
              against the Party that drafted it or prepared it shall apply to this Agreement, any Exhibit, or any
              amendment, addendum, or other modification to the same.
            </p>
          </li>
          <li>
            <p>
              Satisfaction. By clicking the "I Agree" button, the Parties represent, warrant, and agree that they have
              read this Agreement, understand its terms, and acknowledge the same shall be binding upon them.
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <p>Last updated on October 19, 2024</p>
  </v-container>
</template>

<style lang="scss" scoped>
.h1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  font-size: 14pt;
}

.s1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 14pt;
}

.h2, h2 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s3 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s4 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.p, p {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin: 12px 0;
}

.a, a {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s8 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 11pt;
}

li {
  display: block;
}

#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}

#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

#l2 {
  padding-left: 0pt;
}

#l2 > li > *:first-child:before {
  content: "• ";
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l3 {
  padding-left: 0pt;
}

#l3 > li > *:first-child:before {
  content: "• ";
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
</style>
