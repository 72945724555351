import { $auth } from "@/plugins/auth"
import type { NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded } from "vue-router"

export default class Authorization implements App.Middleware.Instance {
  async handle(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    next: App.Middleware.Caller,
    cancel: NavigationGuardNext
  ): Promise<void> {
    if (!$auth.user) {
      return cancel("/")
    }

    if (to.meta.type && $auth.user.type !== to.meta.type) {
      return cancel("/")
    }

    await next(to, from, cancel)
  }
}
