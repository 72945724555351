<script setup lang="ts">

import {computed, ref} from "vue"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"
import useRouteTo from "@/composables/useRouteTo"
import AppNumberInput from "@/components/AppNumberInput.vue"
import CoachServiceCard from "@/components/CoachServiceCard.vue"
import CoachHero from "@/components/CoachHero.vue"
import {useLayout} from "vuetify"
import ReviewCard from "@/components/ReviewCard.vue"
import FocusAreaCard from "@/components/FocusAreaCard.vue"
import useAuth from "@/composables/useAuth"
import LoginSignupDialog from "@/components/LoginSignupDialog.vue"
import {useRouter} from "vue-router"
import {useHead} from "@unhead/vue"

const {routeTo, ROUTES} = useRouteTo()
const router = useRouter()
const layout = useLayout()


const props = defineProps<{
  coachId: string | number;
}>()

const filters = ref<{
  search: string | null;
  delivery: string | null;
  format: string | null;
  price_min: number | null;
  price_max: number | null;
}>({
  search: null,
  delivery: null,
  format: null,
  price_min: null,
  price_max: null,
})

const {axios} = useAxios()
const coach = ref<(App.Models.User) | null>()
const load = async () => {
  const {data} = await axios.get(`/coaches/${props.coachId}`)
  coach.value = data
}
useHead(() => {
  return {
    title: coach.value?.full_name,
  }
})

const filteredService = computed(() => {
  return coach!.value?.services!.filter(service => {
    return (filters.value.search ? service.name.toLowerCase().includes(filters.value.search.toLowerCase()) : true)
      && (filters.value.delivery ? service.delivery_method === filters.value.delivery : true)
      && (filters.value.format ? service.format === filters.value.format : true)
      && (filters.value.price_min ? service.price >= filters.value.price_min : true)
      && (filters.value.price_max ? service.price <= filters.value.price_max : true)
  })
})

const scrollToServices = () => {
  window.scrollTo({
    top: document.querySelector('#services')!.getBoundingClientRect().top + document.documentElement.scrollTop - layout.mainRect.value.top,
    behavior: 'smooth',
  })
}

const {$auth} = useAuth()
const redirectLink = ref<any>(null)
const showLoginDialog = ref<boolean>(false)
const handleBookRequest = async (service: App.Models.Service) => {
  redirectLink.value = routeTo(ROUTES.USERS.APPOINTMENT_REQUEST, {coachId: props.coachId, serviceId: service.id})
  if ($auth.user) {
    return router.push(redirectLink.value)
  }
  showLoginDialog.value = true
}


const handleLoginSignupSuccess = () => {
  showLoginDialog.value = false
  router.push(redirectLink.value)
}

</script>

<template>
  <a-loader :action="load">
    <div class="coach-page">
      <coach-hero
        :coach="coach!"
        return-text="Coaches"
        :return-to="() => router.go(-1)"
      >
        <v-card
          class="coach-page__actions"
        >
          <v-card-title>
            Reach Out
          </v-card-title>
          <v-card-actions class="flex-wrap justify-center align-center">
            <v-btn
              color="primary"
              variant="outlined"
              width="238"
              v-if="coach!.consultation_service"
              @click="handleBookRequest(coach!.consultation_service!)"
            >
              Book Free Consultation
            </v-btn>
            <v-btn
              color="primary"
              variant="flat"
              width="238"
              @click="scrollToServices"
            >
              Book Appointment
            </v-btn>
          </v-card-actions>
        </v-card>
      </coach-hero>
      <v-container class="d-flex flex-column gr-6">
        <h1 class="coach-page__heading">
          My Services
        </h1>
        <v-text-field
          label="Search"
          clearable
          v-model="filters.search"
        />
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filters.delivery"
              label="Delivery"
              clearable
              :items="['In-person', 'Virtual']"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filters.format"
              label="Format"
              clearable
              :items="['Group', 'One-on-one']"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <app-number-input
              v-model="filters.price_min"
              clearable
              label="Price Min"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <app-number-input
              v-model="filters.price_max"
              clearable
              label="Price Max"
            />
          </v-col>
        </v-row>
        <v-row id="services">
          <v-col
            cols="12"
            sm="6"
            v-for="(service) in filteredService"
            :key="service.id"
          >
            <coach-service-card
              :service="service"
              booking
              @book="handleBookRequest(service)"
            />
          </v-col>
        </v-row>
        <h1 class="coach-page__heading">
          My Focus Areas
        </h1>
        <v-slide-group
          show-arrows
        >
          <v-slide-group-item
            v-for="focusArea in coach!.focus_areas"
            :key="focusArea.id"
          >
            <focus-area-card
              class="ma-3"
              style="width: 245px;"
              :focus-area="focusArea"
            />
          </v-slide-group-item>
        </v-slide-group>
        <template v-if="coach!.reviews!.length">
          <h1 class="coach-page__heading">
            My Reviews
          </h1>
          <v-slide-group
            show-arrows
          >
            <v-slide-group-item
              v-for="review in coach!.reviews"
              :key="review.id"
            >
              <review-card
                class="ma-3"
                style="width: 245px;"
                hide-coach
                :review="review"
              />
            </v-slide-group-item>
          </v-slide-group>
        </template>
      </v-container>
    </div>
    <login-signup-dialog
      v-model="showLoginDialog"
      @success="handleLoginSignupSuccess"
    />
  </a-loader>
</template>

<style scoped lang="scss">
.coach-page {
  &__heading {
    color: rgb(var(--v-theme-primary));
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 44px;
    font-weight: 800;
  }

  &__actions {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
  }
}

</style>
