<script setup lang="ts">

import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import {ref, watch} from "vue"

const model = defineModel<boolean>({
  required: true,
})


const props = defineProps<{
  coachId: number;
}>()

const review = ref({
  first_name: null,
  rating: null,
  text: null,
  coach_id: props.coachId,
})


watch(() => model, (val) => {
  if (!val) {
    review.value = {
      first_name: null,
      rating: null,
      text: null,
      coach_id: props.coachId,
    }
  }
})

watch(() => props.coachId, (val) => {
  review.value.coach_id = val
})

const emit = defineEmits<{
  success: [];
}>()

const handleSuccess = () => {
  model.value = false
  emit('success')
}

</script>

<template>
  <v-dialog
    v-model="model"
    scrollable
    max-width="500"
  >
    <v-card>
      <v-card-title class="bg-primary d-flex align-center">
        <span class="white--text">Add Review</span>
        <v-spacer />
        <v-icon
          @click="model = false"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <app-server-validation-form
          :data="review"
          endpoint="/admin/reviews"
          full-width
          @success="handleSuccess"
          v-slot="{ submit, loading, getErrors }"
        >
          <div class="d-flex flex-column gr-6">
            <v-label class="label">
              First Name
            </v-label>
            <v-text-field
              v-model="review.first_name"
              :error-messages="getErrors('first_name')"
            />
            <v-label class="label">
              Your Rating
            </v-label>
            <v-rating
              v-model="review.rating"
              :error-messages="getErrors('rating')"
              color="primary"
              dense
            />
            <v-label class="label">
              Write a review
            </v-label>
            <v-textarea
              v-model="review.text"
              rows="5"
              :error-messages="getErrors('text')"
            />
            <v-btn
              :loading="loading"
              @click="submit"
              size="x-large"
              class="align-self-end"
              color="primary"
            >
              Submit
            </v-btn>
          </div>
        </app-server-validation-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.heading {
  color: rgb(var(--v-theme-primary));
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 26px;
  font-weight: 600;
}

.label {
  color: #000;
  opacity: 1;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
}
</style>
