<script lang="ts" setup>
import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import {reactive, ref} from "vue"
import ThePasswordRules from "@/components/ThePasswordRules.vue"
import useAxios from "@/composables/useAxios"
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"
import ALoader from "@/components/ALoader.vue"
import AMainSection from "@/components/AMainSection.vue"
import InlineForm from "@/components/InlineForm.vue"

const {axios} = useAxios()

const props = defineProps<{
  user: number;
}>()

const userData = ref<Record<any, any>>()

const form = reactive({
  first_name: null,
  middle_name: null,
  last_name: null,
  email: null,
  password: '',
  password_confirmation: '',
})

const handleFormSuccess = async () => {
  form.password = form.password_confirmation = ''
}

const load = async () => {
  await axios.get(`/admin/users/${props.user}`)
    .then(({data}) => {
      userData.value = data
      form.first_name = data.first_name
      form.middle_name = data.middle_name
      form.last_name = data.last_name
      form.email = data.email
    })
}
</script>

<template>
  <a-loader :action="load">
    <a-main-section
      :heading="userData?.full_name"
      return-text="Manage Users"
      :return-to="route(ROUTES.ADMIN.USERS.INDEX)"
    >
      <v-card
        class="my-4"
        color="primary"
        variant="outlined"
      >
        <v-card-title>
          User Options
        </v-card-title>
        <v-card-text>
          <inline-form
            v-if="userData?.deactivated_at"
            v-slot="{ submit, loading }"
            :endpoint="`/admin/users/${props.user}/deactivation`"
            method="delete"
            @success="load"
          >
            <v-btn
              :loading="loading"
              color="primary"
              text="Activate Profile"
              @click="submit"
            />
          </inline-form>
          <inline-form
            v-else
            v-slot="{ submit, loading }"
            :endpoint="`/admin/users/${props.user}/deactivation`"
            @success="load"
          >
            <v-btn
              :loading="loading"
              color="primary"
              text="Deactivate Profile"
              @click="submit"
            />
          </inline-form>
        </v-card-text>
      </v-card>

      <app-server-validation-form
        :endpoint="`/admin/users/${props.user}`"
        :data="form"
        method="put"
        @success="handleFormSuccess"
        v-slot="{ submit, loading, getErrors }"
      >
        <v-text-field
          v-model="form.first_name"
          label="First Name"
          :error-messages="getErrors('first_name')"
        />

        <v-text-field
          v-model="form.middle_name"
          label="Middle Name (optional)"
          :error-messages="getErrors('middle_name')"
        />

        <v-text-field
          v-model="form.last_name"
          label="Last Name"
          :error-messages="getErrors('last_name')"
        />

        <v-text-field
          v-model="form.email"
          type="email"
          label="Email"
          :error-messages="getErrors('email')"
        />

        <v-text-field
          v-model="form.password"
          type="password"
          label="New Password (leave blank to keep original)"
          :error-messages="getErrors('password')"
        />

        <v-text-field
          v-model="form.password_confirmation"
          type="password"
          label="Password Confirmation"
          :error-messages="getErrors('password_confirmation')"
        />

        <the-password-rules :value="form.password" />

        <v-btn
          color="primary"
          size="large"
          :loading="loading"
          @click="submit"
        >
          Save User
        </v-btn>
      </app-server-validation-form>
    </a-main-section>
  </a-loader>
</template>
