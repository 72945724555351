import { useUserStore } from "@/stores/user"
import type { App } from "vue"
import type {AxiosResponse} from "@/plugins/axios"

export interface Auth {
  user: App.Models.User | null
  isAdmin: boolean
  isCoach: boolean
  isUser: boolean
  logout: () => Promise<void>
  loadUser: () => Promise<void>
  loggedIn: boolean,
  impersonate: (userId: number) => Promise<AxiosResponse<{ access_token: string }>>
  stopImpersonating: () => Promise<AxiosResponse<{ access_token: string }>>
}

export const $auth: Auth = {
  get user(): App.Models.User | null {
    const userStore = useUserStore()
    return userStore.user
  },
  set user(user: App.Models.User | null) {
    const userStore = useUserStore()
    userStore.user = user
  },
  get isAdmin(): boolean {
    return this.user?.type === 'admin'
  },
  get isCoach(): boolean {
    return this.user?.type === 'coach'
  },
  get isUser(): boolean {
    return this.user?.type === 'user'
  },
  loadUser() {
    const userStore = useUserStore()
    return userStore.loadUser(true)
  },
  logout() {
    const userStore = useUserStore()
    return userStore.logout()
  },
  get loggedIn() {
    return !!this.user
  },
  async impersonate(userId: number) {
    const userStore = useUserStore()
    return await userStore.impersonate(userId)
  },
  async stopImpersonating() {
    const userStore = useUserStore()
    return await userStore.stopImpersonating()
  },
}


export default {
  install(app: App) {
    app.config.globalProperties.$auth = $auth
  }
}
