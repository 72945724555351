<script setup lang="ts">
import {ref} from "vue"
import LogoMinimalColor from '@/../img/LogoMinimalColor.svg'
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"
import InlineForm from "@/components/InlineForm.vue"

const email = ref('')

</script>

<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="auto">
        <v-img
          contain
          width="123"
          :src="LogoMinimalColor"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <h1 class="text-primary text-center">
          Forgot Password
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <inline-form
          endpoint="/forgot-password"
          :data="{ email }"
          success-message="A notification has been sent to your email. Please follow the instructions to reset your password"
          v-slot="{ submit, loading }"
        >
          <v-card
            elevation="1"
            variant="outlined"
            class="pa-4"
          >
            <v-card-title class="text-sm-body-1 text-center text-wrap">
              Send a password reset notification to your email
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="email"
                name="email"
                variant="outlined"
                type="email"
                prepend-inner-icon="mdi-account"
                placeholder="Email"
                :disabled="loading"
              />

              <v-btn
                :to="route(ROUTES.LOGIN)"
                variant="plain"
                size="sm"
              >
                Return to Login
              </v-btn>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :loading="loading"
                @click="submit"
              >
                Reset Password
              </v-btn>
            </v-card-actions>
          </v-card>
        </inline-form>
      </v-col>
    </v-row>
  </v-container>
</template>
