<script lang="ts" setup>
import {ref} from "vue"
import AMainSection from "@/components/AMainSection.vue"
import {useRouter} from "vue-router"
import FocusAreaForm from "@/components/FocusAreaForm.vue"
import useRouteTo from "@/composables/useRouteTo"

const router = useRouter()
const {routeTo, ROUTES} = useRouteTo()

const focusArea = ref({
  name: null,
  description: null,
  file_id: null
})

const handleFormSuccess = () => {
  router.push(routeTo(ROUTES.ADMIN.FOCUS_AREAS.INDEX))
}

</script>

<template>
  <a-main-section
    heading="Create Focus Area"
    return-text="Manage Focus Areas"
    :return-to="routeTo(ROUTES.ADMIN.FOCUS_AREAS.INDEX)"
  >
    <focus-area-form
      v-model="focusArea"
      endpoint="/admin/focus-areas"
      method="post"
      @success="handleFormSuccess"
    />
  </a-main-section>
</template>
