<script setup lang="ts">
import CoachRating from "@/components/CoachRating.vue"
import useFile from "@/composables/useFile"
import useRouteTo from "@/composables/useRouteTo"
import {ref} from "vue"
import useAxios from "@/composables/useAxios"
import ConfirmationButton from "@/components/ConfirmationButton.vue"
import {computed} from "vue"
import {$auth} from "@/plugins/auth"

const fileUrl = useFile()
const {routeTo, ROUTES} = useRouteTo()
const { axios } = useAxios()

const props = withDefaults(defineProps<{
  review: App.Models.Review,
  hideCoach?: boolean,
}>(), {
  hideCoach: false,
})

const showActions = computed(() => $auth.isAdmin)
const showingMore = ref(false)

const deleteReview = async () => {
  await axios.delete(`/admin/reviews/${props.review!.id}`)

  location.reload()
}

</script>
<template>
  <v-card>
    <v-card-text>
      <p v-if="review?.text.length > 140">
        {{ showingMore ? review!.text : `${review!.text.substring(0, 140)}...` }}
        <button
          class="text-decoration-underline"
          @click="showingMore = !showingMore"
        >
          Show {{ showingMore ? `Less` : `More` }}
        </button>
      </p>
      <p v-else>
        {{ review!.text }}
      </p>

      <p class="font-weight-bold d-flex mt-1 mb-4">
        {{ review?.first_name || review.user?.first_name }}
        <coach-rating
          class="ml-2"
          :model-value="review.rating"
          readonly
        />
      </p>

      <router-link
        custom
        v-if="!hideCoach && review.coach"
        v-slot="{ navigate }"
        :to="routeTo(ROUTES.COACH_PROFILE, { coachId: review.coach!.id })"
      >
        <div
          class="d-flex align-center coach-info cursor-pointer"
          @click="navigate"
        >
          <v-avatar
            size="36px"
          >
            <v-img
              alt="Avatar"
              :src="fileUrl(review.coach!.file_id)"
            />
          </v-avatar>
          <div
            class="coach-name"
          >
            <div class="coach-full-name">
              {{ review.coach!.full_name }}
            </div>
            <div>
              {{ review.coach!.title }}
            </div>
          </div>
        </div>
      </router-link>

      <v-card-actions
        v-if="showActions"
      >
        <confirmation-button
          color="red"
          @confirm="deleteReview"
          confirmation-heading="Delete Review?"
          confirmation-text="Are you sure you want to delete this review?"
          icon="mdi-delete"
        />
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.coach-info {
  margin: 8px 0;
}

.coach-name {
  margin-left: 12px;
}

.coach-full-name {
  font-weight: 800;
}
</style>
