<script setup lang="ts">
import {ref} from "vue"
import LogoMinimalColor from '@/../img/LogoMinimalColor.svg'
import {useRouter} from "vue-router"
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"
import InlineForm from "@/components/InlineForm.vue"
import ThePasswordRules from "@/components/ThePasswordRules.vue"

defineProps<{
  token: string;
}>()

const router = useRouter()

const email = ref('')
const password = ref('')
const passwordConfirmation = ref('')
const showPassword = ref(false)

const onResetPassword = async () => {
  await router.push(route(ROUTES.LOGIN))
}
</script>

<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="auto">
        <v-img
          contain
          width="123"
          :src="LogoMinimalColor"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <h1 class="text-primary text-center">
          Reset Password
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <inline-form
          endpoint="/forgot-password/reset"
          :data="{ email, password, token, password_confirmation: passwordConfirmation }"
          @success="onResetPassword"
          v-slot="{ submit, loading }"
        >
          <v-card
            elevation="1"
            variant="outlined"
            class="pa-4"
          >
            <v-card-title class="text-sm-body-1 text-center text-wrap">
              Enter a new password
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="email"
                name="email"
                variant="outlined"
                type="email"
                prepend-inner-icon="mdi-account"
                placeholder="Email"
                :disabled="loading"
              />

              <v-text-field
                v-model="password"
                name="password"
                variant="outlined"
                placeholder="Password"
                prepend-inner-icon="mdi-lock"
                :type="showPassword ? 'text' : 'password'"
                :disabled="loading"
              >
                <template #append>
                  <v-btn
                    icon
                    small
                    style="margin-top: -2px;"
                    tabindex="-1"
                    @click="showPassword = !showPassword"
                  >
                    <v-icon
                      size="20"
                    >
                      {{ !showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>

              <v-text-field
                v-model="passwordConfirmation"
                name="password_confirmation"
                variant="outlined"
                placeholder="Password Confirmation"
                prepend-inner-icon="mdi-lock"
                type="password"
                :disabled="loading"
              />

              <the-password-rules
                :value="password"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :loading="loading"
                @click="submit"
              >
                Reset Password
              </v-btn>
            </v-card-actions>
          </v-card>
        </inline-form>
      </v-col>
    </v-row>
  </v-container>
</template>

