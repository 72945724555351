<script setup lang="ts">
import {onMounted, ref} from "vue"
import AppPaginationTable from "@/components/AppPaginationTable.vue"
import route from "@/plugins/route"
import useAxios from "@/composables/useAxios"
import AMainSection from "@/components/AMainSection.vue"
import {ROUTES} from "@/router/paths"
import ConfirmationButton from "@/components/ConfirmationButton.vue"
import useFile from "@/composables/useFile"
import TextClamp from 'vue3-text-clamp'

const { axios } = useAxios()
const table = ref()
const fileUrl = useFile()

const q = ref('')

const headers = [
  {
    title: '',
    key: 'file_id',
    sortable: false
  },
  {
    title: '#',
    key: 'position',
  },
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Description',
    key: 'description',
  },
  {
    title: 'Actions',
    key: 'actions',
    width: '200px',
    sortable: false
  }
]

onMounted(() => {
  table.value.reload()
})
</script>

<template>
  <a-main-section
    heading="Manage Focus Areas"
  >
    <v-card
      class="mt-4"
      color="primary"
      variant="outlined"
    >
      <v-card-title>
        Admin Options
      </v-card-title>
      <v-card-text>
        <v-btn
          color="primary"
          text="Add Focus Area"
          :to="route(ROUTES.ADMIN.FOCUS_AREAS.CREATE)"
        />
      </v-card-text>
    </v-card>

    <div class="my-4">
      <v-text-field
        v-model="q"
        placeholder="Search"
        variant="outlined"
      />
    </div>

    <app-pagination-table
      endpoint="/admin/focus-areas"
      ref="table"
      :filters="{
        q: q,
      }"
      :headers="headers"
    >
      <template #item.file_id="{ value }">
        <div class="pa-2">
          <v-avatar
            size="60"
            :image="fileUrl(value)"
          />
        </div>
      </template>
      <template #item.description="{ value }">
        <text-clamp
          :max-lines="3"
          :text="value"
        />
      </template>
      <template #item.actions="{ item }">
        <v-btn
          icon="mdi-pencil"
          variant="flat"
          :to="route(ROUTES.ADMIN.FOCUS_AREAS.EDIT, { focusAreaId: item.id })"
        />
        <confirmation-button
          icon="mdi-delete"
          variant="flat"
          confirmation-heading="Are you sure?"
          :confirmation-text="`Are you sure you want to delete ${item.full_name}? This cannot be undone.`"
          confirmation-button="Delete"
          @confirm="axios.delete(`/admin/focus-areas/${item.id}`).then(() => table.reload())"
        />
      </template>
    </app-pagination-table>
  </a-main-section>
</template>
