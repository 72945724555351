<script setup lang="ts">
import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import AppFileUpload from "@/components/AppFileUpload.vue"
import {computed, ref, watch} from "vue"
import AppAutoComplete from "@/components/AppAutoComplete.vue"
import useAuth from "@/composables/useAuth"
import ThePasswordRules from "@/components/ThePasswordRules.vue"
import CoachServiceDialog from "@/components/CoachServiceDialog.vue"
import CoachServiceCard from "@/components/CoachServiceCard.vue"
import useRouteTo from "@/composables/useRouteTo"
import AppAddressField from "@/components/AppAddressField.vue"
import LicenseSelector from "@/components/LicenseSelector.vue"


const {$auth} = useAuth()
const {routeTo, ROUTES} = useRouteTo()

const props = defineProps<{
  endpoint: string
  method: string
}>()

const coach = defineModel<App.Models.User & {password_confirmation: null | string, consent?: boolean}>({
  required: true
})

const emits = defineEmits(['success', 'error'])

const handleFormSuccess = async (data: App.Models.User) => {
  coach.value.password = ''
  coach.value.password_confirmation = ''
  emits('success', data)
}

const handleFormError = async (errors: {[key: string]: string}) => {
  emits('error', errors)
}

// Focus Area
const selectedFocusArea = ref<App.Models.FocusArea | null>(null)
const focusAreasChange = () => {
  if (!selectedFocusArea.value) {
    return
  }
  coach.value.focus_areas = coach.value.focus_areas!.filter(f => f.id !== selectedFocusArea.value!.id).concat(selectedFocusArea.value)
  selectedFocusArea.value = null
}

const removeFocusArea = (focusArea: App.Models.FocusArea) => {
  coach.value.focus_areas = coach.value.focus_areas!.filter(fa => fa.id !== focusArea.id)

}

// Services
const selectedServiceIndex = ref<number | undefined>(undefined)
const selectedService = computed<App.Models.Service | null>(() => {
  return selectedServiceIndex.value === undefined ? null : coach.value.services![selectedServiceIndex.value]
})
const showServiceDialog = ref(false)
const showAddServiceDialog = (index: number | undefined) => {
  selectedServiceIndex.value = index
  showServiceDialog.value = true
}
watch(showServiceDialog, (show) => {
  if (!show) {
    selectedServiceIndex.value = undefined
  }
})
const handleServiceUpdate = (service: App.Models.Service) => {
  if (selectedServiceIndex.value === undefined) {
    coach.value.services = [...coach.value.services!, service]
  } else {
    coach.value.services![selectedServiceIndex.value] = service
  }
  showServiceDialog.value = false
}
const handleServiceDelete = () => {
  if (selectedServiceIndex.value === undefined) {
    return
  }
  coach.value.services!.splice(selectedServiceIndex.value, 1)
  showServiceDialog.value = false
}

const handleConsultationToggle = () => {
  if (coach.value.offer_free_consultation) {
    coach.value.consultation_service = {
      description: ''
    }
  } else {
    coach.value.consultation_service = null
  }
}

const isCompleteProfile = computed(() => !coach.value.is_profile_completed)
const submitText = computed((): string => {
  if (isCompleteProfile.value && !$auth.isAdmin && !coach.value.has_offline_subscription) {
    return 'Proceed to Payment'
  } else if (coach.value.id && $auth.user?.id === coach.value.id) {
    return 'Update Profile'
  } else if (coach.value.id) {
    return 'Update Coach'
  } else {
    return 'Create Coach'
  }
})

</script>

<template>
  <app-server-validation-form
    @success="handleFormSuccess"
    @error="handleFormError"
    :endpoint="props.endpoint"
    :method="props.method"
    :data="coach"
    :full-width="true"
    v-slot="{submit: innerSubmit, loading, getErrors}"
  >
    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <v-text-field
          v-model="coach.first_name"
          label="First Name"
          :error-messages="getErrors('first_name')"
        />
        <v-text-field
          v-model="coach.middle_name"
          label="Middle Name (optional)"
          :error-messages="getErrors('middle_name')"
        />
        <v-text-field
          v-model="coach.last_name"
          label="Last Name"
          :error-messages="getErrors('last_name')"
        />
        <v-text-field
          v-model="coach.title"
          label="Title"
          :error-messages="getErrors('title')"
        />
        <v-text-field
          v-model="coach.email"
          label="Email"
          type="email"
          :error-messages="getErrors('email')"
          :autocomplete="coach.id ? 'email' : 'new-email'"
        />
        <v-text-field
          v-model="coach.password"
          label="Password"
          autocomplete="new-password"
          type="password"
          :error-messages="getErrors('password')"
        />
        <v-text-field
          v-model="coach.password_confirmation"
          type="password"
          autocomplete="confirm-password"
          label="Password Confirmation"
          :error-messages="getErrors('password_confirmation')"
        />
        <the-password-rules :value="coach.password" />
        <v-text-field
          v-model="coach.phone"
          label="Phone"
          :error-messages="getErrors('phone')"
        />
        <app-address-field
          v-model="coach.address"
          v-model:street="coach.street"
          v-model:city="coach.city"
          v-model:state="coach.state"
          v-model:zipcode="coach.zipcode"
          v-model:latitude="coach.latitude"
          v-model:longitude="coach.longitude"
          label="Address"
          clearable
          :error-messages="getErrors('address')"
        />
        <v-checkbox
          v-model="coach.hide_address"
          label="Hide my address"
          hide-details="auto"
          :error-messages="getErrors('hide_address')"
          density="compact"
          name="hide_address"
        />
        <v-select
          v-model="coach.subscription_level"
          v-if="isCompleteProfile && !$auth.isAdmin && !coach.has_offline_subscription"
          label="Subscription Level"
          class="mt-2"
          :error-messages="getErrors('subscription_level')"
          :items="['Monthly', 'Yearly']"
        />
        <v-checkbox
          v-model="coach.consent"
          v-if="isCompleteProfile && !$auth.isAdmin"
          :error-messages="getErrors('consent')"
        >
          <template #label>
            <div>
              I have read and agree to the
              <router-link
                target="_blank"
                class="text-primary"
                :to="routeTo(ROUTES.TERMS_AND_CONDITIONS)"
              >
                Terms & Conditions
              </router-link> ,
              <router-link
                target="_blank"
                class="text-primary"
                :to="routeTo(ROUTES.DISCLAIMER)"
              >
                Website Disclaimer
              </router-link> ,
              <router-link
                target="_blank"
                class="text-primary"
                :to="routeTo(ROUTES.MASTER_SERVICES_AGREEMENT)"
              >
                Master Services Agreement
              </router-link> , and
              <router-link
                target="_blank"
                class="text-primary"
                :to="routeTo(ROUTES.PRIVACY_POLICY)"
              >
                Privacy Policy
              </router-link>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col
        md="6"
        cols="12"
      >
        <app-file-upload
          v-model="coach.file_id"
          label="Profile Picture"
          accept="image/*"
          :error-messages="getErrors('file_id')"
        />
        <v-textarea
          v-model="coach.bio"
          label="Bio"
          counter="540"
          :error-messages="getErrors('bio')"
        />
      </v-col>
    </v-row>
    <license-selector v-model="coach.states" />
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        class="pb-0"
      >
        <div class="d-flex">
          <app-auto-complete
            v-model:object="selectedFocusArea"
            item-title="name"
            hide-details
            endpoint="/focus-areas"
            label="Focus Areas"
          >
            <template #append>
              <v-btn
                color="primary"
                size="large"
                @click="focusAreasChange"
              >
                Add
              </v-btn>
            </template>
          </app-auto-complete>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="pt-0"
      >
        <v-list class="py-0">
          <v-list-item
            v-for="focusArea in coach.focus_areas"
            class="pl-0"
            :key="focusArea.id"
          >
            <v-list-item-title>{{ focusArea.name }}</v-list-item-title>
            <template #prepend>
              <v-btn
                variant="text"
                @click="removeFocusArea(focusArea)"
              >
                <v-icon color="error">
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <div class="d-flex py-8 align-center">
      <v-label>Customize your services</v-label>
      <v-spacer />
      <v-btn
        color="primary"
        @click="showAddServiceDialog(undefined)"
      >
        Add
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="coach.offer_free_consultation"
          @update:model-value="handleConsultationToggle"
          :error-messages="getErrors('offer_free_consultation')"
          label="I offer a free consultation service."
        />
        <template v-if="coach.offer_free_consultation">
          <v-label>Consultation Description</v-label>
          <v-textarea
            v-model="coach.consultation_service!.description"
            :error-messages="getErrors('consultation_service.description')"
          />
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p v-if="!coach.services!.length && !$auth.isAdmin">
          Please add at least one service to continue
        </p>
        <v-row v-else>
          <v-col
            cols="12"
            md="6"
            v-for="(service, index) in coach.services!"
            :key="service.id"
          >
            <coach-service-card
              :service="service"
              edit
              @edit="showAddServiceDialog(index)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="d-flex mt-4">
      <v-spacer />
      <v-btn
        color="primary"
        size="large"
        :loading="loading"
        @click="innerSubmit"
      >
        {{ submitText }}
      </v-btn>
    </div>
    <coach-service-dialog
      :service="selectedService"
      v-model="showServiceDialog"
      @success="handleServiceUpdate"
      @delete="handleServiceDelete"
    />
  </app-server-validation-form>
</template>

<style lang="scss" scoped>

:deep(.v-label) {
  color: black;
  opacity: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
