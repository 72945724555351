<script setup lang="ts">
import {ref, watchEffect} from "vue"
import LogoMinimalColor from '@/../img/LogoMinimalColor.svg'
import {useRouter} from "vue-router"
import {useUserStore} from "@/stores/user"
import InlineForm from "@/components/InlineForm.vue"
import useRouteTo from "@/composables/useRouteTo"

const router = useRouter()
const userStore = useUserStore()
const {routeTo, ROUTES} = useRouteTo()

const form = ref({
  email: '',
  password: ''
})
const showPassword = ref(false)

const login = async (data: { access_token: string }) => {
  localStorage.setItem("token", data.access_token)
  await userStore.loadUser()
}

watchEffect(() => {
  if (userStore.user) {
    router.push(routeTo(ROUTES.HOME))
  }
})
</script>

<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="auto">
        <v-img
          contain
          width="123"
          :src="LogoMinimalColor"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <h1 class="text-primary text-center">
          Login
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <inline-form
          :data="form"
          @success="login"
          endpoint="/auth/login"
          v-slot="{ submit, loading, getErrors }"
        >
          <v-card
            elevation="1"
            variant="outlined"
            class="pa-4 border--primary"
          >
            <v-card-title
              class="text-sm-body-1 mb-2 text-center font-weight-regular"
              style="font-size: 20px; letter-spacing: 0.6px;"
            >
              <v-spacer />
              Welcome back to Prime Health RN
              <v-spacer />
            </v-card-title>
            <v-card-text class="d-flex flex-column">
              <v-text-field
                v-model="form.email"
                :error-messages="getErrors('email')"
                variant="outlined"
                type="email"
                prepend-inner-icon="mdi-account"
                label="Email"
                :disabled="loading"
              />

              <v-text-field
                v-model="form.password"
                name="password"
                variant="outlined"
                label="Password"
                prepend-inner-icon="mdi-lock"
                :type="showPassword ? 'text' : 'password'"
                @keydown.enter="submit"
                :error-messages="getErrors('password')"
                :disabled="loading"
              >
                <template #append-inner>
                  <v-btn
                    variant="text"
                    small
                    style="margin-top: -2px;"
                    tabindex="-1"
                    @click="showPassword = !showPassword"
                  >
                    <v-icon
                      size="20"
                    >
                      {{ !showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                color="primary"
                :loading="loading"
                class="align-self-center"
                size="x-large"
                @click="submit"
              >
                Login
              </v-btn>
              <p class="text-center mt-5">
                No Account?
                <router-link
                  :to="routeTo(ROUTES.USERS.CREATE)"
                  class="text-primary"
                >
                  Signup
                </router-link>
              </p>

              <router-link
                :to="routeTo(ROUTES.FORGOT_PASSWORD)"
                variant="text"
                class="text-center mt-4 text-primary text-decoration-none"
              >
                Forgot my password
              </router-link>
            </v-card-text>
          </v-card>
        </inline-form>
      </v-col>
    </v-row>
  </v-container>
</template>
