<script setup lang="ts">
</script>

<template>
  <v-container>
    <h1 class="text-center mb-4">
      Privacy Policy
    </h1>
    <p
      class="s1"
      style="padding-top: 4pt;padding-left: 13pt;text-indent: 0pt;text-align: left;"
    >
      Prime Health RN LLC and
      PrimeHealthRN.com Privacy Policy
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      PLEASE READ THIS PRIVACY POLICY CAREFULLY AND IN ITS
      ENTIRETY BEFORE USING PRIMEHEALTHRN.COM (HEREINAFTER REFERRED TO AS THE “SITE”).
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      This Privacy Policy is here to better serve those
      who are concerned with how their information is used online. The following describes what type of information we
      collect, what it’s used for, and the measures we take to protect it.
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      IMPORTANT NOTE: By using the Site and/or purchasing,
      viewing, downloading, or otherwise signing up to receive Prime Health RN LLC email list newsletter, social media
      posts, blog posts, courses, coaching services, guides, eBooks, forms, worksheets, workbooks, website materials,
      templates, PDF forms, and/or invoices. (hereinafter collectively referred to as the “Site, Courses, Services,
      and/or Products”), you voluntarily agree to be bound by this Privacy Policy.
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      <a
        href="mailto:primehealthrnuser@gmail.com"
        class="a"
        target="_blank"
      >If you have any
        questions about this Privacy Policy, please contact Amanda Rogers-Beckley at </a><a
        href="mailto:primehealthrnuser@gmail.com"
        target="_blank"
      >primehealthrnuser@gmail.com.</a>
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      If you do not agree with the terms of this Privacy
      Policy, do not use or browse the Site.
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <ol id="l1">
      <li data-list-text="1.">
        <h2 style="padding-left: 22pt;text-indent: -17pt;text-align: left;">
          Definitions
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Company” <a
            href="http://www.primehealthrn.com/"
            class="a"
            target="_blank"
          >means Prime Health RN LLC and
            www.primehealthrn.com</a><a
            href="http://www.primehealthrn.com/"
            class="s2"
            target="_blank"
          >.</a>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Content” <a
            href="mailto:primehealthrn@gmail.com"
            class="a"
            target="_blank"
          >means any and all written, visual, video, or
            audio information contained on the Site, including, but not limited to, any and all emails received
            from </a><a
            href="mailto:primehealthrn@gmail.com"
            class="s3"
            target="_blank"
          >primehealthrn@gmail.com</a><a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >, </a><a
            href="mailto:primehealthrnuser@gmail.com"
            class="s3"
            target="_blank"
          >primehealthrnuser@gmail.com</a><a
            href="mailto:primehealthrncoach@gmail.com"
            class="a"
            target="_blank"
          >, </a><a
            href="mailto:primehealthrncoach@gmail.com"
            class="s3"
            target="_blank"
          >primehealthrncoach@gmail.com</a><a
            href="http://www.primehealthrn.com/"
            class="a"
            target="_blank"
          >, any of the registered Nurse Coaches, Amanda
            Rogers- Beckley, Prime Health RN LLC, or www.primehealthrn.com, and any and all written or downloadable
            material purchased, viewed, or otherwise offered on </a><span class="p">www.primehealthrn.com, such as blog posts, graphics, designs, documents, information, templates, materials, templates, PDF forms, and invoices.</span>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Personal Information” <span class="p">means information that can be used on its own or in conjunction with other information to identify, contact, or locate a person, or to identify an individual in context. For example, personal information includes, among other things, your name, address, email address, telephone number, credit card information, site behavior, etc.</span>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Site, Courses, Services, and/or Products” <a
            href="http://www.primehealthrn.com/"
            class="a"
            target="_blank"
          >means </a><span class="p">www.primehealthrn.com, Content, email list, social media posts, blog posts, courses, coaching services, guides, eBooks, forms, worksheets, workbooks, website materials, templates, PDF forms, and/or invoices available on the Site.</span>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Site” <a
            href="http://www.primehealthrn.com/"
            class="a"
            target="_blank"
          >means </a><span
            class="p"
          >www.primehealthrn.com and any and all of the Company’s associated pages,</span>
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          tabs, landing pages, forms, or sub-pages.
        </p>
        <h2 style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “You” or “Your” <span
            class="p"
          >means the user, customer, or viewer of the Site.</span>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="2.">
        <h2 style="padding-left: 16pt;text-indent: -11pt;text-align: left;">
          Company
          Statement:
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          The Site and its Content are owned by Prime
          Health RN LLC.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Prime Health RN LLC is committed to protecting
          your Personal Information. We will only collect or use your Personal Information in accordance with the
          Privacy Policy herein.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="3.">
        <h2 style="padding-left: 16pt;text-indent: -11pt;text-align: left;">
          What kind of Personal
          Information do we collect?
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p
          class="s4"
          style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          Personal Information You Provide:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          When using the Site, and in filling out forms,
          purchasing products, providing comments, or contacting us, you may be asked to enter your name, email address,
          website address, mailing address, payment or credit card information. We use this information to deliver the
          product purchased, or information requested, to improve the performance and applicability of the Site, and to
          provide you with educational content, newsletters, promotions, and special offers.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p
          class="s4"
          style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          Personal Information Automatically
          Collected:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Through use of the Site, the Company may use
          data collection technology, such as Amazon Web Services, Mailgun and Google Analytics (hereinafter referred to
          as the “Data Collection Companies”) to collect information related to your use of the Site. Generally
          speaking, this includes information about your geographic location and Site behavior. The Data Collection
          Companies also provide us with information about what type of device or software you use, your IP address
          (with location information), and whether you view the Site on mobile, tablet, or desktop.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We collect this information for statistical
          purposes only and to improve the viewer experience.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="4.">
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          What if the Personal
          Information we have about you is incorrect or you want to update it?
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >If the Personal
            Information we have collected about you is incorrect or incomplete in any way, or you would like to update
            what we have, please contact Prime Health RN at </a>primehealthrnuser@gmail.com. We will make the appropriate
          corrections when notified, as long as the corrections requested to be made are not incorrect or fraudulent in
          any way.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="5.">
        <h2 style="padding-left: 16pt;text-indent: -11pt;text-align: left;">
          When do we collect
          Personal Information?
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We collect Personal Information from you when
          you purchase, order, or sign up on and for the Site, Courses, Services, and Products, download our freebies or
          resources, subscribe to our newsletter, fill out a form, browse the Site, view Content, make purchases, enter
          any of your Personal Information on the Site, and/or communicate with coaches. If you&#39;re just viewing the
          Site, you won&#39;t be required to provide personal information to browse.
        </p>
        <p
          class="s5"
          style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          If you&#39;re
          outside of the EU<a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >: if you sign-up to
            receive any freebies, downloads, webinars, recordings, courses, or services from the Company, or purchase
            any products or services from us, you will automatically be added to our email list to receive free email
            messages from us. You can unsubscribe at any time by clicking &quot;UNSUBSCRIBE&quot; at the bottom of each
            email. If you have any questions, or difficulty unsubscribing from those emails, email Prime Health RN
            at </a><span class="p">primehealthrnuser@gmail.com to be unsubscribed from future messages.</span>
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <i><b>If you&#39;re IN the EU</b></i>: if you
          sign-up to receive any freebies, downloads, webinars, recordings, courses, or services from the Company, or
          purchase any products or services from us, you will only be added to our email list to receive free email
          messages from us if you <b>affirmatively consent </b><a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >to receiving such messages. You can
            unsubscribe at any time by clicking &quot;UNSUBSCRIBE&quot; at the bottom of each email. If you have any
            questions, or difficulty unsubscribing from those emails, email Prime Health RN at </a>primehealthrnuser@gmail.com
          to be unsubscribed from future messages.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="6.">
        <h2 style="padding-left: 16pt;text-indent: -11pt;text-align: left;">
          How do we use your
          Personal Information?
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          When using the Site, Courses, Services, and/or
          Products, we may use the Personal Information we collect from you when you register, make a purchase, sign up
          for our newsletter, respond to a survey or marketing communication (typically by email), browse the Site, or
          use certain other Site features in the following ways:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <ul id="l2">
          <li data-list-text="•">
            <p style="padding-left: 23pt;text-indent: -9pt;text-align: left;">
              To personalize your
              experience and to allow us to deliver the type of content and product offerings in which you are most
              interested.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              To
              improve our Site in order to better serve you.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              To
              allow us to better serve you in response to your customer service requests.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              To
              administer a contest, promotion, survey, or other Site feature.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 23pt;text-indent: -9pt;text-align: left;">
              To quickly process
              your transactions on and for the Site, Courses, Services, and/or Products.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              To
              send periodic emails regarding the Site, Courses, Services, and/or Products.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 13pt;text-align: left;">
              To
              tailor social media (i.e., Facebook, Instagram, etc.) advertisements to you.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;text-align: left;">
              To provide education
              to registered Nurse Coaches who are listed on the website.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br>
            </p>
          </li>
        </ul>
      </li>
      <li data-list-text="7.">
        <h2 style="padding-left: 16pt;text-indent: -11pt;text-align: left;">
          Do we share your
          Personal Information with anyone?
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
          In general, we DO NOT sell, trade, or
          otherwise transfer to outside (third) parties your Personal Information for marketing or advertising purposes,
          except for the following purposes:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <ul id="l3">
          <li data-list-text="●">
            <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
              in order to comply
              with an investigation, law enforcement inquiry, government entities, courts, or other third parties as
              required or allowed by applicable law, such as for legal and/or safety purposes.
            </p>
          </li>
          <li data-list-text="●">
            <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
              Third-party service
              providers that provide products, tools, platforms or services to us, such as email-list building, website
              management, customer service, account maintenance, and performing other activities and services related to
              the management and running of our company.
            </p>
          </li>
          <li data-list-text="●">
            <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
              Social media
              platforms, such as Facebook, Instagram, Twitter, Pinterest, etc. that offer functionalities and services to
              use their services through our website (i.e., pinning an image to Pinterest, sharing a link to Facebook). If
              you use those
            </p>
            <p style="padding-top: 4pt;padding-left: 41pt;text-indent: 0pt;text-align: left;">
              functionalities on the
              Site, your information will be shared with those platforms to complete those functions and activities.
            </p>
          </li>
          <li data-list-text="●">
            <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
              Third-party
              advertising purposes, such as advertising on social media platforms (i.e., Facebook and Instagram) to track
              and categorize your interests and behavior on our Site for the purposes of marketing and advertising to you.
              <span class="s6">We share information with these companies, and these companies may collect information, including your actions taken on the Site, through tracking methods such as Cookies. These third-parties may also possess or get information about you from your behavior/actions: directly with the third-parties; on/from other websites, mobile apps, or companies that the third-party companies work with; or from your interactions with advertisements the third-party companies show you. The information that these companies collect or that we share may be used to customize or personalize the advertisements that are displayed to you.</span>
            </p>
          </li>
        </ul>
        <p style="padding-top: 13pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We may disclose your Personal
          Information to our subsidiaries, contractors, subcontractors, assigns, affiliates or successors in interest
          when necessary to carry out our business functions. This may include website hosting partners and other
          parties who assist us in operating our website, email service, conducting our business, or serving our users,
          so long as those parties agree to keep this information confidential.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          However, non-personally identifiable visitor
          information may be provided to other parties for marketing, advertising, or other uses. If you make your
          personal information available to third parties through our Site, Courses, Services, and/or Products, Prime
          Health RN LLC is not responsible for any unauthorized use by that third party.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          It&#39;s also important to note that we DO allow
          third-party behavioral tracking.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="8.">
        <h2 style="padding-left: 16pt;text-indent: -11pt;text-align: left;">
          How do we protect your
          Personal Information?
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We aim to make your visit to our Site as safe as
          possible. The Site uses commercially acceptable methods of security protection to protect your information.
          The Site is scanned for security breaches using, for example, malware removal software.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We also use a SSL certificate and never transmit
          your credit card information by email.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Your Personal Information is contained behind
          secured networks and is only accessible by a limited number of persons who have special access rights to such
          systems, and are required to keep the Personal Information confidential. By viewing, using, or purchasing on
          or from the Site, Courses, Services, and/or Products, you acknowledge that Prime Health RN LLC and its staff
          and independent contractors may access your Personal Information.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          In addition, all sensitive credit card or
          payment information you supply is encrypted via Secure Socket Layer (SSL) technology.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We implement a variety of security measures when
          a user places an order to maintain the safety of your Personal Information. All transactions are processed
          through a gateway provider and are not stored or processed on our servers.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="9.">
        <h2 style="padding-left: 16pt;text-indent: -11pt;text-align: left;">
          Do we use &#39;cookies&#39;
          or social media pixels?
        </h2>
        <p
          class="s4"
          style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          Cookies<span
            class="p"
          >. We, and third-parties as described in Section 7 herein, use cookies and collect information from the computer, mobile phone, or other device you use to access the Site, read our emails, or view our advertisements. This information is automatically collected. Cookies are small data files that a site or its service provider transfers to your computer&#39;s hard drive through your Web browser (if you allow) that enables the site or service provider&#39;s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current Site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about Site traffic and Site interaction so that we can offer better site experiences and tools in the future.</span>
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We use cookies to:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <ul id="l4">
          <li data-list-text="●">
            <p style="padding-left: 40pt;text-indent: -17pt;text-align: justify;">
              Help remember and
              process the items in the shopping cart.
            </p>
          </li>
          <li data-list-text="●">
            <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">
              Compile aggregate
              data about site traffic and site interactions in order to offer better Site experiences and tools in the
              future. We may also use trusted third-party services that track this information on our behalf.
            </p>
          </li>
          <li data-list-text="●">
            <p style="padding-left: 40pt;text-indent: -17pt;text-align: justify;">
              To personalize
              your experience and better understand customers’ preferences for
            </p>
          </li>
        </ul>
        <p style="padding-left: 41pt;text-indent: 0pt;text-align: justify;">
          our marketing and business purposes.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You can choose to have your computer warn you
          each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser
          settings. Since each browser is a little different, look at your browser&#39;s Help Menu to learn the correct
          way to modify your cookies.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          While you may disable the use of cookies through
          your browser’s settings or options page, you may lose some of the features and functionality of the Site,
          Courses, Services, and/or Products, as cookies are necessary to help track and enhance your experience on the
          Site.
        </p>
        <p
          class="s5"
          style="padding-top: 12pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
        >
          Pixels<span
            class="p"
          >. The Company DOES NOT USE social media pixels (Facebook pixels) to track visitors to the Site so we can tailor advertisements towards those visitors on various social media platforms, including: Facebook, Instagram, etc. The Company reserves the right to use pixels in accordance with the terms of the social media platform.</span>
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="10.">
        <h2 style="padding-left: 22pt;text-indent: -17pt;text-align: left;">
          Third-Party
          Links:
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >Occasionally, at
            our discretion, we may include or offer third-party products, services, or links to articles/blogs/sites on
            our Site. These third-party sites may or may not have separate and independent privacy policies. We,
            therefore, have no responsibility or liability for the content and activities of these linked sites and/or
            their privacy policy (or lack thereof). Nonetheless, we seek to protect the integrity of our Site and welcome
            any feedback about any issues you experience with linked-to sites by emailing us at </a><a
            href="mailto:primehealthrnuser@gmail.com"
            target="_blank"
          >primehealthrnuser@gmail.com.</a>
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="11.">
        <h2 style="padding-left: 20pt;text-indent: -15pt;text-align: left;">
          Password
          Privacy:
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          While using the Site, Courses, Services, and/or
          Products, you may create a username and/or password for login. It is your responsibility to keep the username
          and password safe.
        </p>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >You are also responsible for any actions
            which occur through the use of your username/password, whether completed by you directly or through the use of
            your account. You shall notify us immediately by email at </a>primehealthrnuser@gmail.com of any unauthorized
          use of your login information or any other security breach. Please log out at the end of each session to
          prevent any unauthorized use of your account or login information.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You may not share your username/password or
          login information with anyone other than yourself. We are not responsible or liable for any loss or damages as
          a result of your failure to protect your login information or your unauthorized sharing of same.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="12.">
        <h2 style="padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Google Ads &amp;
          Analytics:
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
          Google&#39;s advertising requirements can be
          summed up by Google&#39;s Advertising Principles. They are put in place to provide a positive experience for
          you. We are not currently using Google Ads on the Site, although this may change in the future.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We have implemented the following through Google
          Analytics: Demographics and Interests Reporting.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We, along with third-party vendors such as
          Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the
          DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with
          ad impressions and other ad service functions as they relate to our website.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          To Opt-Out of Google Ads: You can set
          preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt-out
          by visiting the Network Advertising Initiative Opt-Out page or by using the Google Analytics Opt-Out Browser
          add- on.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="13.">
        <h2 style="padding-left: 21pt;text-indent: -16pt;text-align: left;">
          California Online
          Privacy Protection Act (“CalOPPA”):
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <a
            href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
            class="a"
            target="_blank"
          >CalOPPA stretches well beyond California to require any person or company that
            operates websites collecting Personal Information from California viewers/consumers to post a conspicuous
            privacy policy on its website stating exactly the information being collected and those individuals or
            companies with whom it is being shared. Read more about CalOPPA </a><a
            href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
            class="s3"
            target="_blank"
          >here</a><a
            href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
            target="_blank"
          >.</a>
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Pursuant to CalOPPA, we agree to the
          following:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Users CAN visit our site anonymously.
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          There IS a link to this Privacy Policy on the
          footer and homepage.
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Our Privacy Policy link DOES include the word
          &#39;Privacy&#39; and can easily be found on the page specified above. It is titled &quot;Privacy Policy&quot;
          very clearly.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You will be notified of any Privacy Policy
          changes on our Privacy Policy Page (see bottom
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Updated On” date).
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="14.">
        <h2 style="padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Children’s Online
          Privacy Protection Act (“COPPA”)
        </h2>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We do not specifically market
          to children under the age of 13. Please STOP and do not use, view, purchase, or otherwise browse the Site,
          Courses, Services, or Products if you are under 13 years old. If you’re younger than 13, you are not permitted
          to enter any Personal Information on this Site.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >If you are a parent
            and you believe your child under the age of 13 has provided us with Personal Information, please contact us
            immediately to have it removed by emailing us at </a><a
            href="mailto:primehealthrnuser@gmail.com"
            target="_blank"
          >primehealthrnuser@gmail.com.</a>
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="15.">
        <h2 style="padding-left: 21pt;text-indent: -16pt;text-align: left;">
          Fair Information
          Practices:
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          In order to comply with Fair Information
          Practices we will take the following responsive action, should a data breach occur:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We will notify you via email within 14 business
          days of any known breach.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="16.">
        <h2 style="padding-left: 22pt;text-indent: -17pt;text-align: left;">
          CAN-SPAM Act of
          2003:
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
          The CAN-SPAM Act is a U.S. law which
          establishes rules for commercial email messages, gives you the right to stop certain commercial emails from
          being sent to you, and outlines certain penalties for commercial entities or persons who violate the law.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We collect your email address and name so we
          can:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <ul id="l5">
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              Send information, respond to inquiries, and/or other requests or questions.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 23pt;text-indent: -9pt;text-align: left;">
              Process orders and to
              send information and updates pertaining to orders of a course, product, or service.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              Send you additional information related to your course, product and/or service.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 23pt;text-indent: -9pt;text-align: left;">
              Market to our mailing
              list or continue to send emails to you after the original transaction has occurred.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 23pt;text-indent: -9pt;text-align: left;">
              Email you a
              newsletter with free information and advertising certain Products, Services, and/or Courses we offer.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br>
            </p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
              In accordance with the CAN-SPAM Act, we
              agree to the following:
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br>
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              We
              will not use false or misleading subjects or email addresses.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              We
              will identify the email message as an advertisement in some reasonable way.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              We
              will not include our business mailing address and/or physical address in our emails.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 23pt;text-indent: -9pt;text-align: left;">
              We will monitor
              third-party email marketing services for compliance. We use Mailgun and/or Hubspot to send our emails to
              you.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 22pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              We
              will honor opt-out/unsubscribe requests quickly.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 23pt;text-indent: -9pt;text-align: left;">
              We will allow users
              to unsubscribe by using the appropriate link at the bottom of each email.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br>
            </p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
              TO UNSUBSCRIBE:
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br>
            </p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
              <a
                href="mailto:primehealthrnuser@gmail.com"
                class="a"
                target="_blank"
              >If at any time
                you would like to unsubscribe from receiving future emails, you can email us at Prime Health RN at </a>primehealthrnuser@gmail.com
              or follow the instructions at the
            </p>
            <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
              <a
                href="mailto:primehealthrnuser@gmail.com"
                class="a"
                target="_blank"
              >bottom of any email you receive from
                us and we will promptly remove you from future correspondence(s). However, unsubscribing from one list or
                set of emails may not unsubscribe you from receiving ALL future emails from us. If you experience any
                problems unsubscribing, please email Prime Health RN at </a>primehealthrnuser@gmail.com and we will
              promptly handle your removal.
            </p>
            <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
              <br>
            </p>
          </li>
        </ul>
      </li>
      <li data-list-text="17.">
        <h1 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Your General Data
          Protection Regulation (&quot;GDPR&quot;) Rights
        </h1>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          If you are located within the European Union (&quot;EU&quot;),
          you are entitled to certain rights under the GDPR. You have the right to:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <ul id="l6">
          <li data-list-text="●">
            <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
              Know how long we&#39;ll
              keep your information. We&#39;ll keep your personal information until the earlier of: (1) you either ask us
              to delete your information or (2) the Company decides it no longer needs the data and the cost of retaining
              it outweighs the value to keeping it.
            </p>
          </li>
          <li data-list-text="●">
            <p style="padding-left: 40pt;text-indent: -17pt;text-align: left;">
              Access, rectify or
              erase your personal information.
            </p>
          </li>
          <li data-list-text="●">
            <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
              Withdraw your
              consent to the Company&#39;s processing of your data, which shall have no effect on the lawfulness of the
              processing of your personal information prior to your withdrawal.
            </p>
          </li>
          <li data-list-text="●">
            <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
              Lodge a complaint
              with a supervisory authority that has jurisdiction over GDPR issues.
            </p>
          </li>
          <li data-list-text="●">
            <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
              Provide only your
              personal information which is reasonably required to enter into a contract with us. The Company will not ask
              for your consent to provide unnecessary personal information on the condition of entering into a contractual
              relationship with the Company.
            </p>
          </li>
        </ul>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="18.">
        <h2 style="padding-left: 22pt;text-indent: -17pt;text-align: left;">
          Contacting Us:
        </h2>
      </li>
    </ol>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      If there are any questions regarding this Privacy
      Policy, you may contact us using the following information:
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Prime Health RN LLC
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      <a
        href="http://www.primehealthrn.com/"
        class="a"
        target="_blank"
      >Website: </a>www.primehealthrn.com
      (see contact form)
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 202%;text-align: left;">
      <a
        href="mailto:primehealthrnuser@gmail.com"
        class="a"
        target="_blank"
      >Mailing Address: 4400 Brownsville Rd Ste 105
        PMB 201, Powder Springs, GA 30127 Email: </a><a
        href="mailto:primehealthrnuser@gmail.com"
        target="_blank"
      >primehealthrnuser@gmail.com</a>
    </p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Updated on <span
        class="p"
      >September 10, 2024</span>
    </h2>
  </v-container>
</template>

<style scoped lang="scss">
.s1 {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 16pt;
}

.p, p {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin: 0pt;
}

.a, a {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

h2 {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s2 {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s3 {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 11pt;
}

.s4 {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: italic;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s5 {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s6 {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11.5pt;
}

h1 {
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}

li {
  display: block;
}

#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}

#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) ". ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

#l2 {
  padding-left: 0pt;
}

#l2 > li > *:first-child:before {
  content: "• ";
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l3 {
  padding-left: 0pt;
}

#l3 > li > *:first-child:before {
  content: "● ";
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l4 {
  padding-left: 0pt;
}

#l4 > li > *:first-child:before {
  content: "● ";
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l5 {
  padding-left: 0pt;
}

#l5 > li > *:first-child:before {
  content: "• ";
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l6 {
  padding-left: 0pt;
}

#l6 > li > *:first-child:before {
  content: "● ";
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}
</style>
