<script setup lang="ts">
import {reactive, ref} from "vue"
import LogoMinimalColor from '@/../img/LogoMinimalColor.svg'
import {useRouter} from "vue-router"
import route from "@/plugins/route"
import InlineForm from "@/components/InlineForm.vue"
import ThePasswordRules from "@/components/ThePasswordRules.vue"
import axios from "axios"
import {useUserStore} from "@/stores/user"
import useRouteTo from "@/composables/useRouteTo"
import {useHead} from "@unhead/vue"

useHead({ title: 'Register' })

const router = useRouter()
const userStore = useUserStore()
const {routeTo, ROUTES} = useRouteTo()

const form = reactive({
  first_name: null,
  middle_name: null,
  last_name: null,
  email: '',
  password: '',
  password_confirmation: '',
  consent: false,
})

const showPassword = ref(false)

const handleFormSuccess = async () => {
  const { data } = await axios.post('/auth/login', {
    email: form.email,
    password: form.password,
  })

  localStorage.setItem("token", data.access_token)

  await userStore.loadUser()

  await router.push(route(ROUTES.LOGIN))
}
</script>

<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="auto">
        <v-img
          contain
          width="123"
          :src="LogoMinimalColor"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <h1 class="text-primary text-center">
          Sign Up
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <inline-form
          endpoint="/register"
          :data="form"
          @success="handleFormSuccess"
          v-slot="{ submit, loading, getErrors }"
        >
          <v-card
            elevation="1"
            variant="outlined"
            class="pa-4 border--primary"
          >
            <v-card-title class="text-sm-body-1 text-center">
              <v-spacer />
              Welcome to Prime Health RN
              <v-spacer />
            </v-card-title>
            <v-card-text class="d-flex flex-column">
              <v-text-field
                v-model="form.first_name"
                placeholder="First Name"
                :disabled="loading"
                :error-messages="getErrors('first_name')"
              />
              <v-text-field
                v-model="form.middle_name"
                placeholder="Middle Name"
                :disabled="loading"
                :error-messages="getErrors('middle_name')"
              />
              <v-text-field
                v-model="form.last_name"
                placeholder="Last Name"
                :disabled="loading"
                :error-messages="getErrors('last_name')"
              />

              <v-text-field
                v-model="form.email"
                type="email"
                placeholder="Email"
                :disabled="loading"
                :error-messages="getErrors('email')"
              />

              <v-text-field
                v-model="form.password"
                placeholder="Password"
                :type="showPassword ? 'text' : 'password'"
                @keydown.enter="submit"
                :disabled="loading"
                :error-messages="getErrors('password')"
              >
                <template #append-inner>
                  <v-btn
                    variant="text"
                    small
                    style="margin-top: -2px;"
                    tabindex="-1"
                    @click="showPassword = !showPassword"
                  >
                    <v-icon
                      size="20"
                    >
                      {{ !showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>

              <v-text-field
                v-model="form.password_confirmation"
                type="password"
                placeholder="Password Confirmation"
                :disabled="loading"
                :error-messages="getErrors('password_confirmation')"
              />

              <the-password-rules
                :value="form.password"
              />

              <v-checkbox
                v-model="form.consent"
              >
                <template #label>
                  <div>
                    I have read and agree to the
                    <router-link
                      target="_blank"
                      class="text-primary"
                      :to="routeTo(ROUTES.TERMS_AND_CONDITIONS)"
                    >
                      Terms & Conditions
                    </router-link> ,
                    <router-link
                      target="_blank"
                      class="text-primary"
                      :to="routeTo(ROUTES.DISCLAIMER)"
                    >
                      Website Disclaimer
                    </router-link> , and
                    <router-link
                      target="_blank"
                      class="text-primary"
                      :to="routeTo(ROUTES.PRIVACY_POLICY)"
                    >
                      Privacy Policy
                    </router-link>
                  </div>
                </template>
              </v-checkbox>

              <v-btn
                color="primary"
                :loading="loading"
                class="align-self-center"
                size="x-large"
                @click="submit"
              >
                Sign Up
              </v-btn>
              <p class="align-self-center mt-4">
                Already have an account?
                <router-link
                  :to="routeTo(ROUTES.LOGIN)"
                  class="text-primary"
                >
                  Login
                </router-link>
              </p>
            </v-card-text>
          </v-card>
        </inline-form>
      </v-col>
    </v-row>
  </v-container>
</template>
