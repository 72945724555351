<script setup lang="ts">
import useRouteTo from "@/composables/useRouteTo"
import {useDisplay } from "vuetify"

const {routeTo, ROUTES} = useRouteTo()

const {smAndDown} = useDisplay()
</script>

<template>
  <v-footer
    :height="smAndDown ? 325 : 225"
    class="footer"
  >
    <v-container class="pa-0 pa-md-4">
      <div class="d-flex flex-wrap ga-5">
        <div>
          <v-img
            src="@/../img/Logo_White.svg"
            width="166"
            height="61"
            class="mb-6"
          />
          <p>primehealthrn@gmail.com</p>
          <p>470-922-3838</p>
        </div>
        <v-spacer />
        <div class="d-flex ga-5">
          <div class="d-flex flex-column align-md-end ga-3">
            <router-link to="">
              Our Focus Areas
            </router-link>
            <router-link to="">
              Search for a Coach
            </router-link>
            <v-spacer />
            <p>PrimeHealthRN © 2024</p>
          </div>
          <v-divider
            vertical
            thickness="3"
            opacity="1"
            color="white"
          />
          <div class="d-flex flex-column align-stretch ga-3">
            <router-link :to="routeTo(ROUTES.TERMS_AND_CONDITIONS)">
              Terms & Conditions
            </router-link>
            <router-link :to="routeTo(ROUTES.DISCLAIMER)">
              Website Disclaimer
            </router-link>
            <router-link :to="routeTo(ROUTES.PRIVACY_POLICY)">
              Privacy Policy
            </router-link>
            <router-link :to="routeTo(ROUTES.MASTER_SERVICES_AGREEMENT)">
              Master Services Agreement
            </router-link>
          </div>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<style lang="scss" scoped>
a, p {
  color: white;
  text-decoration: none;
}
.footer {
  background: linear-gradient(180deg, #8DC242 0%, #61852D 100%);
}
</style>
