<script setup lang="ts">
import { ref } from 'vue'
import AMainSection from "@/components/AMainSection.vue"
import CoachProfileForm from "@/components/CoachProfileForm.vue"
import router from "@/router"
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"

const coach = ref({
  focus_areas: [],
  states: [],
  services: [],
})

const onSave = (data) => {
  router.push(route(ROUTES.ADMIN.COACHES.EDIT, {coachId: data.id}))
}

</script>

<template>
  <a-main-section
    heading="Add Coach"
    :return-to="route(ROUTES.ADMIN.COACHES.INDEX)"
    return-text="Manage Coaches"
  >
    <coach-profile-form
      @success="onSave"
      endpoint="/admin/coaches"
      method="post"
      v-model="coach"
    />
  </a-main-section>
</template>

