import type { NavigationGuardNext, RouteRecordNormalized } from "vue-router"
import {$auth} from "@/plugins/auth"
import {ROUTES} from "@/router/paths"

export default class CoachCompleteProfile implements App.Middleware.Instance {
  async handle(
    to: RouteRecordNormalized,
    from: RouteRecordNormalized,
    next: App.Middleware.Caller,
    cancel: NavigationGuardNext
  ): Promise<void> {
    if ($auth.user!.is_profile_completed) {
      return next(to, from, cancel)
    }

    return cancel({ name: ROUTES.COACHES.COMPLETE_PROFILE})
  }
}
