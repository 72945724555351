import { useUserStore } from "@/stores/user"
import type { NavigationGuardNext, RouteRecordNormalized } from "vue-router"

export default class Authentication implements App.Middleware.Instance {
  async handle(
    to: RouteRecordNormalized,
    from: RouteRecordNormalized,
    next: App.Middleware.Caller,
    cancel: NavigationGuardNext
  ): Promise<void> {
    const userStore = useUserStore()
    try {
      await userStore.loadUser()
    } catch (e) {
      cancel()
      return
    }

    await next(to, from, cancel)
  }
}
