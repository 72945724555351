<script setup lang="ts">
import LogoMinimalColor from '@/../img/LogoMinimalColor.svg'
import {useHead} from "@unhead/vue"

useHead({ title: 'Join as a Coach - Request Sent' })
</script>

<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="auto">
        <v-img
          contain
          width="123"
          :src="LogoMinimalColor"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <h1 class="text-primary text-center">
          Join as a Coach
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <v-card
          variant="outlined"
          color="primary"
          class="pa-8"
        >
          <h3 class="mb-2">
            Request Received!
          </h3>

          <div class="text-black">
            <p class="pb-2">
              Thanks for providing that information. We’ll be reaching out to
              you shortly about joining our platform.
            </p>

            <p class="pb-2">
              Thanks,
            </p>

            <p class="text-primary pb-2">
              Prime Health RN
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
