<script setup lang="ts">
import {reactive} from "vue"
import LogoMinimalColor from '@/../img/LogoMinimalColor.svg'
import route from "@/plugins/route"
import {ROUTES} from "@/router/paths"
import InlineForm from "@/components/InlineForm.vue"
import { useHead } from '@unhead/vue'
import {useRouter} from "vue-router"
import LicenseSelector from "@/components/LicenseSelector.vue"

const router = useRouter()

useHead({
  title: 'Join as a Coach'
})

const form = reactive({
  first_name: '',
  middle_name: '',
  last_name: '',
  introduction: '',
  title: '',
  email: '',
  has_nursing_license: false,
  has_nurse_training: false,
  compact_license: false,
  states: [],
})

const register = () => {
  router.push(route(ROUTES.COACHES.CREATE_REQUEST_SENT))
}
</script>

<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="auto">
        <v-img
          contain
          width="123"
          :src="LogoMinimalColor"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <h1 class="text-primary text-center">
          Join as a Coach
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset-sm="3"
        sm="6"
      >
        <inline-form
          endpoint="/coach/register"
          @success="register"
          :data="form"
          v-slot="{ submit, loading }"
        >
          <v-card
            elevation="1"
            variant="outlined"
            class="pa-4"
          >
            <v-card-text>
              <v-text-field
                v-model="form.first_name"
                name="first_name"
                variant="outlined"
                placeholder="First Name"
                :disabled="loading"
              />

              <v-text-field
                v-model="form.middle_name"
                name="name"
                variant="outlined"
                placeholder="Middle Name (optional)"
                :disabled="loading"
              />

              <v-text-field
                v-model="form.last_name"
                name="last_name"
                variant="outlined"
                placeholder="Last Name"
                :disabled="loading"
              />

              <v-text-field
                v-model="form.title"
                name="title"
                variant="outlined"
                placeholder="Title"
                :disabled="loading"
              />

              <v-text-field
                v-model="form.email"
                name="email"
                variant="outlined"
                type="email"
                placeholder="Email"
                :disabled="loading"
              />

              <!-- Tell us about yourself -->
              <h3>Tell us about yourself</h3>

              <v-textarea
                v-model="form.introduction"
              />

              <!-- Licenses -->
              <h3>Licenses</h3>

              <v-checkbox
                label="I have an active unencumbered license to practice nursing"
                v-model="form.has_nursing_license"
                color="primary"
              />

              <v-checkbox
                label="I have received training as a coach and am either certified (NBHWC, AHNCC) or eligible to take the certification exam"
                v-model="form.has_nurse_training"
                color="primary"
              />

              <license-selector v-model="form.states" />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :loading="loading"
                @click="submit"
                variant="elevated"
                size="large"
              >
                Request to Join
              </v-btn>
              <v-spacer />
            </v-card-actions>
            <v-card-actions>
              <v-spacer />
              <div>
                Already have an account?
                <v-btn
                  :to="route(ROUTES.LOGIN)"
                  color="primary"
                >
                  Log In
                </v-btn>
              </div>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </inline-form>
      </v-col>
    </v-row>
  </v-container>
</template>
