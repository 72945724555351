<script setup lang="ts">
import {ref} from 'vue'
import AMainSection from "@/components/AMainSection.vue"
import CoachProfileForm from "@/components/CoachProfileForm.vue"
import {useRouter} from "vue-router"
import useRouteTo from "@/composables/useRouteTo"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"
import useAuth from "@/composables/useAuth"
import {useHead} from "@unhead/vue"
import {$auth} from "@/plugins/auth"

useHead({ title: 'Complete Your Profile' })

const {routeTo, ROUTES} = useRouteTo()
const {axios} = useAxios()
const user = useAuth().$auth.user!
const router = useRouter()

const coach = ref<(App.Models.User & {password_confirmation: null | string}) | null>()

const load = async () => {
  const {data} = await axios.get(`/coach/coaches/${user.id}`)
  coach.value = {...data, password_confirmation: null}
}

const handleSuccess = async () => {
  await $auth.loadUser()

  if ($auth.user?.has_offline_subscription) {
    await router.push(routeTo(ROUTES.COACHES.PROFILE.PROFILE))

    return
  }

  router.push(routeTo(ROUTES.COACHES.CHECKOUT))
}

</script>

<template>
  <a-loader :action="load">
    <a-main-section
      sub-heading="COMPLETE YOUR PROFILE"
      heading="Welcome to Prime Health RN"
    >
      <coach-profile-form
        @success="handleSuccess"
        endpoint="/coach/coaches"
        method="post"
        v-model="coach"
      />
    </a-main-section>
  </a-loader>
</template>

