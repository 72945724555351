<script setup lang="ts" generic="T">
import {type MaybeRef, ref} from "vue"
import {useForm} from "@/composables/useForm"

const props = withDefaults(defineProps<{
  fullWidth?: boolean;
  endpoint: MaybeRef<string>,
  method?: MaybeRef<string>,
  data: MaybeRef<T>,
  hideSuccess?: boolean,
  submitOnLoad?: boolean,
}>(), {
  fullWidth: false,
  method: 'post',
  hideSuccess: false,
})


const emits = defineEmits(['success', 'error'])
const form = ref()

const {
  submit,
  getErrors,
  errorBag,
  loading,
} = useForm(props, form, emits)

defineExpose({
  submit,
})


</script>

<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        :sm="fullWidth ? 12 : 8"
      >
        <v-form
          ref="form"
          :readonly="loading"
          validate-on="eager"
          @submit.prevent
          v-bind="$attrs"
        >
          <slot v-bind="{ submit, loading, getErrors, errorBag }" />
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
