<script setup lang="ts">

</script>

<template>
  <v-container>
    <h1 class="text-center mb-4">
      Terms & Conditions
    </h1>
    <p
      class="s1"
      style="padding-top: 4pt;padding-left: 196pt;text-indent: -149pt;text-align: left;"
    >
      <a
        href="http://www.primehealthrn.comandPrimeHealthRNLLCTerms/"
        class="h1"
        target="_blank"
      >www.primehealthrn.com and
        Prime Health RN LLC </a><u>Terms</u> <u>&amp; Conditions</u>
    </p>
    <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      <a
        href="http://www.primehealthrn.com/"
        class="s3"
        target="_blank"
      >Please read these Terms &amp;
        Conditions carefully and in their entirety before using </a>www.primehealthrn.com (hereinafter referred to as the
      “Site”). The Site and its content are owned by Prime Health RN LLC.
    </h2>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p
      class="s4"
      style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
    >
      PURPOSE<span class="h2">: </span><span
        class="p"
      >These Terms &amp; Conditions are here to clearly explain, outline, and layout the rules, terms, and conditions of using, viewing, and/or browsing the Site and/or purchasing or downloading any course, program, service, or product offered on or by us or the Site.</span>
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      <u><i>IMPORTANT NOTES</i></u>:
    </h2>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      <a
        href="mailto:primehealthrnuser@gmail.com"
        class="s3"
        target="_blank"
      >By using the Site in
        any capacity, you voluntarily agree to these Terms &amp; Conditions. You agree that you have read, understood, and
        consented to these Terms &amp; Conditions. If you have any questions, please contact us at
        primehealthrnuser@gmail.com.</a>
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      You must be at least 18 years old and be able to
      consent to these Terms &amp; Conditions. If you are under the age of 18, or you do not agree with these Terms
      &amp; Conditions as stated herein, please <u>STOP</u> now and do not use this Site or its content. By using the
      Site, you agree to the Terms &amp; Conditions as stated herein, regardless of whether or not you have read these
      Terms &amp; Conditions.
    </h2>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      These Terms &amp; Conditions contain an <b>Arbitration
        Clause and require you to dispute or resolve any claim with us through Arbitration</b>. By agreeing to these Terms
      &amp; Conditions, you agree to the Arbitration Clause in Paragraph No. <b>18</b> and voluntarily waive your right to a jury trial.
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      By proceeding on the Site, you hereby agree to the
      following:
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br>
    </p>
    <ol id="l1">
      <li data-list-text="1.">
        <h2 style="padding-left: 22pt;text-indent: -17pt;text-align: left;">
          Definitions:
        </h2>
        <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Company”, “We”, “I”, “Our”, or “Us” <a
            href="http://www.primehealthrn.com/"
            class="a"
            target="_blank"
          >means Prime Health RN LLC and our website, </a><a
            href="http://www.primehealthrn.com/"
            target="_blank"
          >www.primehealthrn.com.</a>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Content” <a
            href="http://www.primehealthrn.com/"
            class="a"
            target="_blank"
          >means any and all written, visual, video, or
            audio information contained on the Site, including, but not limited to, any and all emails received from
            Amanda Rogers-Beckley, Prime Health RN LLC, and/or www.primehealthrn.com, and any and all written or
            downloadable material purchased, viewed, or otherwise offered by Prime Health RN LLC and/or on </a><span
            class="p"
          >www.primehealthrn.com, including, but not limited to, blog posts, graphics, newsletters, designs, documents, information, templates and materials.</span>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Personal Information” <span class="p">means information that can be used on its own or in conjunction with other information to identify, contact, or locate a person, or to identify an individual in context.</span>
        </h2>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          For example, personal
          information includes, among other things, your name, address, email address, telephone number, coaches you
          schedule appointments with, etc.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Site, Courses, Services, and/or Products” <a
            href="http://www.primehealthrn.com/"
            class="a"
            target="_blank"
          >means </a><span class="p">www.primehealthrn.com, Content (as defined herein), email list/newsletters, social media posts, blog posts, courses, coaching services, guides, eBooks, forms, worksheets, workbooks, webinars, website materials, invoices, and/or templates available on the Site.</span>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “Site” <a
            href="http://www.primehealthrn.com/"
            class="a"
            target="_blank"
          >means </a><span
            class="p"
          >www.primehealthrn.com and any and all of its associated pages, tabs, landing pages, forms, or sub-pages.</span>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          “You” or “Your” <span class="p">means the user, customer, or viewer of the Site.</span>
        </h2>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="2.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Consent:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          By using the Site and/or making any Purchase,
          you implicitly and voluntarily agree to act in accordance with, and abide by, these Terms &amp; Conditions,
          our Privacy Policy, and Disclaimer.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
          By using the Site and/or making any Purchase,
          you represent and warrant that you are at least 18 years-old. Any use of, or access to, the Site and its
          Content by anyone under the age of 18 is unauthorized and in direct violation of these Terms &amp; Conditions
          and our Privacy Policy.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="3.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Site Rules:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          By using the Site and/or making any Purchase,
          you hereby agree &amp; consent <u>not</u> to:
        </p>
        <ul id="l2">
          <li data-list-text="•">
            <p style="padding-left: 32pt;text-indent: -9pt;text-align: left;">
              Abuse or harass any
              person through or on the Site.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 32pt;text-indent: -9pt;text-align: left;">
              Post or transmit
              obscene, offensive, libelous, defamatory, pornographic, or abusive content, as well as content that
              infringes our intellectual property rights or those of another person, website, or company.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 32pt;text-indent: -9pt;text-align: left;">
              Use the Site in any
              way or for any purpose which violates any law of the United States and the jurisdiction in which you use the
              Site.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 32pt;text-indent: -9pt;line-height: 14pt;text-align: left;">
              Post or transmit any “spam” or unwanted, unsolicited content.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 32pt;text-indent: -9pt;text-align: left;">
              Post copyrighted
              materials, photographs, or content which do not belong to you.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 32pt;text-indent: -9pt;text-align: left;">
              Promote or sell your
              own content, services, or products through the Site, or the content, services, or products of anyone else
              other than us.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 32pt;text-indent: -9pt;text-align: left;">
              Copy, download,
              share, post, or transmit our intellectual property in any way that infringes on our intellectual property
              rights.
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 32pt;text-indent: -9pt;text-align: left;">
              Purchase coaching
              services with any of the coaches listed on our website outside of the site.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br>
            </p>
          </li>
        </ul>
      </li>
      <li data-list-text="4.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          DISCLAIMER:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          By using the Site, you understand that we are
          health, wellness, and/or life coaches. We are not a nutritionist, therapist, or licensed physician, and
          therefore you need to discuss and clear any and all changes to your lifestyle, food intake, exercise regimen,
          or medical treatment with your physician before implementing changes or habits suggested by us. You must
          discuss any and all changes to your diet, exercise regimen, supplements, medications, or lifestyle with
          your
        </p>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          physician or qualified medical
          professional before implementing any suggested or offered changes, additions, or alterations to your
          lifestyle. Our Content is for informational and educational purposes only, and is based on our personal
          experience.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="5.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Your Consent to These
          Terms &amp; Conditions:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          By using this Site, or Purchasing or Downloading
          from our Site, Courses, Services, and/or Products, you implicitly and voluntarily agree to these Terms &amp;
          Conditions as stated herein.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="6.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Changes To These Terms
          &amp; Conditions:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We reserve the right to change, amend, or
          otherwise alter these Terms &amp; Conditions at any time without notice to you. When changes are made to these
          Terms &amp; Conditions, we will
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          update the “Updated on” date at the bottom of
          this page. If you do not agree with these Terms &amp; Conditions, please do <u>NOT</u> use our Site, read or
          implement its Content, or Purchase or Download anything from us.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="7.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Links to Third-Party
          or External Websites:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          The Site may contain or include website URL
          links to third-party or external websites. Typically, these URL links are provided so that you may directly
          access a site that contains relevant information. Please note we are not liable for any of the information
          contained on or within the third-party or external websites. We are not responsible for the way they handle
          your personal information, whether they have a privacy policy, or any information you provide to them by
          visiting their website. You are responsible for reading and agreeing to, or expressing disagreement with, the
          external website’s privacy policy or terms &amp; conditions.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="8.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Intellectual Property
          Ownership:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          The Site and its Content are intellectual
          property solely owned by Prime Health RN LLC. The Site and its Content are protected by United States
          copyright and trademark laws, as well as state intellectual property laws. Any violations of this term, and
          all terms contained herein, will be legally pursued to the fullest extent permitted by law.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="9.">
        <h2 style="padding-left: 17pt;text-indent: -12pt;text-align: left;">
          Our Limited License to
          You:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          If you view, access, or Purchase the Site,
          Courses, Services, and/or Products, you are considered our Limited Licensee (“Licensee”). As a Licensee, you
          agree and understand that the Site, Courses, Services, and/or Products have been written, created, drafted,
          invented, and developed by us after a significant investment of time, money, education, hard work, and
          brainpower. The Site, Courses, Services, and/or Products are extremely valuable to us, both professionally and
          personally, and we take the protection of our Site, Courses, Services, and/or Products very seriously.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You may not use the Site, Courses, Services,
          and/or Products in any manner that is unauthorized, improper, against these Terms &amp; Conditions or our
          Privacy Policy, or which violate U.S. intellectual property laws unless authorized by us <u>in writing</u>
          beforehand.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="10.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Your License to
          Us:
        </h2>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          By commenting on the Site, or
          submitting documents to Prime Health RN LLC via contact form, email, or social media, you represent that you
          are the lawful owner of said documents, statements, and/or the information they contain. You grant us a
          license to use your comments or submissions in any way we see fit, as it relates to our business purposes.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="11.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Purchase &amp; Access
          Terms:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          During the course of your use, Purchase, and/or
          Download from the Site, Courses, Services, and/or Products, you agree and understand that you cannot
          distribute, copy, forward, and/or share information prohibited by these Terms &amp; Conditions. You also agree
          and understand that you are to take all necessary steps to make sure that you do not inadvertently share or
          distribute said materials, including, but not limited to, protecting your password (if any) to the Site to
          access your Purchase or Download. Any violations of these Terms &amp; Conditions will be legally pursued to
          the fullest extent permitted by law.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="12.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Sharing the Site
          &amp; Its Content:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >You must request
            and receive written permission by email </a>primehealthrnuser@gmail.com before sharing our Site and its
          Content for commercial purposes. You may share the site for personal purposes, but we ask that you link
          directly to the Site. You are required to give us and the Site credit by linking to the Site and its Content
          if you share it on social media or your own website, including all photographs. Since the Site and its Content
          are not yours, you may not in any way imply or represent that the Site or its Content are yours or that you in
          any way created, caused, or contributed to the Site or its Content. You may not make any claims that you are
          in any way associated with Prime Health RN LLC.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="13.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          No Claims Made
          Regarding Results:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Any and all current or past-client testimonials,
          statements, or examples used by us are simply that: examples. They are not guarantees that you will also
          experience or receive the same results. Each client and their circumstances are unique and nothing shall be
          interpreted as a guarantee that you will experience the same results as another client of ours.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="14.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          DISCLAIMER - No
          Warranties, Guarantees, or Representations Are Being Made:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We do not offer any warranties, of any variety,
          regarding the Site, Courses, Services, and/or Products, and/or your Purchase or Download, in any way. The
          Site, Courses, Services, and/or Products, and/or your Purchases or Downloads are offered <b>“AS IS” </b>and
          without warranties of any kind, neither express nor implied, to the extent permitted by law.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="15.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Your Release of
          Us:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          By using the Site or Purchasing, Downloading, or
          using Prime Health RN LLC’s Courses, Services, and Products, you agree to release, forgive, and forever
          discharge Prime Health RN LLC, its subsidiaries, employees, agents, contractors, subcontractors, and
          affiliates from any and all claims, suits, actions, charges, demands, liabilities, damages, judgments, and/or
          costs, whether known or unknown, both legal and equitable in any manner.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="16.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Errors &amp;
          Omissions:
        </h2>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Every effort is made to provide
          up-to-date accurate information both on the Site and through our services. However, due to the complexity of
          the issues we cover, Prime Health RN LLC does not and cannot warrant, represent, or guarantee that such
          information is free from errors, accurate, or up-to-date at all times. You should do your due diligence,
          research, or consult with a professional to ensure that all information you receive, act upon, or rely on from
          this Site and/or from our services is accurate and up-to-date.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="17.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Our Refund
          Policy:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >We will do
            everything within our ability (and within reason) to ensure your satisfaction. Refunds will not be issued for
            coaching services already rendered or products already purchased. If you have any questions or concerns, or if
            there is anything we can do to make your experience a more pleasant one, please email Prime Health RN
            at </a><a
            href="mailto:primehealthrnuser@gmail.com"
            target="_blank"
          >primehealthrnuser@gmail.com.</a>
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="18.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          <u>ARBITRATION
            CLAUSE</u>:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
          <a
            href="mailto:primehealthrnuser@gmail.com"
            class="a"
            target="_blank"
          >If you have any
            complaint or should any issue arise in the use of the Site or Prime Health RN LLC’s Courses, Services, and/or
            Products, please contact us directly first by emailing Prime Health RN at </a><a
            href="mailto:primehealthrnuser@gmail.com"
            target="_blank"
          >primehealthrnuser@gmail.com.</a>
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          <a
            href="https://www.adr.org/"
            class="a"
            target="_blank"
          >However, if we are unable to
            amicably resolve your dispute in that manner, you agree that you and Prime Health RN LLC shall submit your
            dispute to binding arbitration with the </a><u>American</u><a
            href="https://www.adr.org/"
            class="a"
            target="_blank"
          /><a
            href="https://www.adr.org/"
            class="s8"
            target="_blank"
          >Arbitration </a><u>Association</u><a
            href="https://www.adr.org/Rules"
            class="a"
            target="_blank"
          >, before an arbitrator that is mutually agreed
            upon, in accordance with the American Arbitration Association’s (“AAA”) </a><u>rules</u>.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          By agreeing to this term, you hereby agree and
          understand that you’re waiving your right to a jury trial in court, which would otherwise be available to you
          if not for this Arbitration Clause. Should any arbitration hearing need to be held, it shall be held within 30
          miles of Atlanta, Georgia.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          If the arbitrator issues an award and a judgment
          is made, the judgment will be binding and will be entered in court in the State of Georgia. The only award
          that can be issued to you is a refund of any payment made to Prime Health RN LLC for the applicable Product or
          Service.
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You are not permitted to seek additional
          damages, including consequential or punitive damages.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="19.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Consent to Governing
          Law:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
          These Terms &amp; Conditions, and any dispute
          arising out of it, shall be governed by the laws of the State of Georgia.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="20.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Consent to
          Jurisdiction:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You hereby irrevocably consent to the exclusive
          jurisdiction and venue of any Federal Court in the United States District Court for the District of Georgia or
          a state court located within the State of Georgia in connection with any matter arising out of these Terms
          &amp; Conditions, Privacy Policy, Disclaimer, or as a result of your use, Download, or Purchase from the Site,
          Courses, Services, and/or Products.
        </p>
      </li>
      <li data-list-text="21.">
        <h2 style="padding-top: 4pt;padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Consent to Service:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You hereby irrevocably agree that process may be
          served on you in any manner authorized by the Laws of the State of Georgia for such persons, and you waive any
          objection which you might otherwise have to service of process under the laws of the State of Georgia.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="22.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Payment &amp;
          Purchases:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          When you Purchase or Download one of our
          Courses, Services, and Products from us or the Site, you may pay by Stripe or Square. By doing so, you give
          Prime Health RN LLC permission to automatically charge your credit card for payment. You will receive an
          electronic receipt following your Purchase, which you should retain for your records.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          If you elect the installment or “pay over time”
          option at checkout, you agree that Prime Health RN LLC has permission to automatically charge, without
          checking with you before each installment transaction is charged, the amount due on the date(s) agreed upon at
          checkout.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          If your payment method fails or is otherwise
          declined, you will be removed from, or canceled from having access to, our Courses, Services, and Products.
          Please note, in the event your payment method is declined at any time, you are <b>still responsible </b>for
          the <u>full cost</u> of your Purchase.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          We do not accept any chargeback threats (real or
          threatened). If any chargebacks are placed on a Purchase or Download of our Courses, Services, and Products,
          we will report said incident to the major credit reporting agencies. Doing so could have a negative impact on
          your credit report and/or credit score. Should we need to do so and you would like to have this report removed
          from your credit report, please contact us to arrange for payment owed. Once payment owed is received, we will
          make the appropriate reports to the credit agencies.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Payment processing companies may have different
          privacy policies and practices than we do. We are not responsible for the policies of the payment processing
          companies. As with any online purchase, there are circumstances beyond our control which may compromise your
          credit card or payment method. We are not liable or responsible for any of those circumstances.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You hereby release us from any and all damages
          related to your payment or use of our payment processing companies in which you incur and further agree not to
          assert any claims against us or them for any damages which arise from your Purchase or use of our Site and its
          Content.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="23.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Limitation of
          Liability:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Prime Health RN LLC is not responsible or liable
          in any way for any and all damages you receive directly or indirectly from your use, Purchase, or Download
          from our Site, Courses, Services, and/or Products. We do not assume liability for damages, injuries, harm,
          death, misuse of (or failure to properly use) information or documents, due to any act, or failure to act, by
          you. Notwithstanding anything to the contrary contained herein, your sole and exclusive remedy for negligence,
          failure to perform, or breach by us shall be a refund of the amount paid
        </p>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
          for such service or product. IN
          NO EVENT SHALL WE BE LIABLE TO YOU FOR ANY INDIRECT, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="24.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Defense &amp;
          Indemnification
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          You shall, at all times, indemnify, defend, and
          hold harmless Prime Health RN LLC, Amanda Rogers-Beckley, and all of our shareholders, officers, members,
          affiliates, contractors, subcontractors, directors, assignees, employees, and licensees from and against all
          losses, damages, injuries, delays, deaths, lost profits, and expenses arising out of any proceeding (a)
          brought by either a third-party or by Prime Health RN LLC and Amanda Rogers-Beckley (b) arising out of your
          breach of your obligations, representations, warranties, or covenants under these Terms &amp; Conditions or
          the Privacy Policy; and (c) arising out of any alleged breach or negligence said to have been committed by
          us.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="25.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Termination of Your
          Use
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          At our sole discretion, we are permitted to
          terminate your use or access to the Site, Courses, Services, and/or Products, and Purchases/Downloads if you
          abuse, violate, or breach any of these Terms &amp; Conditions, Privacy Policy, Disclaimer, or any other terms
          to which you have agreed to.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="26.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Entire Agreement
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          These Terms &amp; Conditions, our Privacy Policy
          and Disclaimer, constitute the entire agreement between you and us with respect to the Site, Courses,
          Services, and/or Products, and they supersede all prior or contemporaneous communications and proposals,
          whether electronic, oral, or written, between you and us with respect to the Site, Courses, Services, and/or
          Products.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="27.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Severability
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          The provisions of these Terms &amp; Conditions
          are severable, and the invalidity or unenforceability of any provision shall not affect the validity and
          enforceability of any other provision herein. If any paragraph, section, subsection, sentence, or clause of
          these Terms &amp; Conditions are rendered illegal, invalid, or unenforceable, such illegality, invalidity, or
          unenforceability shall have no effect on these Terms &amp; Conditions as a whole or on any other paragraph,
          section, subsection, sentence, or clause herein.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="28.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Your Privacy &amp;
          Security on the Site:
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          Please read our <u>Privacy Policy</u> for how we
          handle your personal information.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br>
        </p>
      </li>
      <li data-list-text="29.">
        <h2 style="padding-left: 23pt;text-indent: -18pt;text-align: left;">
          Contact
        </h2>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
          If you have any questions or concerns regarding
          these Terms &amp; Conditions, you may contact us using the following information:
        </p>
        <ul id="l3">
          <li data-list-text="•">
            <p style="padding-left: 31pt;text-indent: -8pt;line-height: 14pt;text-align: left;">
              <a
                href="http://www.primehealthrn.com/"
                class="a"
                target="_blank"
              >Website: </a><a
                href="http://www.primehealthrn.com/"
                target="_blank"
              >www.primehealthrn.com</a>
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 31pt;text-indent: -8pt;text-align: left;">
              <a
                href="mailto:primehealthrnuser@gmail.com"
                class="a"
                target="_blank"
              >Email: Amanda at </a><a
                href="mailto:primehealthrnuser@gmail.com"
                target="_blank"
              >primehealthrnuser@gmail.com</a>
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 31pt;text-indent: -8pt;text-align: left;">
              Business Address:
              4400 Brownsville Rd Ste 105 PMB 201, Powder Springs, GA 30127
            </p>
          </li>
        </ul>
      </li>
    </ol>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Updated on September 10, 2024
    </h2>
  </v-container>
</template>

<style scoped lang="scss">
.h1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  font-size: 14pt;
}

.s1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 14pt;
}

.h2, h2 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s3 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s4 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.p, p {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin: 0pt;
}

.a, a {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s8 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 11pt;
}

li {
  display: block;
}

#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}

#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

#l2 {
  padding-left: 0pt;
}

#l2 > li > *:first-child:before {
  content: "• ";
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l3 {
  padding-left: 0pt;
}

#l3 > li > *:first-child:before {
  content: "• ";
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
</style>
