import { createWebHistory, createRouter } from "vue-router"
import "@/router/paths"
import RouteDesigner from "@/router/RouteDesigner"
import Pipeline from "@/middleware/Pipeline"
import {ROUTES} from "@/router/paths"

const router = createRouter({
  history: createWebHistory(),
  routes: RouteDesigner.compile(),
  scrollBehavior(to, from, savedPosition) {
    if (
      from &&
      to.name == ROUTES.SEARCH &&
      from.name == ROUTES.SEARCH &&
      Object.keys(to.query).length &&
      to.fullPath.split('?')[0] == from.fullPath.split('?')[0]
    )  {
      return
    }

    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash }
    }
    return { top: 0 }
  }
})


const pipeline = new Pipeline()
router.beforeEach(pipeline.handle.bind(pipeline))

export default router
