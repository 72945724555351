<template>
  <component
    :is="layout"
    v-if="layout"
  >
    <router-view />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  head() {
    return {
      titleTemplate: "%s | " + import.meta.env.VITE_APP_NAME,
      title: this.$route?.meta?.title || this.toUpper(this.$route?.name?.replace(/[.\-_]/g, ' ')) || undefined
    }
  },
  computed: {
    layout(): string | null{
      if (!this.$route) return null
      return this.$route.meta?.layout ? this.$route.meta.layout + "Layout" : "EmptyLayout"
    }
  },
  methods: {
    toUpper(str?: string) {
      return str?.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
    }
  }
})
</script>
