<script setup lang="ts" generic="T">
import {type MaybeRef, ref} from "vue"
import {useForm} from "@/composables/useForm"

const props = withDefaults(defineProps<{
  fullWidth?: MaybeRef<boolean>;
  endpoint: MaybeRef<string>,
  method?: MaybeRef<string>,
  data?: MaybeRef<T>,
  successMessage?: string,
}>(), {
  fullWidth: false,
  method: 'post',
  data: {} as MaybeRef<T>,
  successMessage: undefined,
})


const emits = defineEmits(['success', 'error'])
const form = ref()

const {
  submit,
  getErrors,
  errorBag,
  loading,
} = useForm(props, form, emits)

defineExpose({
  submit,
})


</script>

<template>
  <v-form
    ref="form"
    :readonly="loading"
    validate-on="eager"
  >
    <slot v-bind="{ submit, loading, getErrors, errorBag }" />
  </v-form>
</template>
