<script setup lang="ts">

import useFile from "@/composables/useFile"
import type {RouteLocationRaw} from "vue-router"

withDefaults(defineProps<{
  coach: App.Models.User;
  returnTo?: RouteLocationRaw | (() => any);
  returnText?: string;
  hideBio?: boolean
}>(), {
  returnTo: undefined,
  returnText: undefined,
  hideBio: false,
})

const fileUrl = useFile()
</script>

<template>
  <div class="coach-hero">
    <v-container
      class="coach-hero__container"
      :class="{
        'coach-hero__container--has-slot': $slots['default'],
      }"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column gr-6"
        >
          <div>
            <v-btn
              v-if="returnTo"
              prepend-icon="mdi-arrow-left"
              variant="text"
              color="white"
              :to="typeof returnTo === 'string' ? returnTo : undefined"
              @click="typeof returnTo === 'function' ? returnTo() : () => {}"
            >
              {{ returnText }}
            </v-btn>
            <h1 class="coach-hero__container__name">
              {{ coach!.full_name }}, {{ coach!.title }}
            </h1>
          </div>
          <div
            class="coach-hero__container__bio"
            v-if="!hideBio"
          >
            <span class="text-pre-wrap">{{ coach!.bio }}</span>
          </div>
          <div class="coach-hero__container__details">
            <v-divider
              color="white"
              opacity="1"
            />
            <v-rating
              v-model="coach!.user_rating"
              color="white"
              readonly
            />
            <div
              class="coach-hero__container__address"
            >
              <v-icon>
                mdi-map-marker
              </v-icon>
              <p>{{ coach!.display_address }}</p>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-img
            :aspect-ratio="4/3"
            cover
            position="top center"
            :src="fileUrl(coach!.file_id, 'original')"
          />
        </v-col>
      </v-row>
      <slot />
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.coach-hero {
  background-color: rgb(var(--v-theme-tertiary));

  &__container {
    position: relative;

    @media (max-width: 800px) {
      &--has-slot {
        padding-bottom: 100px;
      }
    }

    &__name {
      color: #FFF;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 44px;
      font-weight: 800;
    }

    &__details {
      max-width: 300px;

      @media (max-width: 1280px) and (min-width: 960px) {
        margin-bottom: 120px;
      }
    }

    &__bio {
      overflow: hidden;
      color: #FFF;
      text-overflow: ellipsis;
      font-family: Lato, sans-serif;
      font-size: 22px;
    }

    &__address {
      display: flex;
      gap: 12px;
      color: white;
    }
  }
}
</style>
