import type { NavigationGuardNext, RouteRecordNormalized } from "vue-router"
import {$auth} from "@/plugins/auth"
import {ROUTES} from "@/router/paths"

export default class CoachSubscribed implements App.Middleware.Instance {
  async handle(
    to: RouteRecordNormalized,
    from: RouteRecordNormalized,
    next: App.Middleware.Caller,
    cancel: NavigationGuardNext
  ): Promise<void> {
    if ($auth.user!.current_subscription?.status === 'active' || $auth.user?.has_offline_subscription) {
      return next(to, from, cancel)
    }

    return cancel({ name: ROUTES.COACHES.PROFILE.SUBSCRIPTION})
  }
}
