<script setup lang="ts">
import AMainSection from "@/components/AMainSection.vue"
import useRouteTo from "@/composables/useRouteTo"
import AppointmentDetails from "@/components/AppointmentDetails.vue"
import {computed} from "vue"
import {useRoute} from "vue-router"

const {routeTo, ROUTES} = useRouteTo()

const props = defineProps<{
  appointmentId: string | number,
  coachId: string | number,
}>()

const returnTo = computed(() => {
  const { query } = useRoute()
  return query.appointmentsIndex === '1'
    ? routeTo(ROUTES.ADMIN.APPOINTMENTS.INDEX)
    : routeTo(ROUTES.ADMIN.COACHES.EDIT, { coachId: props.coachId })
})
</script>

<template>
  <a-main-section
    heading="Appointment Request"
    :return-to="returnTo"
    return-text="Appointments"
  >
    <appointment-details :appointment-id="props.appointmentId" />
  </a-main-section>
</template>

<style scoped lang="scss">
</style>
