<script setup lang="ts">
import {loadStripe} from '@stripe/stripe-js'
import AMainSection from "@/components/AMainSection.vue"
import {computed, onMounted, onUnmounted, ref} from "vue"
import useAxios from "@/composables/useAxios"
import useErrorHandler from "@/composables/useErrorHandler"
import {useRouter} from "vue-router"
import useRouteTo from "@/composables/useRouteTo"
import useAuth from "@/composables/useAuth"
import type {StripeEmbeddedCheckout} from "@stripe/stripe-js"
import {useHead} from "@unhead/vue"

useHead({ title: 'Subscribe' })

const {errorHandler} = useErrorHandler()
const {axios} = useAxios()
const router = useRouter()
const {routeTo, ROUTES} = useRouteTo()
const {$auth} = useAuth()

if ($auth.user!.current_subscription?.status === 'active') {
  router.push(routeTo(ROUTES.COACHES.PROFILE.PROFILE))
}

const checkout = ref<StripeEmbeddedCheckout | null>(null)
const returnText = computed(() => $auth.user!.is_profile_completed ? 'Profile' : 'Complete Profile')
const returnTo = computed(() => $auth.user!.is_profile_completed ? routeTo(ROUTES.COACHES.PROFILE.PROFILE) : routeTo(ROUTES.COACHES.COMPLETE_PROFILE))
const sessionId = ref<string | null>(null)

const fetchClientSecret = async () => {
  const {data: {clientSecret, sessionId: id, message, errors}, status} = await axios.get(`/coach/checkout-session`).catch(e => e)
  if (errorHandler(status, message, errors)) return

  sessionId.value = id

  return clientSecret
}

const onComplete = async () => {
  const {data: { message, errors }, status} = await axios.get(`/coach/checkout-session/${sessionId.value}`, {}).catch(e => e)
  if (errorHandler(status, message, errors)) return
  await $auth.loadUser()
  await router.push(routeTo(ROUTES.COACHES.PROFILE.PROFILE))
}

onUnmounted(() => {
  checkout.value?.destroy()
})

onMounted(async () => {
  await $auth.loadUser()

  if ($auth.user?.has_offline_subscription) {
    await router.push(routeTo(ROUTES.COACHES.PROFILE.PROFILE))

    return
  }

  const stripe = await loadStripe(import.meta.env.VITE_STRIPE_KEY)
  checkout.value = await stripe!.initEmbeddedCheckout({
    fetchClientSecret,
    onComplete
  })

  checkout.value.mount('#checkout')
})
</script>

<template>
  <a-main-section
    :heading="'Checkout'"
    :return-text="returnText"
    :return-to="returnTo"
    :return-link="'/coaches/create'"
  >
    <div id="checkout" />
  </a-main-section>
</template>

<style scoped lang="scss">

</style>
