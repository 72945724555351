import moment from "moment/moment"

type Time = string | number | Date

const formatTime = (time: Time, format: string, local: boolean = true): string => {
  switch (typeof time) {
    case "number":
      time = time * 1000
      break
    case "object":
      time = (time as Date).toISOString()
      break
    default:
      break
  }
  let date = moment.utc(time)
  if (local) {
    date = date.local()
  }
  if (!date.isValid()) {
    return ""
  }
  return date.format(format)
}

export default function useTime() {
  return {
    full_human_date(time: Time, local: boolean = true): string {
      return formatTime(time, "dddd, MMMM D, Y", local)
    },
    short_human_date(time: Time, local: boolean = true): string {
      return formatTime(time, "MMM D, Y", local)
    },
    day(time: Time, local: boolean = true): string {
      return formatTime(time, "dddd", local)
    },
    full_date_time(time: Time, local: boolean = true): string {
      return formatTime(time, "dddd, MMMM D, Y h:mm a", local)
    },
    short_date_time(time: Time, local: boolean = true): string {
      return formatTime(time, "MMM D, Y h:mm a", local)
    },
    time(time: Time, local: boolean = true): string {
      return formatTime(time, "h:mm a", local)
    },
    short_date_time_12(time: Time, local: boolean = true): string {
      return formatTime(time, "MM/DD/YYYY h:mm a", local)
    },
    short_date(time: Time, local: boolean = true): string {
      return formatTime(time, "MM/DD/YYYY", local)
    },
    month_year(time: Time, local: boolean = true): string {
      return formatTime(time, "MM/YYYY", local)
    },
    formatTime,

    time_ago(time: Time): string {
      return moment.min(moment.utc(time).endOf('day'), moment()).fromNow()
    },
    time_future(time: Time): string {
      const start = moment().utc().startOf('day')
      const end = moment.utc(time).add(1, 'second').startOf('day')

      if (start.isSame(end)) {
        return "Today"
      }
      return moment.max(start).to(end)
    },
    time_calculate(time: Time): string {
      const date = moment.utc(time).endOf('day')
      if(date.isAfter()) {
        return this.time_future(time)
      } else {
        return this.time_ago(time)
      }
    },
    calendar(time: Time): string {
      const date = moment.utc(time).local()
      if (!date.isValid()) {
        return "Never"
      }
      return date.calendar(null, {
        sameDay: "[Today] [@] h:mma",
        nextDay: "[Tomorrow] [@] h:mma",
        nextWeek: "dddd [@] h:mma",
        lastDay: "[Yesterday] [@] h:mma",
        lastWeek: "[Last] dddd [@] h:mma",
        sameElse: "MMM D, Y h:mma"
      })
    },
  }
}
